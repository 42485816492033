import React, { FC, ReactElement } from "react";

import { Box, Tooltip, TooltipProps } from "@mui/material";

export interface CustomToolTipProps {
  title: string;
  child: ReactElement;
  placement?: TooltipProps["placement"];
}

/**
 * A custom tooltip component that wraps the child component in a div to fix a ref issue.
 * @param CustomToolTipProps - the props for the CustomToolTip component
 * @param CustomToolTipProps.title - the title of the tooltip
 * @param CustomToolTipProps.child - the child component to wrap in the tooltip
 * @param CustomToolTipProps.placement - the placement of the tooltip
 * @returns a custom tooltip component
 */
const CustomToolTip: FC<CustomToolTipProps> = ({ title, child, placement = "top" }) => (
  <Tooltip title={title} placement={placement}>
    <Box>{child}</Box>
  </Tooltip>
);

export default CustomToolTip;
