import { AuthenticationResult } from "@azure/msal-browser";

import { msalInstance } from "../..";
import { WebApiTokenRequest } from "../ApiConfiguration";
import { TokenType } from "./TokenType";

/**
 * This function is purely for testing API functionality in the frontend until AAD is integrated further.
 * @returns either a Bearer token or an empty string.
 */
export const getToken = async (): Promise<string> => {
  try {
    const authResult: AuthenticationResult = await msalInstance.acquireTokenSilent(WebApiTokenRequest);

    if (authResult.tokenType === TokenType.Bearer) {
      return `${authResult.tokenType} ${authResult.accessToken}`;
    } else {
      return "";
    }
  } catch (error) {
    throw new Error(`Failed to acquire token, reason: ${error}`);
  }
};
