import React, { FC, createContext, useContext, useEffect, useMemo, useReducer, useState } from "react";

import { SyncError } from "../components/KnowledgeSources/KnowledgeSourcesHelper";

import { KnowledgeSourceWebAppsConfig } from "../API/Auth/AuthConfig";
import { checkLock, checkStatus, synchronize } from "../API/SyncInteraction";
import { useClientContext } from "./ClientContext";

const initialSyncContext: SyncContextAttributes = {
  isSyncing: false,
  isAlertOpen: false,
};

const SyncContext = createContext<SyncContextAttributes | null>(initialSyncContext);

interface SyncContextProps {
  children: React.ReactNode;
}

export enum SyncActionType {
  setSyncing,
  setAlertOpen,
}

type SyncContextAttributes = {
  isSyncing: boolean;
  isAlertOpen: boolean;
  checkSyncLock?: (clientId: string | null) => Promise<void>;
  syncSources?: (clientId: string | null) => Promise<void>;
};

/**
 * Context provider knowledgesources synchronisation
 * @param SyncContextProps - The props for the component
 * @param SyncContextProps.children - The children of the component
 * @returns The sync context provider
 */
export const SyncContextProvider: FC<SyncContextProps> = ({ children }) => {
  const ClientContext = useClientContext();

  const [context, dispatch] = useReducer(syncReducer, initialSyncContext);
  const [clientId, setClientId] = useState<string | null>(null);
  const isMultiTenant: boolean = process.env.REACT_APP_IS_MULTI_TENANT === "true";

  useEffect(() => {
    if (isMultiTenant && ClientContext?.clientId !== null) {
      setClientId(ClientContext!.clientId);
    } else if (!isMultiTenant) {
      setClientId(null);
    }
  }, [ClientContext?.clientId]);

  useEffect(() => {
    if (isMultiTenant && clientId === null) return;

    // TODO 5441: use server-side rendering instead of this live update disaster implementation!
    let intervalTime = 20000;
    if (KnowledgeSourceWebAppsConfig.knowledgeSourceWebAppCallTimeInterval !== undefined) {
      intervalTime = 1000 * parseInt(KnowledgeSourceWebAppsConfig.knowledgeSourceWebAppCallTimeInterval);
    }

    const interval = setInterval(() => {
      void checkSyncLock(clientId);
      checkStatus(clientId);
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [clientId]);

  /**
   * Sync the sources for this client
   * @param clientId - The client ID
   */
  const syncSources = async (clientId: string | null): Promise<void> => {
    setSyncing(true);
    await synchronize(clientId).catch((error) => {
      if (error instanceof SyncError) {
        setSyncing(true);
        setAlertOpen(true);
      }
    });
  };

  /**
   * Check if synchronization is locked
   * @param clientId - the client for which to check the lock
   */
  const checkSyncLock = async (clientId: string | null): Promise<void> => {
    await checkLock(clientId)
      .then(() => {
        setSyncing(false);
      })
      .catch((error) => {
        if (error instanceof SyncError) {
          setSyncing(true);
        } else {
          throw error;
        }
      });
  };

  /**
   * Status of syncing
   * @param value - the value for syncing
   */
  const setSyncing = (value: boolean): void => {
    const action: SyncReducerAction = {
      type: SyncActionType.setSyncing,
      payload: value,
    };

    dispatch(action);
  };

  /**
   * Status of syncing
   * @param value - the value for syncing
   */
  const setAlertOpen = (value: boolean): void => {
    const action: SyncReducerAction = {
      type: SyncActionType.setAlertOpen,
      payload: value,
    };

    dispatch(action);
  };

  const _context: SyncContextAttributes = useMemo(
    () => ({
      isSyncing: context.isSyncing,
      isAlertOpen: context.isAlertOpen,
      syncSources,
      checkSyncLock,
    }),
    [context.isSyncing, context.isAlertOpen],
  );

  return <SyncContext.Provider value={_context}>{children}</SyncContext.Provider>;
};

/**
 * Get the sync context
 * @returns The sync context
 */
export const useSyncContext = (): SyncContextAttributes | null => useContext(SyncContext);

/**
 * Sync reducer action
 */
export type SyncReducerAction = {
  type: SyncActionType;
  payload: string | boolean;
};

/**
 * Sync reducer
 * @param context - The current context.
 * @param action - The action to perform.
 * @returns The updated context.
 */
export const syncReducer = (context: SyncContextAttributes, action: SyncReducerAction): SyncContextAttributes => {
  switch (action.type) {
    case SyncActionType.setSyncing:
      return { ...context, isSyncing: action.payload as boolean };
    case SyncActionType.setAlertOpen:
      return { ...context, isAlertOpen: action.payload as boolean };
  }
};
