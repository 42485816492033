import moment from "moment";

import { EditorContentType } from "../../components/Editors/ModularEditor/ModularEditor";

import { TrackedChange } from "../../models/ChangeTracking/TrackedChange";
import { DialogModel } from "../../models/Dialogs/Dialog";

import { ChangeType } from "../../enums/ChangeType";

import { localStorageContextAttributes } from "./LocalStorageContext";

/**
 * Update change tracking
 * @param oldValue - The old value of the item
 * @param newValue - The new value of the item
 * @param changeType - The type of change
 * @param containerName - The name of the container
 * @param context - The context to update
 */
export const updateChangeTracking = (
  oldValue: EditorContentType | DialogModel | null,
  newValue: EditorContentType | DialogModel | null,
  changeType: ChangeType,
  containerName: string,
  context: localStorageContextAttributes,
): void => {
  const value = newValue ?? oldValue;
  if (value === null) throw new Error("Either new or old value should not be null");

  const key: string = context.createKey!(containerName, value.key);

  const trackedChange: TrackedChange = {
    key,
    containerName,
    oldValue,
    newValue,
    lastModified: moment().toDate(),
    changeType,
    live: false,
    displayOrder: Object.keys(value),
  };

  switch (changeType) {
    case ChangeType.New:
      context.trackChange!(trackedChange);
      break;
    case ChangeType.Delete:
      // If the change exists, a newly made item is deleted,
      // otherwise a previously existing item is deleted.
      if (context.trackedChangeExists!(trackedChange)) {
        context.updateChange!(trackedChange);
      } else {
        context.trackChange!(trackedChange);
      }
      break;
    case ChangeType.Update: {
      // Retrieve a previous update for this item and modify it, or create a new change if did not exist.
      const trackedChangeResult = context.getChangeByKey!(key, containerName);
      if (trackedChangeResult === undefined) {
        context.trackChange!(trackedChange);
      } else {
        context.updateChange!(trackedChange);
      }
    }
  }
};
