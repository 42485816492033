import React, { Dispatch, FC, ReactNode, createContext, useContext, useEffect, useMemo, useReducer } from "react";

import { deepCopy } from "../../helpers/DeepCopy";

import { IntentGroup } from "../../models/Nlu/IntentGroups";

import { ContainerNames } from "../../enums/ContainerNames";

import { StorageEnvironment, createItems, getContainer, updateItems } from "../../API/StorageInteraction";
import { useNLUContext } from "./NluContext";

// #region intent groups context

// Builds the context that provides the intent groups
const IntentGroupContext = createContext<intentGroupContextAttributes | null>(null);

// Builds the context that provides the function that lets components dispatch actions
const IntentGroupDispatchContext = createContext<Dispatch<intentGroupReducerAction> | null>(null);

// Initial intent groups state provided to the reducer in the provider.
const initialIntentGroupContext: intentGroupContextAttributes = {
  intentGroups: [],
  intentGroupOtherKey: "intent-groups-group-ungrouped",
};

/**
 * Enum that contains all possible actions
 */
export enum IntentGroupActionType {
  set,
}

/**
 * Interfaces the children property to the context provider
 */
interface IntentGroupContextProviderProps {
  children: ReactNode | Array<ReactNode> | null;
}

/**
 * Interfaces the context values and functions with the provider component
 */
export type intentGroupContextAttributes = {
  intentGroups: Array<IntentGroup>;
  intentGroupOtherKey: string;
  setIntentGroups?: (intentGroups: Array<IntentGroup>) => Promise<void>;
  moveIntentToGroup?: (from: string, target: string, category: string) => Promise<void>;
  removeIntentFromGroup?: (parent: string, name: string) => Promise<void>;
};

// TODO: Further extract the functions from the question editor to this context
// TODO: so that we later can cleanly separate the global states and component states.

/**
 * Provides the intent group context to the application
 * @param IntentGroupContextProviderProps - The properties of the provider
 * @param IntentGroupContextProviderProps.children - The children of the provider
 * @returns A provider element
 */
export const IntentGroupContextProvider: FC<IntentGroupContextProviderProps> = ({ children }) => {
  // Context dependency
  const CLUContext = useNLUContext();

  const [context, dispatch] = useReducer(intentGroupReducer, initialIntentGroupContext);

  useEffect(() => {
    if (CLUContext?.selectedProject === null) return;

    void setIntentGroups().catch((error) => {
      throw new Error(`Setting Intent Groups failed: ${error}`);
    });
  }, [CLUContext?.selectedProject]);

  /**
   * Set the intent groups for the app that is currently selected.
   * @param groups - The intent groups to set.
   */
  const setIntentGroups = async (groups?: Array<IntentGroup>): Promise<void> => {
    let isInitialSetup = true;
    if (CLUContext?.selectedProject === null) return;

    // It's not an update, get the data.
    if (groups === undefined || groups === null) {
      const response = await getContainer(StorageEnvironment.Staging, "IntentGroups");
      groups = response as Array<IntentGroup>;

      // If a group for ungrouped intents doesn't exist, create it.
      const ungrouped = groups.find((group) => group.name === "Overig" || group.name === "Extra");
      if (ungrouped === undefined) {
        groups.push({
          key: context.intentGroupOtherKey,
          name: `Overig`,
          intents: [],
        } satisfies IntentGroup);
      } else {
        isInitialSetup = false;
      }

      // Check for any floating intents, add them to the ungrouped group.
      if (CLUContext?.selectedProject.assets.intents !== undefined) {
        const floatingIntents = CLUContext.selectedProject?.assets.intents.filter(
          (intent) =>
            !(
              groups?.some((group) => group.intents.some((groupedIntent) => groupedIntent.name === intent.name)) ??
              false
            ),
        );

        if (floatingIntents.length > 0) {
          groups.find((group) => group.key === context.intentGroupOtherKey)?.intents.push(...floatingIntents);
        }
      }

      // Check for any deleted intents, and filter them from the intent groups.
      groups.forEach((group) => {
        group.intents = group.intents.filter((intent) =>
          CLUContext?.selectedProject?.assets.intents.some((listIntent) => listIntent.name === intent.name),
        );
      });
    }

    groups = sortIntentGroups(groups);

    if (isInitialSetup) {
      await createIntentGroups(groups);
    } else {
      await updateIntentGroups(groups);
    }

    // Construct a dispatch action.
    const action: intentGroupReducerAction = {
      type: IntentGroupActionType.set,
      payload: groups,
    };

    dispatch(action);
  };

  /**
   * Move an intent from Group A to target group B
   * @param from - The key of the group we are taking an intent away from.
   * @param target - The key of the group we are targeting to put the intent into.
   * @param name - The name of the intent.
   */
  const moveIntentToGroup = async (from: string, target: string, name: string): Promise<void> => {
    const update = deepCopy(context.intentGroups);

    // Get the current groups.
    const sender = update.find((group) => group.key === from);
    const receiver = update.find((group) => group.key === target);

    if (sender !== undefined && receiver !== undefined) {
      const deliveryIndex = sender.intents.findIndex((intent) => intent.name === name);
      const delivery = sender.intents.splice(deliveryIndex, 1).pop();

      if (delivery !== undefined) {
        receiver.intents.push(delivery);
      }

      // Place the updated groups back in the update package.
      update[update.findIndex((group) => group.key === from)] = sender;
      update[update.findIndex((group) => group.key === target)] = receiver;
    }

    await setIntentGroups(update);
  };

  /**
   * Remove an intent from the group after it is deleted.
   * @param parent - the targeted group
   * @param name - the name of the intent
   */
  const removeIntentFromGroup = async (parent: string, name: string): Promise<void> => {
    const update = deepCopy(context.intentGroups);

    // Get the target group.
    const target = update.find((group) => group.name === parent);

    // Filtrate the specified intent
    if (target !== undefined) {
      target.intents = target.intents.filter((intent) => intent.name !== name);

      // place the updated group back in the package
      update[update.findIndex((group) => group.name === target.name)] = target;
    }

    await setIntentGroups(update);
  };

  /**
   * Sorts the intent groups
   * @param intentGroups - The intent groups.
   * @returns The sorted intent groups.
   */
  const sortIntentGroups = (intentGroups: Array<IntentGroup>): Array<IntentGroup> => {
    const sortedIntentGroups = [...intentGroups];

    sortedIntentGroups.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    sortedIntentGroups.forEach((group) => {
      group.intents.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name > b.name) {
          return 1;
        }

        return 0;
      });
    });

    // Sort the Overig group to the top
    const index = sortedIntentGroups.findIndex((group) => group.key === `intents-groups-group-ungrouped`);

    if (index !== -1) {
      const overigGroup = sortedIntentGroups[index];
      sortedIntentGroups.splice(index, 1);
      sortedIntentGroups.unshift(overigGroup);
    }

    return sortedIntentGroups;
  };

  /**
   * creates the intent groups
   * @param groups - The intent groups.
   */
  const createIntentGroups = async (groups: Array<IntentGroup>): Promise<void> => {
    await createItems(StorageEnvironment.Staging, ContainerNames.IntentGroups, groups, null);
  };

  /**
   * updates the intent groups
   * @param groups - The intent groups.
   */
  const updateIntentGroups = async (groups: Array<IntentGroup>): Promise<void> => {
    await updateItems(StorageEnvironment.Staging, ContainerNames.IntentGroups, groups, null);
  };

  const _context: intentGroupContextAttributes = useMemo(
    () => ({
      intentGroups: context.intentGroups,
      intentGroupOtherKey: context.intentGroupOtherKey,
      setIntentGroups,
      moveIntentToGroup,
      removeIntentFromGroup,
    }),
    [context.intentGroups, context.intentGroupOtherKey],
  );

  return (
    <IntentGroupContext.Provider value={_context}>
      <IntentGroupDispatchContext.Provider value={dispatch}>{children}</IntentGroupDispatchContext.Provider>
    </IntentGroupContext.Provider>
  );
};

/**
 * Provides the intent group context
 * @returns A context
 */
export const useIntentGroupContext = (): intentGroupContextAttributes | null => useContext(IntentGroupContext);

/**
 * Provides the function that lets components dispatch actions
 * @returns A dispatch context
 */
export const useIntentGroupDispatch = (): Dispatch<any> | null => useContext(IntentGroupDispatchContext);

// #endregion

// #region intent group reducer

export type intentGroupReducerAction = {
  type: IntentGroupActionType;
  payload?: Array<IntentGroup> | IntentGroup | string;
};

/**
 * The reducer that handles the updating of the context for intent groups.
 * @param context - The state to be updated
 * @param action - The action to be executed by the reducer
 * @returns Updated state
 */
export const intentGroupReducer = (
  context: intentGroupContextAttributes,
  action: intentGroupReducerAction,
): intentGroupContextAttributes => {
  if (action.type === IntentGroupActionType.set) {
    const update = action.payload as Array<IntentGroup>;

    return { ...context, intentGroups: update } satisfies intentGroupContextAttributes;
  } else {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
};

// #endregion
