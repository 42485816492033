export enum ContainerNames {
  Answers = "answers",
  Dialogs = "dialogs",
  Faq = "faq",
  GroupIncidents = "groupincidents",
  Highlights = "highlights",
  Intents = "intents",
  IntentGroups = "intentgroups",
  KnowledgeSources = "knowledgesources",
}
