// TODO 4098: remove this file with staging/live adaptation
import { KnowledgeSource } from "../models/KnowledgeSources/KnowledgeSource";
import { StorageEnvironment, createItem, deleteItem, getContainer, updateItem } from "./StorageInteraction";

const KnowledgeSourcesDataKey = "KnowledgeSources";

/**
 * Retrieves the knowledge sources from the context.
 * @param clientId - The client ID to use for the API call
 * @returns The knowledge sources from the context.
 */
export const getKnowledgeSources = async (clientId: string | null): Promise<Array<KnowledgeSource>> =>
  await getContainer(StorageEnvironment.Live, KnowledgeSourcesDataKey, clientId)
    .then((data) => data as Array<KnowledgeSource>)
    .catch((error: Error) => {
      throw new Error(`Failed to retrieve data: ${error}`);
    });

/**
 * Updates the local and external data
 * @param newKnowledgeSources - The new knowledge sources to create
 */
export const createKnowledgeSource = (newKnowledgeSources: KnowledgeSource): void => {
  void createItem(StorageEnvironment.Live, KnowledgeSourcesDataKey, newKnowledgeSources).catch((error: Error) => {
    throw new Error(`Failed to create source: ${error}`);
  });
};

/**
 * Updates the external data
 * @param updatedKnowledgeSource - The updated knowledge source
 * @param clientId - The client ID to use for the API call
 */
export const updateKnowledgeSource = (updatedKnowledgeSource: KnowledgeSource, clientId: string | null): void => {
  void updateItem(
    StorageEnvironment.Live,
    KnowledgeSourcesDataKey,
    updatedKnowledgeSource.key,
    updatedKnowledgeSource,
    clientId,
  ).catch((error: Error) => {
    throw new Error(`Failed to update source: ${error}`);
  });
};

/**
 * Updates the local and external data
 * @param key - The key of the knowledge source to delete
 * @param clientId - The client ID to use for the API call
 */
export const deleteKnowledgeSource = (key: string, clientId: string | null): void => {
  void deleteItem(StorageEnvironment.Live, KnowledgeSourcesDataKey, key, clientId).catch((error: Error) => {
    throw new Error(`Failed to delete source: ${error}`);
  });
};
