import axios, { AxiosRequestConfig } from "axios";

import { FaqItem } from "../models/Faqs";

import { ApiMethod } from "../enums/ApiMethod";

import { webApiConfig } from "./ApiConfiguration";
import { getToken } from "./Auth/Auth";

/**
 * Generic function to setup an API call
 * @param method - The method to use for the API call
 * @param clientId - The client ID to use for the API call
 * @param item - The item to send in the request
 * @param endpoint - The endpoint to send the request to
 * @returns The response from the API
 */
export const genericFaqCall = async (
  method: ApiMethod,
  clientId: string | null,
  item?: FaqItem,
  endpoint = "",
): Promise<Array<FaqItem>> => {
  try {
    // set the base endpoint for file uploads
    const url = `${webApiConfig.endpointUrl}/Faq${endpoint}`;
    const token = await getToken();

    const requestConfig: AxiosRequestConfig = {
      params: {
        clientId,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    switch (method) {
      case ApiMethod.GET:
        return (await axios.get<Array<FaqItem>>(url, requestConfig)).data;
      case ApiMethod.POST:
        return await axios.post(url, item, requestConfig);
      case ApiMethod.DELETE:
        return await axios.delete(url, requestConfig);
      default:
        throw new Error("Unsupported method for faq request");
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        `API call failed, error message: ${error.message}, request url: ${error.config?.url}, info: ${error.response?.data.title}`,
      );
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Get the Faq items from the database
 * @param clientId - The client ID to get the Faq items for
 * @returns The Faq items from the database
 */
export const getFaqItems = async (clientId: string | null): Promise<Array<FaqItem>> =>
  await genericFaqCall(ApiMethod.GET, clientId);

/**
 * Add new Faq items to external database
 * @param data - The Faq items to add
 * @param clientId - The client ID to add the Faq items for
 */
export const addFaqItem = (data: FaqItem, clientId: string | null): void => {
  void genericFaqCall(ApiMethod.POST, clientId, data);
};

/**
 * Delete all Faq items from the database
 * @param question - The question to delete
 * @param clientId - The client ID to delete the Faq items for
 */
export const deleteFaqItem = (question: string, clientId: string | null): void => {
  const endpoint = `/${encodeURIComponent(question)}`;
  void genericFaqCall(ApiMethod.DELETE, clientId, undefined, endpoint);
};
