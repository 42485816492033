import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { ApiMethod } from "../enums/ApiMethod";

import { webApiConfig } from "./ApiConfiguration";
import { getToken } from "./Auth/Auth";

/**
 * Generic function to setup an API call
 * @param method - The HTTP method to use
 * @param item - The item to send in the body of the request
 * @param endpoint - The endpoint to send the request to
 * @returns The response from the API
 */
export const genericCallSetup = async <T>(
  method: ApiMethod,
  item?: T,
  endpoint = "",
): Promise<AxiosResponse<T, any>> => {
  try {
    const url = `${webApiConfig.endpointUrl}/FileUploads${endpoint}`;
    const token = await getToken();

    const headers = {
      Authorization: token,
      "Content-Type": method === ApiMethod.PUT ? "multipart/form-data" : "application/json",
      "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
    };

    const requestConfig: AxiosRequestConfig = {
      headers,
      signal: new AbortController().signal,
    };

    let response: AxiosResponse<T, any>;

    switch (method) {
      case ApiMethod.PUT:
        response = await axios.put<T>(url, item, requestConfig);
        break;
      case ApiMethod.DELETE:
        response = await axios.delete<T>(url, requestConfig);
        break;
      default:
        throw new Error("Unsupported method for file upload request");
    }

    return response;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(
        `API call failed, error message: ${error.message}, request url: ${error.config?.url}, info: ${error.response?.data.title}`,
      );
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Create a file in blob storage
 * @param item - The item to send in the body of the request
 */
export const createFile = <T>(item: T): void => {
  genericCallSetup(ApiMethod.PUT, item).catch((error: Error) => {
    throw new Error(`Failed to upload file: ${error}`);
  });
};

/**
 * Delete an file from blob storage
 * @param filePath - the file path of the file to delete
 */
export const deleteFile = (filePath: string): void => {
  const endpoint = `/file/${filePath}`;

  genericCallSetup(ApiMethod.DELETE, null, endpoint).catch((error: Error) => {
    throw new Error(`Failed to delete file: ${error}`);
  });
};

/**
 * Delete an folder/source from blob storage
 * @param folderName - the fodler name of the folder to delete
 */
export const deleteFolder = (folderName: string): void => {
  const endpoint = `/folder/${folderName}`;

  genericCallSetup(ApiMethod.DELETE, null, endpoint).catch((error: Error) => {
    throw new Error(`Failed to delete folder: ${error}`);
  });
};
