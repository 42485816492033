import React, { FC, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";

import { findDifference } from "../../../helpers/ObjectDifferences";

// Data Imports
import { LanguageText } from "../../../models/Language";

interface AccordionContentAnswerProps {
  record: Record<string, any>;
  differences?: Record<string, boolean | Record<string, boolean>>;
}

/**
 * An accordion content item for highlights.
 * @param AccordionContentAnswerProps - the props for the component.
 * @param AccordionContentAnswerProps.record - the record to display.
 * @param AccordionContentAnswerProps.differences - the differences to highlight.
 * @returns a component for displaying the answer.
 */
const AccordionContentAnswer: FC<AccordionContentAnswerProps> = ({ record, differences }) => {
  // state
  const [languagesOpen, setLanguagesOpen] = useState<boolean>(false);

  /**
   * function to reduce code required in setting bg colors on differences.
   * @param key - the key to check.
   * @returns the background color.
   */
  const setBackgroundColor = (key: string): string => {
    const shouldBeSet = findDifference(key, differences);
    if (shouldBeSet === undefined) return "white";
    else return shouldBeSet ? "rgba(0, 100, 180, 0.20)" : "white";
  };

  /**
   * Handle the opening of the languages section.
   */
  const handleLanguagesOpen = (): void => {
    setLanguagesOpen(!languagesOpen);
  };

  return (
    <List key={`answer-change-preview`} disablePadding sx={{ width: "100%" }}>
      <ListItem
        data-testid={`item-key`}
        key={`answer-change-preview-key`}
        sx={{
          backgroundColor: setBackgroundColor("key"),
        }}
      >
        <ListItemAvatar>
          <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Naam`} />
        </ListItemAvatar>
        <ListItemText>{record.key}</ListItemText>
      </ListItem>
      <ListItemButton
        data-testid={`expand-languages`}
        key={`answer-change-preview-languages`}
        sx={{
          backgroundColor: setBackgroundColor("languages"),
        }}
        onClick={handleLanguagesOpen}
      >
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
          <ListItemAvatar>
            <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Talen`} />
          </ListItemAvatar>
          <Box data-testid="expand-languages-actions">{languagesOpen ? <ExpandLess /> : <ExpandMore />}</Box>
        </Stack>
      </ListItemButton>
      <Collapse in={languagesOpen} timeout={"auto"} unmountOnExit>
        <List
          key={`answer-change-preview-languages-list`}
          disablePadding
          sx={{
            width: "100%",
            backgroundColor: setBackgroundColor("languages"),
          }}
        >
          {record.languages.map((language: LanguageText) => (
            <ListItem
              data-testid={`language-key-${language.key}`}
              key={`answer-change-preview-languages-${language.key}`}
            >
              <ListItemAvatar>
                <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={language.key} />
              </ListItemAvatar>
              <ListItemText>{language.value}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
      <ListItem
        data-testid={"record-type"}
        key={`answer-change-preview-type`}
        sx={{
          backgroundColor: setBackgroundColor("type"),
        }}
      >
        <ListItemAvatar>
          <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Type`} />
        </ListItemAvatar>
        <ListItemText>{record.type}</ListItemText>
      </ListItem>
    </List>
  );
};

export default AccordionContentAnswer;
