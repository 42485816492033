import React, { FC, useEffect, useState } from "react";

import _ from "lodash";

import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { deepCopy } from "../../helpers/DeepCopy";

import { useClientContext } from "../../contexts/ClientContext";

import { ClientDetails } from "../../models/Client";

import { getClient, updateClientConfig } from "../../API/ClientInteraction";
import ClientLoadingIndicator from "./ClientLoadingIndicator";

/**
 * The client configurator component that allows for editing of client information
 * @returns The ClientConfigurator component
 */
const ClientConfigurator: FC = () => {
  // Configurator data state
  const [preserve, setPreserve] = useState<ClientDetails>();
  const [client, setClient] = useState<ClientDetails>();

  // Configurator form state
  const [nameError, setNameError] = useState<boolean>(false);
  const [contextError, setContextError] = useState<boolean>(false);
  const [contextLength, setContextLength] = useState<number>();

  // Context
  const clientContext = useClientContext();

  useEffect(() => {
    loadClientDetails().catch((error) => {
      throw new Error(`Could not load client specific details for configuration: ${error}`);
    });
  }, [clientContext?.clientId]);

  useEffect(() => {
    if (client === undefined) return;

    setContextLength(client.config.clientContext.length);
  }, [client?.config.clientContext]);

  /**
   * Load the currently selected client's data
   * @returns void
   */
  const loadClientDetails = async (): Promise<void> => {
    if (clientContext === null) return undefined;

    const client: ClientDetails = await getClient(clientContext.clientId);
    setClient(client);
    setPreserve(client);
  };

  /**
   * Handle the changing of the context in the client configuration
   * @param event - The event that triggered this function
   */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (client === undefined) return;

    const update: ClientDetails = deepCopy(client);

    if (typeof event.target.value == "string" && event.target.value.length < 1) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    update.clientName = event.target.value;
    setClient(update);
  };

  /**
   * Handle the changing of the context in the client configuration
   * @param event - The event that triggered this function
   */
  const handleContextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (client === undefined) return;

    const update: ClientDetails = deepCopy(client);

    if (typeof event.target.value == "string" && event.target.value.length < 1) {
      setContextError(true);
    } else {
      setContextError(false);
    }

    update.config.clientContext = event.target.value;
    setClient(update);
  };

  /**
   * Handle the saving of the edited client config
   */
  const handleClientConfigSave = (): void => {
    if (client === undefined) return;

    void updateClientConfig(client);
    setPreserve(client);
  };

  if (client === undefined) {
    return <ClientLoadingIndicator />;
  }

  return (
    <Box width={"100%"} height={"100%"} display={"grid"}>
      <Stack
        divider={<Divider variant="middle" orientation="horizontal" />}
        direction="column"
        spacing={2}
        sx={{ width: "100%", maxWidth: 750, justifySelf: "center", paddingTop: "10px" }}
      >
        <Box sx={{ width: "100%", maxWidth: 750 }}>
          <Typography variant="h4" gutterBottom>
            Informatie binnen de gesprekscontext bewerken
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            De chatbot heeft wat informatie nodig binnen de gesprekscontext over u, de klant, om antwoorden te kunnen
            geven alsof deze echt deel is van uw bedrijf.
          </Typography>
        </Box>
        <Stack direction="column" spacing={2}>
          <TextField
            label={"Bedrijfsnaam"}
            value={client.clientName}
            onChange={handleNameChange}
            error={nameError}
            helperText={nameError && "Dit veld mag niet leeg zijn."}
          />
          <TextField
            label={`Bedrijfscontext ${contextLength ?? 0}/300`}
            multiline
            maxRows={4}
            value={client.config.clientContext ?? ""}
            onChange={handleContextChange}
            error={contextError}
            helperText={contextError && "Dit veld mag niet leeg zijn."}
            slotProps={{ htmlInput: { maxLength: 300 } }}
          />
          <Button
            endIcon={<SaveIcon />}
            onClick={handleClientConfigSave}
            disabled={nameError || contextError || _.isEqual(preserve, client)}
          >
            Opslaan
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ClientConfigurator;
