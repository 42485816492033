import React, { FC } from "react";

import { Box } from "@mui/material";

import { StepTypes } from "../../../../enums/StepTypes";

import styles from "../Visualisation_Dialog.module.scss";

export interface StepTypeProps {
  name: string;
  type: string;
}

/**
 * StepType Component
 * @param StepTypeProps - The properties of the StepType component.
 * @param StepTypeProps.name - The name of the step.
 * @param StepTypeProps.type - The type of the step.
 * @returns A StepType component.
 */
export const StepType: FC<StepTypeProps> = ({ name, type }: StepTypeProps) => {
  /**
   * On drag start event handler.
   * @param event - The drag event.
   */
  const onDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
    event.dataTransfer.setData("application/reactflow", type);
    event.dataTransfer.effectAllowed = "move";
  };

  /**
   * Gets the extended class name for the block.
   * @param type - The type of the block.
   * @returns The extended class name for the block.
   */
  const getBlockExtendedClassName = (type: string): string => {
    switch (type) {
      case StepTypes.Message:
        return styles.message;
      case StepTypes.Conditional:
        return styles.conditional;
      case StepTypes.AskWithButtons:
        return styles.askWithButtons;
      case StepTypes.YesNoDialog:
        return styles.yesNoDialog;
      case StepTypes.TextPrompt:
        return styles.textPrompt;
      case StepTypes.CreateTicketDialog:
        return styles.createTicketDialog;
      case StepTypes.Redirect:
        return styles.redirect;
      case StepTypes.LLM:
        return styles.llm;
      default:
        return "";
    }
  };

  return (
    <Box
      data-testid="step"
      onDragStart={(event) => {
        onDragStart(event);
      }}
      className={`${styles.block} ${getBlockExtendedClassName(type)} `}
      draggable
    >
      {name}
    </Box>
  );
};
