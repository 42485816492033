import React, { FC, createContext, useContext, useEffect, useMemo, useReducer } from "react";

import Cookies from "js-cookie";

import { Client } from "../models/Client";

import resources from "../resources/resources.json";

import { getClients } from "../API/ClientInteraction";
import { setWebChatClientId } from "../API/WebchatInteractions";

const initialClientContext: ClientContextAttributes = {
  clientId: null,
  clientIdList: [],
};

const ClientContext = createContext<ClientContextAttributes | null>(initialClientContext);

interface ClientContextProps {
  children: React.ReactNode;
}

export enum ClientActionType {
  setClientId,
  setClientIdList,
}

export type ClientContextAttributes = {
  clientId: string | null;
  clientIdList: Array<Client>;
  setClientId?: (clientId: string) => void;
  updateClientId?: (clientId: string) => void;
  setClientIdList?: (clientIdList: Array<Client>) => void;
};

/**
 * Context provider client (ID and name) for the use of super admin panel
 * @param ClientContextProps - The props for the component
 * @param ClientContextProps.children - The children of the component
 * @returns The client context provider
 */
export const ClientContextProvider: FC<ClientContextProps> = ({ children }) => {
  const isMultiTenant: boolean = process.env.REACT_APP_IS_MULTI_TENANT === "true";

  const [context, dispatch] = useReducer(clientReducer, initialClientContext);

  useEffect(() => {
    if (isMultiTenant) {
      void getClients().then((response) => {
        setClientIdList(response);
        if (context.clientId === null) {
          const cookie = Cookies.get(resources.cookieNames.lastClientIdCookie);
          if (cookie !== null && cookie !== undefined && response.find((x) => x.clientId === cookie) !== null) {
            setClientId(cookie);
          } else {
            setClientId(response[0].clientId);
            Cookies.set(resources.cookieNames.lastClientIdCookie, response[0].clientId);
          }
        }
      });
    }
  }, []);

  /**
   * Set the client ID
   * @param clientId - The client ID.
   */
  const setClientId = (clientId: string): void => {
    if (clientId === context.clientId) return;

    const action: ClientReducerAction = {
      type: ClientActionType.setClientId,
      payload: clientId,
    };
    dispatch(action);
  };

  /**
   * Set the client ID and update the chat clientID
   * @param clientId - The client ID.
   */
  const updateClientId = (clientId: string): void => {
    setClientId(clientId);
    setWebChatClientId(clientId);
  };

  /**
   * Set the clientIdList
   * @param clientIdList - The list of client IDs.
   */
  const setClientIdList = (clientIdList: Array<Client>): void => {
    const action: ClientReducerAction = {
      type: ClientActionType.setClientIdList,
      payload: clientIdList,
    };
    dispatch(action);
  };

  const _context: ClientContextAttributes = useMemo(
    () => ({
      clientId: context.clientId,
      clientIdList: context.clientIdList,
      setClientId,
      updateClientId,
      setClientIdList,
    }),
    [context.clientId, context.clientIdList],
  );

  return <ClientContext.Provider value={_context}>{children}</ClientContext.Provider>;
};

/**
 * Get the client context
 * @returns The client context
 */
export const useClientContext = (): ClientContextAttributes | null => useContext(ClientContext);

/**
 * Client reducer action
 */
export type ClientReducerAction = {
  type: ClientActionType;
  payload: string | Array<Client>;
};

/**
 * Client reducer
 * @param context - The current context.
 * @param action - The action to perform.
 * @returns The updated context.
 */
export const clientReducer = (
  context: ClientContextAttributes,
  action: ClientReducerAction,
): ClientContextAttributes => {
  switch (action.type) {
    case ClientActionType.setClientId:
      return { ...context, clientId: action.payload as string };
    case ClientActionType.setClientIdList:
      return { ...context, clientIdList: action.payload as Array<Client> };
  }
};
