import { GridRowModel } from "@mui/x-data-grid";

import { externalStorageContextAttributes } from "../../contexts/ChangeTracking/ExternalStorageContext";
import { localStorageContextAttributes } from "../../contexts/ChangeTracking/LocalStorageContext";

import { LanguageText } from "../../models/Language";

import { ChangeType } from "../../enums/ChangeType";

import { EditorContentArray, EditorContentType } from "../Editors/ModularEditor/ModularEditor";

/**
 * Reusable function to format based on the storage context.
 * @param storageContext - The storage context to apply changes from.
 * @param containerName - The name of the container to get changes for.
 * @returns An array of strings representing the changes made in the storage context.
 */
export const getChanges = (
  storageContext: localStorageContextAttributes | externalStorageContextAttributes,
  containerName: string,
): Array<string> => {
  const changeBucket = storageContext.changeBuckets.find((bucket) => bucket.key === containerName);
  if (changeBucket === undefined) return [];

  return changeBucket.changes
    .filter((change) => change.changeType !== ChangeType.Delete)
    .map((change) => change.newValue.key);
};

/**
 * Returns a string representing the class name for a row.
 * @param gridRowModel - A grid row model object
 * @param stagedChanges - An array of strings representing the staged changes.
 * @param localChanges - An array of strings representing the local changes.
 * @returns returns row bold if element has been edited. Otherwise returns empty string.
 */
export const getRowClassName = (
  gridRowModel: GridRowModel,
  stagedChanges: Array<string>,
  localChanges: Array<string>,
): string => {
  if (stagedChanges.some((key) => key === gridRowModel.row.key)) return "stage";
  if (localChanges.some((key) => key === gridRowModel.row.key)) return "local";

  return "";
};

/**
 * Returns a string representing the class name for a row.
 * @param oldRow - The old row object.
 * @param newRow - The new row object.
 * @param defaultLanguage - The default language to update.
 * @returns A new row object with the updated languages.
 */
export const updateRow = (oldRow: GridRowModel, newRow: GridRowModel, defaultLanguage: string): GridRowModel => {
  const oldRowAsEditorContentType = oldRow as EditorContentType;

  const updatedLanguages = oldRowAsEditorContentType.languages.map((language) => {
    if (language.key === defaultLanguage && language.value !== newRow.value) {
      return {
        key: defaultLanguage,
        value: newRow.value,
      };
    }

    return language;
  });

  delete newRow.value;

  const updatedRow = { ...newRow, languages: updatedLanguages };

  return updatedRow;
};

/**
 * Updates the languages of an item.
 * @param dataEntries - The data entries to update.
 * @param key - The key of the item to update.
 * @param languageText - The language text to update.
 * @returns The updated data entries.
 */
export const addOrUpdateLanguage = (
  dataEntries: EditorContentArray,
  key: string,
  languageText: LanguageText,
): { oldItem: EditorContentType; updatedItem: EditorContentType } => {
  const oldItem = dataEntries.find((item) => item.key === key);
  if (oldItem === undefined) throw new Error("impossible");

  let languages: Array<LanguageText>;
  if (oldItem.languages.some((language) => language.key === languageText.key)) {
    languages = oldItem.languages.map((language) => {
      if (language.key === languageText.key) return { ...language, value: languageText.value };

      return language;
    });
  } else {
    languages = [...oldItem.languages, languageText];
  }

  const updatedItem = { ...oldItem, languages };

  return { oldItem, updatedItem };
};

/**
 * Updates the languages of an item.
 * @param dataEntries - The data entries to update.
 * @param selectedDataEntryKey - The key of the selected data entry.
 * @param languageKey - The key of the language to update.
 * @returns The updated data entries.
 */
export const removeItemLanuage = (
  dataEntries: EditorContentArray,
  selectedDataEntryKey: string,
  languageKey: string,
): { oldItem: EditorContentType; updatedItem: EditorContentType } => {
  const oldItem = dataEntries.find((item) => item.key === selectedDataEntryKey);
  if (oldItem === undefined) throw new Error("impossible");

  const updatedItem = { ...oldItem, languages: oldItem.languages.filter((language) => language.key !== languageKey) };

  return { oldItem, updatedItem };
};
