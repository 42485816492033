import { DialogVersion } from "./DialogVersion";

export const dialog: DialogModel = {
  key: "",
  name: "",
  intent: "",
  versions: [],
};

export interface DialogModel {
  key: string;
  name: string;
  intent: string;
  versions: Array<DialogVersion>;
}
