export interface Language {
  key: string;
  name: string;
}

export interface LanguageText {
  key: string;
  value: string;
}

export const languages: Array<Language> = [
  { key: "NL", name: "Nederlands" },
  { key: "EN", name: "Engels" },
  { key: "DE", name: "Duits" },
  { key: "FR", name: "Frans" },
];
