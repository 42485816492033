import React, { FC, useState } from "react";

import { List } from "@mui/material";

import { useExternalStorageContext } from "../../contexts/ChangeTracking/ExternalStorageContext";
import { useLocalStorageContext } from "../../contexts/ChangeTracking/LocalStorageContext";

import { drawerGroups } from "../../models/Drawer/DrawerGroups";

import DrawerGroupItem from "./DrawerGroupItem";
import DropDownwithSync from "./DropDownWithSync";

/**
 * Builds the list of items that will be placed in the drawer
 * @returns The DrawerContents component
 */
const DrawerContents: FC = () => {
  const [selectedTab, setSelectedTab] = useState<[string, number]>();
  const externalStorageContext = useExternalStorageContext();
  const localStorageContext = useLocalStorageContext();

  if (externalStorageContext === null || localStorageContext === null) {
    throw new Error("Contexts should not be null");
  }

  return (
    <List data-testid="drawer-contents" key={"page-routes-drawer"} disablePadding>
      {drawerGroups.map((group) => (
        <DrawerGroupItem
          key={`drawer-group-${group.name}`}
          group={group}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      ))}
      <DropDownwithSync />
    </List>
  );
};

export default DrawerContents;
