import React, { FC } from "react";

import { Box, CircularProgress, List } from "@mui/material";

import { externalStorageContextAttributes } from "../../contexts/ChangeTracking/ExternalStorageContext";
import { localStorageContextAttributes } from "../../contexts/ChangeTracking/LocalStorageContext";

import { ChangeBucket } from "../../models/ChangeTracking/ChangeBucket";

import SaveList from "./SaveList";

export interface ChangesToSaveProps {
  context: localStorageContextAttributes | externalStorageContextAttributes | null;
}

/**
 * Changes to save component
 * @param ChangesToSaveProps - the props for the component
 * @param ChangesToSaveProps.context - the context to use
 * @returns a react component
 */
const ChangesToSave: FC<ChangesToSaveProps> = ({ context }) => {
  if (context !== null) {
    return (
      <>
        <Box data-testid="title" component="span">
          Weet je zeker dat je de wijzigingen wil opslaan?
        </Box>
        <List data-testid="save-list" sx={{ bgcolor: "background.paper" }}>
          {context.changeBuckets.map((bucket: ChangeBucket) => {
            if (bucket.key === "intents" || bucket.changes.length <= 0) return null;

            return (
              <SaveList
                data-testid={`list-${bucket.key}`}
                key={`list-${bucket.key}`}
                changeBucket={bucket}
                context={context}
              />
            );
          })}
        </List>
      </>
    );
  } else {
    return <CircularProgress data-testid="loading-indicator" />;
  }
};

export default ChangesToSave;
