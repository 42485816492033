import React, { FC } from "react";

import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";

/**
 * SkeletonLoader Component
 * @returns A react component to indicate that the page is loading.
 */
const SkeletonLoader: FC = () => (
  <Grid container spacing={2} columnSpacing={2}>
    <Grid size={{ xs: 2 }}>
      <Skeleton variant="rounded" width={110} height={36} />
    </Grid>
    <Grid size={{ xs: 12 }}>
      <Skeleton variant="rounded" height="50vh" />
    </Grid>
  </Grid>
);

export default SkeletonLoader;
