import React, { FC, RefObject } from "react";

import { Button } from "@mui/material";
import { Stack } from "@mui/system";

interface FileUploadButtonProps {
  border?: boolean;
  handleButtonClick: () => void;
  handleFileChoice: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: RefObject<HTMLInputElement>;
}

/**
 * The file upload button and drag-and-drop text
 * @param FileUploadButtonProps - the properties of the component
 * @param FileUploadButtonProps.border - whether to set the border
 * @param FileUploadButtonProps.handleButtonClick - function to handle clicking the button
 * @param FileUploadButtonProps.handleFileChoice - function to handle choosing a file
 * @param FileUploadButtonProps.fileInputRef - reference to the file input element
 * @returns - the file upload button and drag-and-drop text
 */
const FileUploadButton: FC<FileUploadButtonProps> = ({ border, handleButtonClick, handleFileChoice, fileInputRef }) => (
  <Stack
    data-testid="drop-zone"
    direction="row"
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
      height: "50px",
      borderBottom: border ? "2px dashed black" : "",
    }}
  >
    <p> Sleep bestanden hierheen of </p>
    <Button variant="contained" color="info" onClick={handleButtonClick} sx={{ maxHeight: "25px" }}>
      Kies een bestand
    </Button>
    <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChoice} multiple />
  </Stack>
);

export default FileUploadButton;
