import React, { ChangeEvent, FC, ReactElement, useEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { deepCopy } from "../../../helpers/DeepCopy";

import { documentConfig } from "../../../models/KnowledgeSources/DocumentConfig";
import {
  KnowledgeSource,
  knowledgeSource,
  knowledgeSourceTypeMessage,
} from "../../../models/KnowledgeSources/KnowledgeSource";
import { UrlConfig, urlConfig } from "../../../models/KnowledgeSources/UrlConfig";

import { KnowledgeSourceType } from "../../../enums/KnowledgeSourceType";

import FileUpload from "../../FileUpload/FileUpload";
import { isUrlDuplicate, isUrlInvalid, isUrlType } from "../../KnowledgeSources/KnowledgeSourcesHelper";

interface CreateUploadSourceDialogProps {
  allKnowledgeSources: Array<KnowledgeSource>;
  handleSourceUpload: (newSource: KnowledgeSource) => void;
  uploadFiles: (files: Array<File>, sourceName: string) => void;
  disabled: boolean;
}

/**
 * The functional component that handles uploading a new knowledge source.
 * @param CreateUploadSourceDialogProps - The properties of the component.
 * @param CreateUploadSourceDialogProps.allKnowledgeSources - An array of all the current knowledge sources.
 * @param CreateUploadSourceDialogProps.handleSourceUpload - A function that handles the upload of a new knowledge source.
 * @param CreateUploadSourceDialogProps.uploadFiles - A function that handles the upload of files.
 * @param CreateUploadSourceDialogProps.disabled - A boolean that determines if the button is disabled.
 * @returns A React functional component.
 */
const CreateUploadSourceDialog: FC<CreateUploadSourceDialogProps> = ({
  allKnowledgeSources,
  handleSourceUpload,
  uploadFiles,
  disabled,
}) => {
  // Dialog configuration
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");

  // The new knowledge source
  const [newSource, setNewSource] = useState<KnowledgeSource>(deepCopy(knowledgeSource));

  // Dialog states
  const [open, setOpen] = useState<boolean>(false);

  // States for showing validation errors
  const [sourceNameError, setSourceNameError] = useState<string>("");
  const [sourceUrlError, setSourceUrlError] = useState<string>("");

  // The new File objects to upload
  const [files, setFiles] = useState<Array<File>>([]);

  /** Open with a new default source */
  useEffect(() => {
    if (open) {
      setNewSource(deepCopy(knowledgeSource));
    }
  }, [open]);

  /** Check if name is not duplicate */
  useEffect(() => {
    const duplicate: boolean = allKnowledgeSources.some((source) => source.name === newSource.name);
    setSourceNameError(duplicate ? "Kennisbron naam bestaat al" : "");
  }, [newSource.name]);

  /** Check if url is not duplicate or invalid */
  useEffect(() => {
    if (newSource.type !== "" && isUrlType(newSource.type)) {
      if (isUrlDuplicate(newSource, allKnowledgeSources)) {
        setSourceUrlError("Url bestaat al");
      } else if (isUrlInvalid(newSource)) {
        setSourceUrlError("Url is ongeldig");
      } else {
        setSourceUrlError("");
      }
    }
  }, [newSource]);

  /**
   * Handles setting several input values
   * @param event - user input from HTML element
   * @param property - The property to set
   */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, property: string): void => {
    const updatedSource = { ...newSource };

    event.preventDefault();
    switch (property) {
      case "name":
        updatedSource.name = event.target.value;
        break;
      case "description":
        updatedSource.description = event.target.value;
        break;
      case "type": {
        updatedSource.type = KnowledgeSourceType[event.target.value as keyof typeof KnowledgeSourceType];

        // When setting the type, set config with default values
        if (updatedSource.type === KnowledgeSourceType.DocumentUpload) {
          updatedSource.config = deepCopy(documentConfig);
        } else {
          updatedSource.config = deepCopy(urlConfig);
        }
        break;
      }
      case "url":
        (updatedSource.config as UrlConfig).url = event.target.value;
        break;
      case "recursive": {
        const checkEvent = event as ChangeEvent<HTMLInputElement>;
        (updatedSource.config as UrlConfig).recursive = checkEvent.target.checked;
        break;
      }
    }
    setNewSource(updatedSource);
  };

  /**
   * Handles opening the dialog with the specified scroll type.
   * @param scrollType - The scroll type for the dialog (e.g., 'paper', 'body', 'paperScrollBody', etc.).
   * @returns void
   */
  const handleOpen = (scrollType: DialogProps["scroll"]) => (): void => {
    setScroll(scrollType);
    setOpen(true);
  };

  // The variable that handles live updating form validation
  const validSubmission: boolean = [
    newSource.name !== "",
    newSource.type !== "",
    newSource.type === KnowledgeSourceType.DocumentUpload ? files.length !== 0 : newSource.config.url !== "",
    sourceNameError === "",
    sourceUrlError === "",
  ].every(Boolean);

  /** Handle submitting a new knowledge source */
  const handleSubmit = (): void => {
    newSource.key = newSource.name;
    newSource.lastModified = new Date().toISOString();
    if (newSource.type === KnowledgeSourceType.DocumentUpload) {
      // Generate a unique id used to indentify files in sync apps
      const folderId = uuidv4();
      newSource.config.folderId = folderId;
      newSource.config.filenames = files.map((file) => file.name);
      uploadFiles(files, folderId);
    }

    handleSourceUpload(newSource);
    handleClose();
  };

  /** Handles closing the dialog. */
  const handleClose = (): void => {
    setOpen(false);
    setFiles([]);
    setNewSource(deepCopy(knowledgeSource));
  };

  /**
   * Returns grid component based on source type
   * @returns Type of document to upload component
   */
  const typeComponent = (): ReactElement => {
    if (newSource.type === "") {
      return <Grid></Grid>;
    }

    if (newSource.type === KnowledgeSourceType.DocumentUpload) {
      return <FileUpload setFiles={setFiles} />;
    } else {
      return (
        <>
          <Grid size={{ xs: 12 }}>
            <TextField
              id="input-url"
              size="small"
              label="URL"
              fullWidth
              helperText={sourceUrlError}
              error={sourceUrlError !== ""}
              value={newSource.config.url}
              onChange={(event) => {
                handleInputChange(event, "url");
              }}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newSource.config.recursive}
                  onChange={(event) => {
                    handleInputChange(event, "recursive");
                  }}
                  name="recursive"
                  color="primary"
                />
              }
              label="Recursief"
            />
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen("paper")}
        startIcon={<LibraryBooksIcon />}
        disabled={disabled}
        sx={{ height: 50 }}
      >
        Kennisbron toevoegen
      </Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll}>
        <DialogTitle>Nieuwe Kennisbron</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText style={{ color: "black" }}>
            Vul de benodigde informatie in om een nieuwe kennisbron aan te maken.
          </DialogContentText>
          <Button
            style={{
              right: "5px",
              top: "5px",
              position: "absolute",
              color: "black",
              fontSize: "17px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            X
          </Button>
          <Grid container gap={2} paddingTop={2}>
            <Grid size={{ xs: 12 }}>
              <TextField
                id="input-name"
                size="small"
                label="Kennisbron naam"
                fullWidth
                helperText={sourceNameError}
                error={sourceNameError !== ""}
                value={newSource.name}
                onChange={(event) => {
                  handleInputChange(event, "name");
                }}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField
                id="input-description"
                size="small"
                label="Beschrijving"
                fullWidth
                value={newSource.description}
                onChange={(event) => {
                  handleInputChange(event, "description");
                }}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField
                id="input-type"
                size="small"
                select
                label="Kennisbron type"
                fullWidth
                value={newSource.type}
                onChange={(event) => {
                  handleInputChange(event, "type");
                }}
              >
                {knowledgeSourceTypeMessage.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {typeComponent()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={!validSubmission} onClick={handleSubmit}>
            Opslaan
          </Button>
          <Button color="error" onClick={handleClose}>
            Annuleren
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateUploadSourceDialog;
