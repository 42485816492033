import React, { FC } from "react";

import { Box, MenuItem, TextField } from "@mui/material";

import { TopDeskItem } from "../../../../models/Itsm/TopDesk/TopDeskItem";
import { TopDeskSubcategory } from "../../../../models/Itsm/TopDesk/TopDeskSubcategory";

interface CustomSelectProps {
  handleChange: (value: string) => void;
  isMandatory?: boolean;
  itemList: Array<TopDeskItem | TopDeskSubcategory>;
  label: string;
  selectId: string;
  selectedValue: string;
}

/**
 * CustomSelect Component
 * @param CustomSelectProps - The properties of the component.
 * @param CustomSelectProps.handleChange - The function to call when the selected value changes.
 * @param CustomSelectProps.isMandatory - Whether the select is mandatory.
 * @param CustomSelectProps.itemList - The list of items to display in the select.
 * @param CustomSelectProps.label - The label of the select.
 * @param CustomSelectProps.selectId - The id of the select.
 * @param CustomSelectProps.selectedValue - The selected value of the select.
 * @returns A React component representing a custom select.
 */
const CustomSelect: FC<CustomSelectProps> = ({
  handleChange,
  isMandatory,
  itemList,
  label,
  selectId,
  selectedValue,
}) =>
  itemList == null || itemList.length === 0 ? (
    <></>
  ) : (
    <Box mb={2}>
      <TextField
        sx={{
          color: `${(isMandatory ?? false) && selectedValue === "-1" ? "red" : "black"}`,
        }}
        select
        fullWidth
        label={label}
        id={selectId}
        value={selectedValue}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      >
        {itemList.map((el) => (
          <MenuItem value={el.id} key={el.id}>
            {el.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );

export default CustomSelect;
