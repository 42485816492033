import { ChangeBucket } from "../models/ChangeTracking/ChangeBucket";
import { TrackedChange } from "../models/ChangeTracking/TrackedChange";

import { ApiMethod } from "../enums/ApiMethod";

import { webApiConfig } from "./ApiConfiguration";
import { apiCall, getRequestHeaders } from "./ApiHelpers";
import { StorageEnvironment } from "./StorageInteraction";

/**
 * Get the change buckets from the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the storage that can be put directly into the context.
 */
export const getBuckets = async (storageEnvironment: StorageEnvironment): Promise<Array<ChangeBucket>> => {
  const url = `${webApiConfig.endpointUrl}/ChangeTracking`;

  const requestConfig = { params: { environment: storageEnvironment }, ...(await getRequestHeaders()) };

  const response = await apiCall<Array<ChangeBucket>>(url, ApiMethod.GET, requestConfig);

  return response;
};

/**
 * Get the change buckets from the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param name - The name of the bucket retrieved.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns A single change bucket.
 */
export const getBucket = async (storageEnvironment: StorageEnvironment, name: string): Promise<ChangeBucket> => {
  const url = `${webApiConfig.endpointUrl}/ChangeTracking/${name}`;

  const requestConfig = { params: { environment: storageEnvironment }, ...(await getRequestHeaders()) };

  const response = await apiCall<ChangeBucket>(url, ApiMethod.GET, requestConfig);

  return response;
};

/**
 * Update a change bucket in the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param change - The change that will be reflected into it's parent bucket
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 */
export const CreateChangeInBucket = async (
  storageEnvironment: StorageEnvironment,
  change: TrackedChange,
): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/ChangeTracking`;

  const requestConfig = { params: { environment: storageEnvironment }, ...(await getRequestHeaders()) };

  await apiCall(url, ApiMethod.POST, requestConfig, change);
};

/**
 * Update a change bucket in the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param change - The change that will be reflected into it's parent bucket
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 */
export const UpdateBucketWithChange = async (
  storageEnvironment: StorageEnvironment,
  change: TrackedChange,
): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/ChangeTracking`;

  const requestConfig = { params: { environment: storageEnvironment }, ...(await getRequestHeaders()) };

  await apiCall(url, ApiMethod.PUT, requestConfig, change);
};

/**
 * Update a change bucket in the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param changes -The changes that will be reflected into it's parent bucket
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 */
export const UpdateBucketWithChanges = async (
  storageEnvironment: StorageEnvironment,
  changes: Array<TrackedChange>,
): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/ChangeTracking/List`;

  const requestConfig = { params: { environment: storageEnvironment }, ...(await getRequestHeaders()) };

  await apiCall(url, ApiMethod.PUT, requestConfig, changes);
};

/**
 * Get the change buckets from the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param change - The change that will be reflected into it's parent bucket
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 */
export const DeleteChangeFromBucket = async (
  storageEnvironment: StorageEnvironment,
  change: TrackedChange,
): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/ChangeTracking`;

  const requestConfig = { params: { environment: storageEnvironment }, ...(await getRequestHeaders()) };

  await apiCall(url, ApiMethod.DELETE, requestConfig, change);
};

/**
 * Trigger a request to start saving changes to staging.
 * @param buckets - The change buckets to push to staging
 * @returns An array with the containers from the storage that can be put directly into the context.
 */
export const StageChanges = async (buckets: Array<ChangeBucket>): Promise<Array<ChangeBucket>> => {
  const url = `${webApiConfig.endpointUrl}/ChangeManager/Stage`;

  const requestConfig = await getRequestHeaders();

  const response = await apiCall<Array<ChangeBucket>>(url, ApiMethod.POST, requestConfig, buckets);

  return response;
};

/**
 * Trigger a request to start saving changes to production.
 * @returns An array with the containers from the storage that can be put directly into the context.
 */
export const LiveChanges = async (): Promise<Array<ChangeBucket>> => {
  const url = `${webApiConfig.endpointUrl}/ChangeManager/Live`;

  const requestConfig = await getRequestHeaders();

  const response = await apiCall<Array<ChangeBucket>>(url, ApiMethod.POST, requestConfig);

  return response;
};
