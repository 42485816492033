import React, { FC, useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Divider, IconButton, ListItem, ListItemText, Stack, TextField } from "@mui/material";

import { useNLUContext } from "../../../../contexts/NLU/NluContext";

import { NluUtterance } from "../../../../models/Nlu/NluUtterence";

interface UtteranceListItemProps {
  utterance: NluUtterance;
  index: number;
}

/**
 * An utterance list item.
 * @param UtteranceListItemProps - The props of the utterance.
 * @param UtteranceListItemProps.utterance - The utterance to display.
 * @param UtteranceListItemProps.index - The index of the utterance.
 * @returns A list item for an utterance.
 */
const UtteranceListItem: FC<UtteranceListItemProps> = ({ utterance, index }: UtteranceListItemProps) => {
  // Context
  const CLUContext = useNLUContext();

  useEffect(() => {
    setEditing(false);
  }, [CLUContext?.selectedIntent]);

  // State
  const [update, setUpdate] = useState<string>(utterance.text);
  const [editing, setEditing] = useState<boolean>(false);

  /**
   * Handle the editing state
   */
  const handleEditing = (): void => {
    setUpdate(utterance.text);
    setEditing(!editing);
  };

  /**
   * Handle the user's action of editing the value.
   * @param event - The event that triggered the function.
   */
  const handleEditAction = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUpdate(event.target.value);
  };

  /**
   * Handle the result of the user saving the edit.
   */
  const handleEditResult = (): void => {
    if (CLUContext === null) return;

    void CLUContext.createOrUpdateUtterance!(update, utterance.intent, utterance.text);

    setEditing(!editing);
  };

  /**
   * Handle the deleting of an utterance.
   */
  const handleDelete = (): void => {
    if (CLUContext === null) return;

    void CLUContext.removeUtterance!(utterance.text);
  };

  return (
    <ListItem
      key={`utterance-${index}`}
      secondaryAction={
        editing ? (
          <Stack direction={"row"} spacing={0.5} justifyContent={"center"}>
            <Button size={"small"} variant={"contained"} onClick={handleEditResult}>
              Opslaan
            </Button>
            <Button size={"small"} variant={"contained"} onClick={handleEditing}>
              Annuleer
            </Button>
          </Stack>
        ) : (
          <Stack
            direction={"row"}
            divider={<Divider orientation={"vertical"} variant={"middle"} flexItem />}
            spacing={0.5}
            justifyContent={"center"}
          >
            <IconButton size="small" onClick={handleEditing}>
              <EditIcon color="primary" />
            </IconButton>
            <IconButton size="small" onClick={handleDelete}>
              <DeleteIcon color="error" />
            </IconButton>
          </Stack>
        )
      }
    >
      {editing ? (
        <TextField value={update} onChange={handleEditAction} />
      ) : (
        <ListItemText onDoubleClickCapture={handleEditing}>{utterance.text}</ListItemText>
      )}
    </ListItem>
  );
};

export default UtteranceListItem;
