import { DocumentConfig } from "../../models/KnowledgeSources/DocumentConfig";
import { KnowledgeSourceBase } from "../../models/KnowledgeSources/KnowledgeSourcesBase";

import { KnowledgeSourceType } from "../../enums/KnowledgeSourceType";
import { SynchronizeStatus } from "../../enums/SynchronizeStatus";

import resource from "../../resources/resources.json";

import { UrlConfig } from "./UrlConfig";

export type KnowledgeSource =
  | (KnowledgeSourceBase & { type: ""; config: Record<string, never> })
  | (KnowledgeSourceBase & {
      type:
        | KnowledgeSourceType.AtlassianWiki
        | KnowledgeSourceType.AzureDevOpsWiki
        | KnowledgeSourceType.GoogleDrive
        | KnowledgeSourceType.SharePoint
        | KnowledgeSourceType.UrlScraper;
      config: UrlConfig;
    })
  | (KnowledgeSourceBase & { type: KnowledgeSourceType.DocumentUpload; config: DocumentConfig });

// Default knowledge source
export const knowledgeSource: KnowledgeSource = {
  key: "",
  clientId: null,
  name: "",
  description: "",
  type: "",
  config: {},
  status: SynchronizeStatus.New,
  lastSyncSuccess: resource.general.knowledgeSourceNotSynced,
  lastSyncRequest: resource.general.knowledgeSourceNotSynced,
  lastModified: "",
};

// Knowledge source type display value
export const knowledgeSourceTypeMessage = [
  { key: KnowledgeSourceType.AtlassianWiki, value: "Atlassian Wiki" },
  { key: KnowledgeSourceType.AzureDevOpsWiki, value: "Azure DevOps Wiki" },
  { key: KnowledgeSourceType.DocumentUpload, value: "Document Upload" },
  { key: KnowledgeSourceType.GoogleDrive, value: "Google Drive" },
  { key: KnowledgeSourceType.SharePoint, value: "SharePoint" },
  { key: KnowledgeSourceType.UrlScraper, value: "Website Scraper" },
];

// File types allowed to be uploaded
export const allowedFileExtensions = ["txt", "pdf", "docx"];
