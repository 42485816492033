import { TabPaths } from "../../enums/TabPaths";

export interface DrawerItem {
  key: string;
  tabName: string;
  urlPath: string;
  tabOrder: number;
}

/** All the drawer items that belong to this instance with tabOrder as default order */
export const editorItems: Array<DrawerItem> = [
  {
    key: "questions",
    tabName: "Vragen",
    urlPath: TabPaths.Questions,
    tabOrder: 0,
  },
  {
    key: "answers",
    tabName: "Berichten",
    urlPath: TabPaths.Messages,
    tabOrder: 1,
  },
  {
    key: "dialogs",
    tabName: "Dialogen",
    urlPath: TabPaths.Dialogs,
    tabOrder: 2,
  },
  {
    key: "highlights",
    tabName: "Highlights",
    urlPath: TabPaths.Highlights,
    tabOrder: 3,
  },
  {
    key: "groupincidents",
    tabName: "Groepsincidenten",
    urlPath: TabPaths.GroupsIncidents,
    tabOrder: 4,
  },
  {
    key: "faq",
    tabName: "Faq",
    urlPath: TabPaths.Faq,
    tabOrder: 5,
  },
  {
    key: "knowledgesources",
    tabName: "Kennisbronnen",
    urlPath: TabPaths.KnowledgeSources,
    tabOrder: 6,
  },
];

export const configItems: Array<DrawerItem> = [
  {
    key: "clientconfig",
    tabName: "Klantconfiguratie",
    urlPath: TabPaths.ClientConfig,
    tabOrder: 0,
  },
];
