import React, { FC } from "react";

import ReactCountryFlag from "react-country-flag";

/**
 * Returns a flag icon for the United kingdom
 * @returns A flag icon for the United Kingdom
 */
export const GBFlagIcon: FC = () => <FlagIcon countryCode="GB" />;

/**
 * Returns a flag icon for the Netherlands
 * @returns A flag icon for the Netherlands
 */
export const NLFlagIcon: FC = () => <FlagIcon countryCode="NL" />;

/**
 * Returns a flag icon for France
 * @returns A flag icon for France
 */
export const FRFlagIcon: FC = () => <FlagIcon countryCode="FR" />;

/**
 * Returns a flag icon for Germany
 * @returns A flag icon for Germany
 */
export const DEFlagIcon: FC = () => <FlagIcon countryCode="DE" />;

/**
 * Returns a flag icon for a specific language key
 * @param languageKey - The language key to get the flag icon for.
 * @param languageKey.countryCode - The country code for the flag icon.
 * @returns A flag icon for the given language key
 */
const FlagIcon: FC<{ countryCode: string }> = ({ countryCode }) => (
  <ReactCountryFlag
    data-testid={`${countryCode.toLowerCase()}-flag`}
    countryCode={countryCode}
    svg
    style={{
      width: "2em",
      height: "2em",
    }}
  />
);
