import React, { FC } from "react";

import { ChevronLeft, Menu } from "@mui/icons-material";
import { IconButton, Stack, Toolbar, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";

import SignInOutButton from "../../pages/home/SignInOutButton";
import ClientSelection from "../ClientSelection/ClientSelection";

interface HeaderProps {
  authenticated: boolean;
  isDrawerOpen?: boolean;
  handleDrawerOpen?: () => void;
}

/**
 * Header Component
 * @param HeaderProps - the compoonent props
 * @param HeaderProps.authenticated - the authenticated status
 * @param HeaderProps.isDrawerOpen - the drawer open status
 * @param HeaderProps.handleDrawerOpen - the drawer open handler
 * @returns The Header component
 */
const Header: FC<HeaderProps> = ({ authenticated, isDrawerOpen, handleDrawerOpen }) => {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      color="default"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.grey[900],
        color: theme.palette.common.white,
      }}
    >
      <Toolbar>
        {authenticated && (
          <IconButton edge="start" sx={{ mr: 2 }} onClick={handleDrawerOpen} aria-label="open drawer">
            {isDrawerOpen !== false ? <ChevronLeft sx={{ color: "white" }} /> : <Menu sx={{ color: "white" }} />}
          </IconButton>
        )}
        <Grid container justifyContent="space-between" alignItems="center" width={"100%"}>
          <Grid>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Admin Panel
            </Typography>
          </Grid>
          <Grid>
            <Stack direction="row" alignItems="center" spacing={2}>
              {authenticated && <ClientSelection />}
              <SignInOutButton authenticated={authenticated} />
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
