import React, { ChangeEvent, FC, ReactElement, useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PageviewIcon from "@mui/icons-material/Pageview";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { GridActionsCellItem } from "@mui/x-data-grid";

import { deepCopy } from "../../helpers/DeepCopy";

import { documentConfig } from "../../models/KnowledgeSources/DocumentConfig";
import { KnowledgeSource, knowledgeSourceTypeMessage } from "../../models/KnowledgeSources/KnowledgeSource";
import { UrlConfig, urlConfig } from "../../models/KnowledgeSources/UrlConfig";

import { KnowledgeSourceType } from "../../enums/KnowledgeSourceType";

import resources from "../../resources/resources.json";

import CustomToolTip from "../CustomToolTip";
import FileUpload from "../FileUpload/FileUpload";
import styles from "./KnowledgeSourceEditDialog.module.scss";
import { dateTimeRepresentation, isUrlDuplicate, isUrlInvalid, isUrlType } from "./KnowledgeSourcesHelper";

interface KnowledgeSourceEditDialogProps {
  source: KnowledgeSource;
  allKnowledgeSources: Array<KnowledgeSource>;
  handleEditSource: (newSource: KnowledgeSource) => void;
  uploadFiles: (files: Array<File>, sourceName: string) => void;
  deleteFile: (filename: string, sourceName: string) => void;
}

/**
 * The functional component that handles editing a knowledge source.
 * @param KnowledgeSourceEditDialogProps - The props for the component
 * @param KnowledgeSourceEditDialogProps.source - The source to edit
 * @param KnowledgeSourceEditDialogProps.allKnowledgeSources - All knowledge sources
 * @param KnowledgeSourceEditDialogProps.handleEditSource - Function to handle editing a source
 * @param KnowledgeSourceEditDialogProps.uploadFiles - Function to upload files
 * @param KnowledgeSourceEditDialogProps.deleteFile - Function to delete a file
 * @returns - The KnowledgeSourceEditDialog component
 */
const KnowledgeSourceEditDialog: FC<KnowledgeSourceEditDialogProps> = ({
  source,
  allKnowledgeSources,
  handleEditSource,
  uploadFiles,
  deleteFile,
}) => {
  // Dialog configuration
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");

  // The knowledge source we are editing
  const [sourceToEdit, setSourceToEdit] = useState<KnowledgeSource>(deepCopy(source));

  // Dialog states
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  // State to track if source has been edited
  const [changed, setChanged] = useState<boolean>(false);

  // States for showing validation errors
  const [sourceUrlError, setSourceUrlError] = useState<string>("");

  // The new File objects to upload
  const [files, setFiles] = useState<Array<File>>([]);

  /** Check if source is actually changed */
  useEffect(() => {
    if (JSON.stringify(sourceToEdit) !== JSON.stringify(source)) {
      setChanged(true);
    } else if (files.length > 0) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [sourceToEdit, files]);

  /** Check if url is not duplicate or invalid */
  useEffect(() => {
    if (sourceToEdit.type !== "" && isUrlType(sourceToEdit.type)) {
      if (isUrlDuplicate(sourceToEdit, allKnowledgeSources)) {
        setSourceUrlError("Url bestaat al");
      } else if (isUrlInvalid(sourceToEdit)) {
        setSourceUrlError("Url is ongeldig");
      } else {
        setSourceUrlError("");
      }
    }
  }, [sourceToEdit]);

  /**
   * Handles updating filenames of a source and deleting linked files in storage
   * @param filenames - Array of filenames
   */
  const updateFilenames = (filenames: Array<string>): void => {
    if (sourceToEdit.type === KnowledgeSourceType.DocumentUpload) {
      const updatedSource = deepCopy(sourceToEdit);
      updatedSource.config.filenames = filenames;
      setSourceToEdit(updatedSource);
    }
  };

  /** Deletes files from storage based on updated filenames */
  const deleteFiles = (): void => {
    if (
      sourceToEdit.type === KnowledgeSourceType.DocumentUpload &&
      source.type === KnowledgeSourceType.DocumentUpload
    ) {
      for (const filename of source.config.filenames) {
        if (!sourceToEdit.config.filenames.includes(filename)) {
          deleteFile(sourceToEdit.config.folderId, filename);
        }
      }
    }
  };

  /**
   * Handles setting several input values
   * @param event - user input from HTML element
   * @param property - property to change
   */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, property: string): void => {
    const updatedSource = { ...sourceToEdit };

    event.preventDefault();
    switch (property) {
      case "name":
        updatedSource.name = event.target.value;
        break;
      case "description":
        updatedSource.description = event.target.value;
        break;
      case "type":
        updatedSource.type = KnowledgeSourceType[event.target.value as keyof typeof KnowledgeSourceType];

        // If config type changes, set defaults
        if (updatedSource.type === KnowledgeSourceType.DocumentUpload) {
          updatedSource.config = deepCopy(documentConfig);
        } else {
          updatedSource.config = deepCopy(urlConfig);
        }
        break;
      case "url":
        (updatedSource.config as UrlConfig).url = event.target.value;
        break;
      case "recursive": {
        const checkEvent = event as ChangeEvent<HTMLInputElement>;
        (updatedSource.config as UrlConfig).recursive = checkEvent.target.checked;
        break;
      }
    }

    setSourceToEdit(updatedSource);
  };

  // The variable that handles live updating form validation
  const validSubmission: boolean = [
    sourceToEdit.type !== "",
    sourceToEdit.type === KnowledgeSourceType.DocumentUpload
      ? sourceToEdit.config.filenames.length !== 0 || files.length !== 0
      : sourceToEdit.config.url !== "",
    sourceUrlError === "",
    changed,
  ].every(Boolean);

  /** Handle submitting a new knowledge source */
  const handleSubmit = (): void => {
    deleteFiles();

    if (sourceToEdit.type === KnowledgeSourceType.DocumentUpload) {
      const newFilenames = [...sourceToEdit.config.filenames, ...files.map((file) => file.name)];
      sourceToEdit.config.filenames = newFilenames;
      if (files.length > 0) {
        uploadFiles(files, sourceToEdit.config.folderId);
      }
    }

    handleEditSource(sourceToEdit);
    handleClose();
  };

  /** Handles closing the dialog. */
  const handleClose = (): void => {
    setOpen(false);
    setFiles([]);
    setChanged(false);
    setSourceUrlError("");

    // timeout because otherwise the revert of Edit and sourceToEdit are viewable for a short time, due to the fade out
    setTimeout(() => {
      setEdit(false);
    }, 100);
  };

  /**
   * Returns grid component based on source type
   * @returns - ReactElement
   */
  const typeComponent = (): ReactElement => {
    if (sourceToEdit.type === "") {
      return <Grid></Grid>;
    }

    if (sourceToEdit.type === KnowledgeSourceType.DocumentUpload) {
      return (
        <FileUpload setFiles={setFiles} filenames={sourceToEdit.config.filenames} updateFilenames={updateFilenames} />
      );
    } else {
      return (
        <>
          <Grid size={{ xs: 12 }}>
            <TextField
              id="input-url"
              size="small"
              label="URL"
              fullWidth
              helperText={sourceUrlError}
              error={sourceUrlError !== ""}
              value={sourceToEdit.config.url}
              onChange={(event) => {
                handleInputChange(event, "url");
              }}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sourceToEdit.config.recursive}
                  onChange={(event) => {
                    handleInputChange(event, "recursive");
                  }}
                  name="recursive"
                  color="primary"
                />
              }
              label="Recursief"
            />
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <GridActionsCellItem
        icon={<PageviewIcon color="primary" />}
        label="Bewerken"
        onClick={() => {
          setScroll("paper");
          setOpen(true);
          setSourceToEdit(deepCopy(source));
        }}
      />
      <Dialog open={open} onClose={handleClose} scroll={scroll}>
        <DialogTitle>
          Kennisbron: <span style={{ fontStyle: "italic" }}>{sourceToEdit.name}</span>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Button
            style={{
              right: "5px",
              top: "5px",
              position: "absolute",
              color: "black",
              fontSize: "17px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            X
          </Button>
          {edit ? (
            <Grid container gap={2} paddingTop={2}>
              <Grid size={{ xs: 12 }}>
                <TextField
                  id="input-description"
                  size="small"
                  label="Beschrijving"
                  fullWidth
                  value={sourceToEdit.description}
                  onChange={(event) => {
                    handleInputChange(event, "description");
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <TextField
                  id="input-type"
                  size="small"
                  select
                  label="Kennisbron type"
                  fullWidth
                  value={sourceToEdit.type}
                  onChange={(event) => {
                    handleInputChange(event, "type");
                  }}
                >
                  {knowledgeSourceTypeMessage.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {typeComponent()}
            </Grid>
          ) : (
            <Table className={styles.DetailsTable}>
              <TableBody>
                <TableRow>
                  <TableCell>Laatst gewijzigd:</TableCell>
                  <TableCell>{dateTimeRepresentation(sourceToEdit.lastModified)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Laatste synchronisatie request:</TableCell>
                  <TableCell>
                    {sourceToEdit.lastSyncRequest === resources.general.knowledgeSourceNotSynced
                      ? resources.general.knowledgeSourceNotSynced
                      : dateTimeRepresentation(sourceToEdit.lastSyncRequest)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Laatste succesvolle synchronisatie:</TableCell>
                  <TableCell>
                    {sourceToEdit.lastSyncSuccess === resources.general.knowledgeSourceNotSynced
                      ? resources.general.knowledgeSourceNotSynced
                      : dateTimeRepresentation(sourceToEdit.lastSyncSuccess)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Beschrijving:</TableCell>
                  <TableCell>{sourceToEdit.description}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Type:</TableCell>
                  <TableCell>
                    {
                      knowledgeSourceTypeMessage.find((type) => type.key === (sourceToEdit.type as KnowledgeSourceType))
                        ?.value
                    }
                  </TableCell>
                </TableRow>
                {sourceToEdit.type === KnowledgeSourceType.DocumentUpload ? (
                  <TableRow>
                    <TableCell>Bestandsnamen:</TableCell>
                    <TableCell>{sourceToEdit.config.filenames.join(", ")}</TableCell>
                  </TableRow>
                ) : (
                  <>
                    <TableRow>
                      <TableCell>URL:</TableCell>
                      <TableCell style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        <CustomToolTip
                          placement="top"
                          title={(sourceToEdit.config as UrlConfig).url}
                          child={
                            <>
                              <GridActionsCellItem
                                icon={<OpenInNewIcon color="primary" />}
                                label="open"
                                onClick={() => {
                                  window.open(sourceToEdit.config.url);
                                }}
                              />
                              <div className={styles.NoBlueLink}>
                                <a href={sourceToEdit.config.url} target="_blank" rel="noreferrer">
                                  {sourceToEdit.config.url}
                                </a>
                              </div>
                            </>
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Recursief</TableCell>
                      <TableCell>
                        {sourceToEdit.config.recursive ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        {edit ? (
          <DialogActions>
            <Button color="primary" disabled={!validSubmission} onClick={handleSubmit}>
              Opslaan
            </Button>
            <Button
              color="error"
              onClick={() => {
                setEdit(false);
                setChanged(false);
                setFiles([]);
                setSourceToEdit(deepCopy(source));
              }}
            >
              Annuleren
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setEdit(true);
              }}
            >
              Wijzigen
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default KnowledgeSourceEditDialog;
