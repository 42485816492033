import React, { FC, useEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack, Tab, TextField, Typography } from "@mui/material";

import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import CreateNewDialog from "../../../components/Dialogs/NewEntryDialogs/DialogCreator/CreateNewDialog";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";

import { useLocalStorageContext } from "../../../contexts/ChangeTracking/LocalStorageContext";
import { updateChangeTracking } from "../../../contexts/ChangeTracking/UpdateChangeTracking";
import { useNLUContext } from "../../../contexts/NLU/NluContext";

import { DialogModel } from "../../../models/Dialogs/Dialog";
import { defaultDialogVersion } from "../../../models/Dialogs/DialogVersion";

import { ChangeType } from "../../../enums/ChangeType";
import { ContainerNames } from "../../../enums/ContainerNames";

import { StorageEnvironment, getContainer } from "../../../API/StorageInteraction";
import { TopDeskApiData, loadTopDeskData } from "../../../API/TopDeskInteraction";
import VisualisationDialog from "./VisualisationDialog";

interface DialogEditorProps {
  containerName: string;
}

/**
 * DialogEditor Component
 * @param DialogEditorProps - The properties of th DialeogEditor component.
 * @param DialogEditorProps.containerName - The name of the container to get the dialogs from.
 * @returns A React component representing the DialogEditor to display and edit the dialogs.
 */
const DialogEditor: FC<DialogEditorProps> = ({ containerName }: DialogEditorProps) => {
  // TODO 4906: Add support for client ID selections swap uncomment the following two lines and use the client ID in the useEffect used to collect data
  // const ClientContext = useClientContext();
  // const [clientId, setClientId] = useState<string | null>(ClientContext!.clientId!);

  // Context
  const localStorageContext = useLocalStorageContext();
  if (localStorageContext === null) throw new Error("Context can not be null");
  const CLUContext = useNLUContext();

  // State
  const [dialogData, setDialogData] = useState<Array<DialogModel>>([]);
  const [currentDialogKey, setCurrentDialogKey] = useState<string>("");
  const [confirmProps, setConfirmProps] = useState<ConfirmationDialogProps | null>(null);
  const [topDeskApiData, setTopDeskApiData] = useState<TopDeskApiData>();
  const [isTopDeskDataLoaded, setIsTopDeskDataLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void getContainer(StorageEnvironment.Staging, containerName).then((data) => {
      setDialogData(data as Array<DialogModel>);
    });

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (dialogData === undefined) return;

    // Select the first dialog in the list while there is no currently selected dialog,
    // set the current dialog in the list.
    if (dialogData.length > 0 && currentDialogKey === "") {
      setCurrentDialogKey(dialogData[0].key);
    }
  }, [dialogData]);

  /**
   * Handles the update of the dialog.
   * @param updatedDialog - The updated dialog.
   */
  const onUpdate = (updatedDialog: DialogModel): void => {
    const oldDialog = dialogData.find((item) => item.key === updatedDialog.key);
    if (oldDialog === undefined) throw new Error("Matching dialog to update was never found");

    setDialogData(dialogData.map((item) => (item.key === updatedDialog.key ? updatedDialog : item)));

    updateChangeTracking(oldDialog, updatedDialog, ChangeType.Update, ContainerNames.Dialogs, localStorageContext);
  };

  /**
   * Creates a new dialog.
   * @param dialog - The dialog to create.
   */
  const createNewDialog = async (dialog: DialogModel): Promise<void> => {
    if (dialogData === undefined) return;

    // Create a new dialog.
    const newDialog: DialogModel = {
      key: uuidv4(),
      name: dialog.name,
      intent: dialog.intent,
      versions: [defaultDialogVersion],
    };

    // Update data first
    setDialogData([...dialogData, newDialog]);

    // Now you can safely set currentDialog
    setCurrentDialogKey(newDialog.key);

    updateChangeTracking(null, newDialog, ChangeType.New, ContainerNames.Dialogs, localStorageContext);
  };

  /**
   * Opens the delete dialog window.
   * @param dialog - The dialog to delete.
   */
  const openDeleteDialogWindow = (dialog: DialogModel): void => {
    const confirmProps: ConfirmationDialogProps = {
      open: true,
      handleClose: handleConfirmClose,
      title: `'${dialog.name}' verwijderen`,
      button1Text: "Verwijderen",
      button2Text: "Annuleren",
      description: `Weet je zeker dat je "${dialog.name}" wilt verwijderen?`,

      /**
       * Deletes the dialog.
       */
      executable: () => {
        deleteDialog(dialog);
        handleConfirmClose();
      },
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Closes the delete dialog window.
   */
  const handleConfirmClose = (): void => {
    const confirmProps: ConfirmationDialogProps = {
      open: false,
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Deletes the dialog.
   * @param dialog - The dialog to delete.
   */
  const deleteDialog = (dialog: DialogModel): void => {
    // Find the dialog to delete
    const updatedDialogData = dialogData.filter((item) => item.key !== dialog.key);

    // Remove the used intents
    const currentDialog = dialogData.find((item) => item.key === dialog.key);
    const currIntent = CLUContext?.selectedProject?.assets.intents.find(
      (intent) => intent.name === currentDialog?.intent,
    );

    if (currIntent !== undefined) {
      CLUContext?.updateUsedIntents!(currIntent, true);
    }

    setDialogData(updatedDialogData);
    if (updatedDialogData.length > 0) setCurrentDialogKey(updatedDialogData[0].key);

    updateChangeTracking(dialog, null, ChangeType.Delete, ContainerNames.Dialogs, localStorageContext);
  };

  /**
   * Open rename dialog
   * @param rename - The dialog to rename
   */
  const openRenameDialogWindow = (rename: DialogModel): void => {
    const dialog = dialogData.find((dialog: DialogModel) => dialog.key === rename.key);

    if (dialog === undefined) {
      throw new Error("Could not find dialog for rename");
    }

    const confirmProps: ConfirmationDialogProps = {
      open: true,
      handleClose: handleConfirmClose,
      title: `'${dialog.name}' wijzigen van naam`,
      button1Text: "Wijzigen",
      button2Text: "Annuleren",
      description: `Weet je zeker dat je "${dialog.name}" wilt wijzigen?`,
      bodyContent: (
        <TextField
          onChange={(e) => {
            dialog.name = e.target.value;
          }}
        />
      ),

      /**
       * Renames the dialog.
       */
      executable: () => {
        onUpdate(dialog);
      },
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Loads the TOPdesk data.
   * @returns Promise<boolean>
   */
  const loadTopDeskApiData = async (): Promise<boolean> => {
    await loadTopDeskData()
      .catch((error) => {
        throw new Error(`Error loading TOPdesk data: ${error}`);
      })
      .then((data) => {
        if (data == null) return false;

        setTopDeskApiData(data);
        setIsTopDeskDataLoaded(true);

        return true;
      });

    return false;
  };

  /**
   * This function handles the change of the tab.
   * @param event - The event.
   * @param newValue - The new value.
   */
  const handleTabChange = (event: React.SyntheticEvent, newValue: string): void => {
    setCurrentDialogKey(newValue);
  };

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (dialogData.length === 0) {
    return (
      <>
        <Stack alignItems={"center"}>
          <Typography variant="body1">Er zijn geen dialogen. Maak er nu een met de plus hieronder.</Typography>
          <CreateNewDialog executable={createNewDialog} />
        </Stack>
        {confirmProps !== null ? <ConfirmationDialog {...confirmProps} /> : null}
      </>
    );
  } else {
    return (
      <>
        {confirmProps !== null ? <ConfirmationDialog {...confirmProps} /> : null}
        <TabContext value={currentDialogKey}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} sx={{ height: "55px" }}>
              {dialogData.map((dialog: DialogModel) => (
                <Tab
                  disableRipple
                  key={`${dialog.key}_tab`}
                  value={dialog.key}
                  label={dialog.name}
                  icon={
                    <>
                      <EditIcon
                        color="primary"
                        sx={{ "&:hover": { backgroundColor: "rgba(0,0,200,0.1)" } }}
                        onClick={() => {
                          openRenameDialogWindow(dialog);
                        }}
                      />
                      <DeleteIcon
                        color="error"
                        sx={{ "&:hover": { backgroundColor: "rgba(200,0,0,0.1)" } }}
                        onClick={() => {
                          openDeleteDialogWindow(dialog);
                        }}
                      />
                    </>
                  }
                  iconPosition="end"
                />
              ))}
              <CreateNewDialog executable={createNewDialog} />
            </TabList>
          </Box>
          {dialogData.map((dialog: DialogModel) => (
            <TabPanel value={dialog.key} key={`${dialog.key}_tabpanel`}>
              <VisualisationDialog
                dialog={dialog}
                onUpdate={onUpdate}
                selectedDialog={dialog.name}
                topDeskApiData={topDeskApiData}
                isTopDeskDataLoaded={isTopDeskDataLoaded}
                loadTopDeskApiData={loadTopDeskApiData}
                containerName={containerName}
              />
            </TabPanel>
          ))}
        </TabContext>
      </>
    );
  }
};

export default DialogEditor;
