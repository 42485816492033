import React, { FC, useState } from "react";

import ExpandMore from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";

import { TrackedChange } from "../../models/ChangeTracking/TrackedChange";

import { AccordionContentFactoryStarter } from "./AccordionComponents/AccordionContentFactory";

interface SaveAccordionProps {
  change: TrackedChange;
}

/**
 * Committable change item Component
 * @param SaveAccordionProps - the props for the component
 * @param SaveAccordionProps.change - the change to display
 * @returns a component that displays the change in an accordion
 */
const SaveAccordion: FC<SaveAccordionProps> = ({ change }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  /**
   * Handle the expansion of the accordion
   */
  const handleExpansion = (): void => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      key={`panel-${change.key}`}
      slotProps={{
        transition: { unmountOnExit: true },
      }}
      sx={{ width: "100%" }}
    >
      <AccordionSummary
        data-testid="accordion-summary"
        expandIcon={<ExpandMore />}
        id={`panel-${change.key}-header`}
        onClick={handleExpansion}
      >
        {expanded ? "Veranderingen niet meer tonen" : "Veranderingen tonen"}
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: "100%" }}>{AccordionContentFactoryStarter(change)}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SaveAccordion;
