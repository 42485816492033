import React, { FC, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";

import { findDifference } from "../../../helpers/ObjectDifferences";

import { DialogStep } from "../../../models/Dialogs/DialogStep";
import { DialogVersion } from "../../../models/Dialogs/DialogVersion";

interface AccordionContentDialogProps {
  record: Record<string, any>;
  differences?: Record<string, boolean | Record<string, boolean>>;
}

/**
 * An accordion content item for highlights.
 * @param AccordionContentDialogProps - The props for this component.
 * @param AccordionContentDialogProps.record - The record that will be displayed in this component.
 * @param AccordionContentDialogProps.differences - The differences that will be displayed in this component.
 * @returns AccordionContentDialog
 */
const AccordionContentDialog: FC<AccordionContentDialogProps> = ({ record, differences }) => {
  // state
  const [stepsOpen, setStepsOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  /**
   * function to reduce code required in setting bg colors on differences.
   * @param shouldBeSet - whether it should be set or not.
   * @returns string - the color that should be set.
   */
  const setBackgroundColor = (shouldBeSet: boolean): string => (shouldBeSet ? "rgba(0, 100, 180, 0.20)" : "white");

  /**
   * Handle the opening of the languages section.
   */
  const handleStepsOpen = (): void => {
    setStepsOpen(!stepsOpen);
  };

  /**
   * Handle the changing of a tab.
   * @param event - The event that triggered the handler.
   * @param newTabIndex - the new tab index.
   */
  const onTabChange = (event: React.SyntheticEvent, newTabIndex: number): void => {
    setTabIndex(newTabIndex);
  };

  return (
    <List key={`dialog-change-preview`} disablePadding sx={{ width: "100%" }}>
      <ListItem
        data-testid={`item-key`}
        key={`dialog-change-preview-name`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("name", differences)),
        }}
      >
        <ListItemAvatar>
          <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Naam`} />
        </ListItemAvatar>
        <ListItemText>{record.name}</ListItemText>
      </ListItem>
      <ListItem
        key={`dialog-change-preview-intent`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("intent", differences)),
        }}
      >
        <ListItemAvatar>
          <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Intent`} />
        </ListItemAvatar>
        <ListItemText>{record.intent}</ListItemText>
      </ListItem>
      <ListItemButton
        data-testid={`expand-languages`}
        key={`dialog-change-preview-versions`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("versions", differences)),
        }}
        onClick={handleStepsOpen}
      >
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
          <ListItemAvatar>
            <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Talen`} />
          </ListItemAvatar>
          <Box data-testid="expand-languages-actions">{stepsOpen ? <ExpandLess /> : <ExpandMore />}</Box>
        </Stack>
      </ListItemButton>
      <Collapse in={stepsOpen} timeout={"auto"} unmountOnExit>
        <Tabs
          value={tabIndex}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ width: "100%", backgroundColor: setBackgroundColor(false) }}
        >
          {record.versions.map((version: DialogVersion) => (
            <Tab
              key={`dialog-change-preview-tab-${version.version}`}
              label={`Versie ${version.version}`}
              sx={{ backgroundColor: setBackgroundColor(findDifference(version.version, differences)) }}
            />
          ))}
        </Tabs>
        <Box sx={{ width: "100%" }}>
          <List data-testid="versions-list" sx={{ width: "inherit" }}>
            {record.versions[tabIndex].steps.map((step: DialogStep) => (
              <AccordionContentDialogStep key={`dialog-change-preview-version-step-${step.id}`} step={step} />
            ))}
          </List>
        </Box>
      </Collapse>
    </List>
  );
};

interface AccordionContentDialogStepProps {
  step: DialogStep;
}

/**
 * a component that displays information of a single step of a dialog.
 * @param AccordionContentDialogStepProps - The props for this component.
 * @param AccordionContentDialogStepProps.step - The step that will be displayed in this component.
 * @returns AccordionContentDialogStep
 */
const AccordionContentDialogStep: FC<AccordionContentDialogStepProps> = ({ step }) => {
  // State
  const [open, setOpen] = useState<boolean>(false);

  /** handle the opening of this step */
  const handleOpen = (): void => {
    setOpen(!open);
  };

  return (
    <ListItem key={`dialog-change-preview-version-step-list-item-${step.id}`} onClick={handleOpen}>
      <ListItemText>{step.name}</ListItemText>
    </ListItem>
  );
};

export default AccordionContentDialog;
