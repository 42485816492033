import React, { FC } from "react";

import { IPublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

import { CssBaseline } from "@mui/material";

import Webchat from "../components/Webchat";

import { ExternalStorageContextProvider } from "../contexts/ChangeTracking/ExternalStorageContext";
import { LocalStorageContextProvider } from "../contexts/ChangeTracking/LocalStorageContext";
import { ClientContextProvider } from "../contexts/ClientContext";
import { ErrorContextProvider } from "../contexts/ErrorContext";
import { IntentGroupContextProvider } from "../contexts/NLU/IntentGroupContext";
import { NluContextProvider } from "../contexts/NLU/NluContext";
import { SyncContextProvider } from "../contexts/SyncContext";

import Authenticator from "./home/Authenticator";
import Home from "./home/Home";

interface AppProps {
  pca: IPublicClientApplication;
}

const webchatScript = `${process.env.REACT_APP_CHAT_URL!}/webchat_builder.bundle.js`;

/**
 * Renders the application
 * @param AppProps - The props for the component
 * @param AppProps.pca - The public client application
 * @returns A React component representing the application
 */
const App: FC<AppProps> = ({ pca }) => (
  <MsalProvider instance={pca}>
    <CssBaseline />
    <AuthenticatedTemplate>
      <ClientContextProvider>
        <Webchat src={webchatScript} />
        <ErrorContextProvider>
          <NluContextProvider>
            <IntentGroupContextProvider>
              <SyncContextProvider>
                <ExternalStorageContextProvider>
                  <LocalStorageContextProvider>
                    <Home />
                  </LocalStorageContextProvider>
                </ExternalStorageContextProvider>
              </SyncContextProvider>
            </IntentGroupContextProvider>
          </NluContextProvider>
        </ErrorContextProvider>
      </ClientContextProvider>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Authenticator />
    </UnauthenticatedTemplate>
  </MsalProvider>
);

export default App;
