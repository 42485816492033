import { NluEntity } from "../models/Nlu/NluEntity";
import { NluIntent } from "../models/Nlu/NluIntent";
import { NluProject } from "../models/Nlu/NluProject";
import { NluSuccessResponse } from "../models/Nlu/NluSuccessResponse";
import { NluTrainingState } from "../models/Nlu/NluTrainingState";
import { NluUtterance } from "../models/Nlu/NluUtterence";

import { ApiMethod } from "../enums/ApiMethod";

import { webApiConfig } from "./ApiConfiguration";
import { apiCall, getRequestHeaders } from "./ApiHelpers";

/**
 * API GET to get the basic project information of all nlu projects
 * @returns The basic project information of all projects from the backend
 */
export const getProjectsAsync = async (): Promise<Array<string>> => {
  const url = `${webApiConfig.endpointUrl}/Nlu`;
  const requestConfig = await getRequestHeaders();

  return await apiCall<Array<string>>(url, ApiMethod.GET, requestConfig);
};

/**
 * Export the specified project.
 * @param projectName - The name of the project to export
 * @returns The exported project
 */
export const exportProjectAsync = async (projectName: string): Promise<NluProject> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}`;
  const requestConfig = await getRequestHeaders();

  const response = await apiCall<NluProject>(url, ApiMethod.GET, requestConfig);

  return response;
};

/**
 * Import the specified project.
 * @param projectName - The name of the project to import
 * @param project - The project to import
 * @returns The success response from the import
 */
export const importProjectAsync = async (projectName: string, project: NluProject): Promise<NluSuccessResponse> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}`;

  const requestConfig = await getRequestHeaders();

  const response = await apiCall<NluSuccessResponse>(url, ApiMethod.PUT, requestConfig, project);

  return response;
};

/**
 * Deploy the specified project.
 * @param projectName - The name of the project to deploy
 * @returns The success response from the deployment
 */
export const deployProjectAsync = async (projectName: string): Promise<NluSuccessResponse> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Deploy`;

  const requestConfig = await getRequestHeaders();

  const response = await apiCall<NluSuccessResponse>(url, ApiMethod.POST, requestConfig);

  return response;
};

/**
 * Train the specified project.
 * @param projectName - The name of the project to train
 * @returns The training state of the project
 */
export const trainProjectAsync = async (projectName: string): Promise<NluTrainingState> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Train/Start`;

  const requestConfig = await getRequestHeaders();

  const response = await apiCall<NluTrainingState>(url, ApiMethod.GET, requestConfig);

  return response;
};

/**
 * Get the training status the specified project
 * @param projectName - The name of the project to get the training status for
 * @returns The training state of the projectq
 */
export const getTrainingJobsAsync = async (projectName: string): Promise<Array<NluTrainingState>> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Train`;

  const requestConfig = await getRequestHeaders();

  const response = await apiCall<Array<NluTrainingState>>(url, ApiMethod.GET, requestConfig);

  return response;
};

/**
 * Create or update an intent in the specified project.
 * @param projectName - The name of the project to create or update the intent in
 * @param intent - The intent to create or update
 * @returns The success response from the intent creation or update
 */
export const createOrUpdateIntentAsync = async (
  projectName: string,
  intent: NluIntent,
): Promise<NluSuccessResponse> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Intents`;

  const requestConfig = await getRequestHeaders();

  const response = await apiCall<NluSuccessResponse>(url, ApiMethod.POST, requestConfig, intent);

  return response;
};

/**
 * Create or update an entity in the specified project.
 * @param projectName - The name of the project to create or update the entity in
 * @param entity - The entity to create or update
 * @returns The success response from the entity creation or update
 */
export const createOrUpdateEntityAsync = async (
  projectName: string,
  entity: NluEntity,
): Promise<NluSuccessResponse> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Entities`;

  const requestConfig = await getRequestHeaders();

  const response = await apiCall<NluSuccessResponse>(url, ApiMethod.POST, requestConfig, entity);

  return response;
};

/**
 * Create or update an utterance in the specified project.
 * @param projectName - The name of the project to create or update the utterance in
 * @param utterance - The utterance to create or update
 * @param previousUtterance - The previous utterance to replace
 * @returns The success response from the utterance creation or update
 */
export const createOrUpdateUtteranceAsync = async (
  projectName: string,
  utterance: NluUtterance,
  previousUtterance?: string,
): Promise<NluSuccessResponse> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Utterances`;

  const requestConfig = { params: { previousUtterance }, ...(await getRequestHeaders()) };

  const response = await apiCall<NluSuccessResponse>(url, ApiMethod.POST, requestConfig, utterance);

  return response;
};

/**
 * Remove an intent from the specified project.
 * @param projectName - The name of the project to remove the intent from
 * @param name - The name of the intent to remove
 * @returns The success response from the intent removal
 */
export const removeIntentAsync = async (projectName: string, name: string): Promise<NluSuccessResponse> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Intents`;

  const requestConfig = { params: { name }, ...(await getRequestHeaders()) };

  const response = await apiCall<NluSuccessResponse>(url, ApiMethod.POST, requestConfig);

  return response;
};

/**
 * Remove an entity from the specified project.
 * @param projectName - The name of the project to remove the entity from
 * @param name - The name of the entity to remove
 * @returns The success response from the entity removal
 */
export const removeEntityAsync = async (projectName: string, name: string): Promise<NluSuccessResponse> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Entities`;

  const requestConfig = { params: { name }, ...(await getRequestHeaders()) };

  const response = await apiCall<NluSuccessResponse>(url, ApiMethod.DELETE, requestConfig);

  return response;
};

/**
 * Remove an utterance from the specified project.
 * @param projectName - The name of the project to remove the utterance from
 * @param utterance - The utterance to remove
 * @returns The success response from the utterance removal
 */
export const removeUtteranceAsync = async (projectName: string, utterance: string): Promise<NluSuccessResponse> => {
  const url = `${webApiConfig.endpointUrl}/Nlu/${projectName}/Utterances`;

  const requestConfig = { params: { utterance }, ...(await getRequestHeaders()) };

  const response = await apiCall<NluSuccessResponse>(url, ApiMethod.DELETE, requestConfig);

  return response;
};
