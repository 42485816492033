import React, { FC, useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import CustomDataGrid from "../../../components/CustomDataGrid/CustomDataGrid";

import { Answer } from "../../../models/Answers";
import { GroupIncident } from "../../../models/GroupIncidents";
import { Highlight } from "../../../models/Highlights";

import { ContainerNames } from "../../../enums/ContainerNames";

import { getColumnsByContainerName } from "./ModularEditorHelper";

export type EditorContentType = Highlight | GroupIncident | Answer;
export type EditorContentArray = Array<EditorContentType>;

interface ModularEditorProps {
  containerName: ContainerNames;
}

/**
 * Generic Editor Component
 * @param ModularEditorProps - The props for the component
 * @param ModularEditorProps.containerName - The name of the container to use.
 * @returns A Generic Editor component
 */
const ModularEditor: FC<ModularEditorProps> = ({ containerName }) => {
  const [columns, setColumns] = useState<Array<GridColDef>>([]);

  useEffect(() => {
    const newColumns = getColumnsByContainerName(containerName);
    setColumns(newColumns);
  }, [containerName]);

  return (
    <>
      {columns.length > 0 ? (
        <CustomDataGrid columns={columns} containerName={containerName} />
      ) : (
        <Box sx={{ display: "flex" }} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ModularEditor;
