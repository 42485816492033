import React, { FC, useEffect, useState } from "react";

import { MenuItem, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { NodeSelection } from "../../../../components/Editors/DialogEditor/VisualisationDialog";

import { DialogModel } from "../../../../models/Dialogs/Dialog";
import { DialogStep } from "../../../../models/Dialogs/DialogStep";

import { StorageEnvironment, getContainer } from "../../../../API/StorageInteraction";

interface ShowRedirectOptionsProps {
  selectedNodeData: { newNodeData: DialogStep; oldNodeData: DialogStep | null };
  setSelectednodeData: (arg0: NodeSelection) => void;
  containerName: string;
  selectedDialog: string;
}

/**
 * ShowRedirectOptions Component
 * @param ShowRedirectOptionsProps - The properties of the ShowRedirectOptions component.
 * @param ShowRedirectOptionsProps.selectedNodeData - The selected node data.
 * @param ShowRedirectOptionsProps.setSelectednodeData - The function to set the selected node data.
 * @param ShowRedirectOptionsProps.containerName - The name of the container.
 * @param ShowRedirectOptionsProps.selectedDialog - The selected dialog.
 * @returns A ShowRedirectOptions component.
 */
const ShowRedirectOptions: FC<ShowRedirectOptionsProps> = ({
  selectedNodeData,
  setSelectednodeData,
  containerName,
  selectedDialog,
}): React.ReactElement => {
  const [dialogs, setDialogs] = useState<Array<DialogModel>>([]);

  /** Get data on initial render */
  useEffect(() => {
    getDialogs();
  }, []);

  /** Get the dialog data */
  const getDialogs = (): void => {
    void getContainer(StorageEnvironment.Staging, containerName).then((data) => {
      setDialogs(data as Array<DialogModel>);
    });
  };

  /**
   * Handles the change of the response of the node.
   * @param event - The event that triggered the change.
   */
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.redirect = event.target.value;

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData!,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectednodeData(newNodeSelection);
  };

  return (
    <Grid key="options-container " container spacing={1}>
      <Grid key={`redirect`} size={{ xs: 12 }}>
        <TextField
          select
          label={"dialoog"}
          value={selectedNodeData.newNodeData.options.redirect}
          onChange={onChange}
          slotProps={{ htmlInput: { name: "redirect" } }}
          fullWidth={true}
        >
          {dialogs.map((dialog) => {
            if (dialog.name !== selectedDialog) {
              return (
                <MenuItem key={dialog.name} value={dialog.name}>
                  {dialog.name}
                </MenuItem>
              );
            } else {
              return null;
            }
          })}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default ShowRedirectOptions;
