import React, { FC, ReactElement, memo, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import SaveIcon from "@mui/icons-material/Save";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
  gridClasses,
} from "@mui/x-data-grid";

import CustomToolTip from "../../components/CustomToolTip";
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { EditorContentArray, EditorContentType } from "../../components/Editors/ModularEditor/ModularEditor";

import { useExternalStorageContext } from "../../contexts/ChangeTracking/ExternalStorageContext";
import { useLocalStorageContext } from "../../contexts/ChangeTracking/LocalStorageContext";
import { updateChangeTracking } from "../../contexts/ChangeTracking/UpdateChangeTracking";

import { Language, LanguageText, languages } from "../../models/Language";

import { ChangeType } from "../../enums/ChangeType";
import { ContainerNames } from "../../enums/ContainerNames";

import { StorageEnvironment, getContainer } from "../../API/StorageInteraction";
import "../../styles/PageLayout.css";
import AddOrEditLanguageDialog, {
  AddOrEditLanguageDialogProps,
  defaultAddOrEditLanguageDialogProps,
} from "../Dialogs/AddOrEditLanguageDialog";
import { filterLanguages, findLanguageValueInDataSet } from "../Editors/DialogEditor/VisualisationDialogHelper";
import { addOrUpdateLanguage, getChanges, getRowClassName, removeItemLanuage, updateRow } from "./CustomDataGridHelper";
import DataGridDialog from "./DataGridComponents/DataGridDialog";
import Flag from "./DataGridComponents/Flag";

const defaultLanguage: string = process.env.REACT_APP_GLOBAL_LANGUAGE!;

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    executable: (update: any) => void;
  }
}

interface CustomDataGridProps {
  columns: Array<GridColDef>;
  containerName: ContainerNames;
}

/**
 * Custom data grid component that displays a data grid with custom columns and actions.
 * @param DataGridProps - The props for the custom data grid component.
 * @param DataGridProps.columns - The columns to be displayed in the data grid.
 * @param DataGridProps.containerName - The name of the container for the data grid.
 * @returns A JSX element representing the custom data grid component.
 */
const CustomDataGrid: FC<CustomDataGridProps> = ({ columns, containerName }: CustomDataGridProps) => {
  const localStorageContext = useLocalStorageContext();
  const externalStorageContext = useExternalStorageContext();

  const [localChanges, setLocalChanges] = useState<Array<string>>([]);
  const [stagedChanges, setStagedChanges] = useState<Array<string>>([]);
  const [confirmProps, setConfirmProps] = useState<ConfirmationDialogProps>();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [selectedDataEntryKey, setSelectedDataEntryKey] = useState<string>("");
  const [languageDialogProps, setLanguageDialogProps] = useState<AddOrEditLanguageDialogProps>(
    defaultAddOrEditLanguageDialogProps,
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [dataEntries, setDataEntries] = useState<Array<EditorContentType>>([]);

  useEffect(() => {
    void getContainer(StorageEnvironment.Staging, containerName).then((data) => {
      setDataEntries(data as EditorContentArray);
    });
  }, [containerName]);

  useEffect(() => {
    setLocalChanges([...getChanges(localStorageContext!, containerName)]);
    setStagedChanges([...getChanges(externalStorageContext!, containerName)]);
  }, [dataEntries]);

  /**
   * Handles the row edit stop event.
   * @param params - An object containing information about the row and other parameters.
   */
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params: GridRowParams): void => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  /**
   * Handles cancel click on edit
   * @param id - The id of the row to cancel the edit for.
   * @returns A function that cancels the edit for the row with the specified id.
   */
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  /**
   * Handles the row model change event.
   * @param newRowModesModel - The new row modes model.
   */
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel): void => {
    setRowModesModel(newRowModesModel);
  };

  /**
   * Handles the row edit click event.
   * @param id - The id of the row to edit.
   * @returns A function that edits the row with the specified id.
   */
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  /**
   * Handles the save click event during row edit.
   * @param id - The id of the row to save.
   * @returns A function that saves the row with the specified id.
   */
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const commonColumns: Array<GridColDef> = [
    {
      field: "key",
      headerName: "Onderwerp",
      type: "string",
      minWidth: 200,
      hideable: false,
      editable: false,
    },
    {
      field: "value",
      headerName: "Beschrijving",
      type: "string",
      flex: 1,
      minWidth: 400,
      hideable: false,
      editable: true,
      /**
       * Gets the value of the main language from the languages array
       * @param _ - The value of the cell which we do not use here.
       * @param row - The row data
       * @returns The value of the main language
       */
      valueGetter: (_, row) => {
        if (row.languages.length > 0) {
          const languageIndex: number = row.languages.findIndex((lang: LanguageText) => lang.key === defaultLanguage);
          if (languageIndex !== -1 && row.languages[languageIndex].value !== "") {
            return row.languages[languageIndex].value;
          }
        }

        return "";
      },
    },
    {
      flex: 1,
      maxWidth: 250,
      minWidth: 100,
      field: "andereTalen",
      headerName: "Andere Talen",
      type: "actions",
      /**
       * Gets the actions for the row
       * @param params - The row parameters
       * @param params.row - The row data
       * @returns The actions for the row
       */
      getActions: ({ row }: GridRowModel) => {
        const actions: Array<ReactElement> = [];
        const data = row as EditorContentType;

        /**
         * Function to create the action for the language specified
         * @param languageKey - The key of the language
         * @param title - The title of the language
         * @returns The actions flags for language
         */
        const createAction = (languageKey: string, title: string): JSX.Element => (
          <GridActionsCellItem
            key={languageKey}
            icon={<CustomToolTip title={title} child={<Flag languageKey={languageKey}></Flag>} />}
            label={`${languageKey}-text`}
            onClick={() => {
              setSelectedDataEntryKey(data.key);
              const languageValue: string = findLanguageValueInDataSet(data, languageKey);
              handleLanguageDialogProps(`Wijzig ${title} tekst`, languages, languageKey, languageValue, true);
              setDialogOpen(true);
            }}
          />
        );

        if (
          data.languages !== null &&
          data.languages !== undefined &&
          languages.some((language) => !data.languages.some((lang: LanguageText) => lang.key === language.key))
        ) {
          actions.push(
            <GridActionsCellItem
              key={`${data.key}`}
              icon={<CustomToolTip title="Voeg een nieuwe taal toe" child={<AddIcon />} />}
              label="add-new-language"
              onClick={() => {
                setSelectedDataEntryKey(data.key);
                const filteredLanguages = filterLanguages(languages, data);
                handleLanguageDialogProps("Voeg een nieuwe taal toe", filteredLanguages);
                setDialogOpen(true);
              }}
            />,
          );
        }

        languages.forEach((language) => {
          if (
            language.key !== defaultLanguage &&
            data.languages.some((lang: LanguageText) => lang.key === language.key)
          ) {
            actions.push(createAction(language.key, language.name));
          }
        });

        return actions;
      },
    },
  ];

  const actionColumn: GridColDef = {
    field: "actions",
    headerName: "Acties",
    type: "actions",
    width: 150,
    hideable: false,

    /**
     * Returns an array of React elements representing action items for a grid cell.
     * @param params - An object containing information about the row and other parameters.
     * @param params.id - The unique id of the row.
     * @param params.row - The data of the row.
     * @returns An array of React elements representing action items for the grid cell.
     */
    getActions: ({ id, row }) => {
      const actions: Array<JSX.Element> = [];

      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        actions.push(
          <GridActionsCellItem key={`${id}_save`} icon={<SaveIcon />} label="Opslaan" onClick={handleSaveClick(id)} />,
        );
        actions.push(
          <GridActionsCellItem
            key={`${id}_cancel`}
            icon={<CloseIcon />}
            label="Sluit"
            onClick={handleCancelClick(id)}
          />,
        );

        return actions;
      }

      actions.push(
        <GridActionsCellItem key={`${id}_edit`} icon={<EditIcon />} label="Bewerken" onClick={handleEditClick(id)} />,
      );

      actions.push(
        <GridActionsCellItem
          key={`${id}_revert`}
          icon={<HistoryIcon />}
          label="Terugdraai"
          onClick={() => {
            throw new Error("Does not work");
          }}
        />,
      );

      actions.push(
        <GridActionsCellItem
          key={`${id}_delete`}
          icon={<DeleteIcon />}
          label="Verwijderen"
          onClick={openDeleteItemDialog(row.key)}
        />,
      );

      return actions;
    },
  };

  const allColumns: Array<GridColDef> = commonColumns.concat(columns);
  allColumns.push(actionColumn);

  /**
   * Adds a language to the incident
   * @param languageText - The language text to add to the incident
   * @param key - The key of the incident to add the language to
   */
  const handleAddOrUpdateLanguage = (languageText: LanguageText, key: string = selectedDataEntryKey): void => {
    const { oldItem, updatedItem } = addOrUpdateLanguage(dataEntries, key, languageText);

    onUpdate(oldItem, updatedItem);
  };

  /**
   * Removes a language from the entry
   * @param languageKey - The language key to remove from the incident
   */
  const removeLanguage = (languageKey: string): void => {
    const { oldItem, updatedItem } = removeItemLanuage(dataEntries, selectedDataEntryKey, languageKey);

    onUpdate(oldItem, updatedItem);
  };

  /**
   * Opens the language dialog for editing or adding a language
   * @param title - The title of the dialog
   * @param languages - The languages to display in the dialog
   * @param dialogLanguage - The language to edit
   * @param dialogValue - The value of the language to edit
   * @param isEdit - Whether the dialog is for editing or adding a language
   */
  const handleLanguageDialogProps = (
    title: string,
    languages: Array<Language>,
    dialogLanguage = "",
    dialogValue = "",
    isEdit = false,
  ): void => {
    setLanguageDialogProps({
      addLanguage: handleAddOrUpdateLanguage,
      removeLanguage,
      isOpen: dialogOpen,
      isEdit,
      /**
       * Close dialog
       */
      handleClose: () => {
        setDialogOpen(false);
      },
      languages,
      dialogValue,
      dialogLanguage,
      title,
    });
  };

  /**
   * Deletes an item with the given key from the data list and updates the component state and data with the new data.
   * @param key - The unique key of the item to be deleted.
   * @returns a function that deletes the item with the specified key.
   */
  const openDeleteItemDialog = (key: string) => () => {
    const confirmProps: ConfirmationDialogProps = {
      open: true,
      handleClose: handleConfirmClose,
      title: "Bevestiging Verwijderen",
      button1Text: "Akkoord",
      button2Text: "Annuleer",
      description: "Weet je zeker dat je dit wilt verwijderen?",
      /** Delete item on confirmation */
      executable: () => {
        onDelete(key);
      },
    };

    // Show the confirmation dialog by setting the props for it
    setConfirmProps(confirmProps);
  };

  /**
   * Updates the data list with a new row data and the component state and data with the updated data.
   * @param newRow - The new data for the row to be updated.
   * @param oldRow - The previous data for the row to be updated.
   * @returns The updated row data.
   */
  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel): GridValidRowModel => {
    if (newRow === oldRow) {
      return oldRow;
    }

    const updatedRow = updateRow(oldRow, newRow, defaultLanguage);

    onUpdate(oldRow as EditorContentType, updatedRow as EditorContentType);

    return updatedRow;
  };

  /**
   * Closes the confirmation dialog by setting the 'open' prop to 'false' and resetting other props.
   */
  const handleConfirmClose = (): void => {
    const confirmProps: ConfirmationDialogProps = {
      open: false,
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Editor update function that creates a dispatch action with the correct information
   * @param newItem - the new item generated by the CustomDataGrid component that will be
   */
  const onCreate = (newItem: EditorContentType): void => {
    const newData: EditorContentArray = [...dataEntries, newItem];
    setDataEntries(newData);

    updateChangeTracking(null, newItem, ChangeType.New, containerName, localStorageContext!);
  };

  /**
   * Update change bucket
   * @param oldValue - the old value of the item
   * @param newValue - the new value of the item
   */
  const onUpdate = (oldValue: EditorContentType, newValue: EditorContentType): void => {
    const newData: EditorContentArray = dataEntries.map((item) => (item.key === newValue.key ? newValue : item));

    setDataEntries(newData);

    updateChangeTracking(oldValue, newValue, ChangeType.Update, containerName, localStorageContext!);
  };

  /**
   * Editor update function that creates a dispatch action with the correct information
   * @param key - the key of the item to delete
   */
  const onDelete = (key: string): void => {
    const oldValue = dataEntries.find((item) => item.key === key);

    if (oldValue === undefined) throw new Error("Could not find item to delete");

    const newData: EditorContentArray = dataEntries.filter((item) => item.key !== key);
    setDataEntries(newData);

    updateChangeTracking(oldValue, null, ChangeType.Delete, containerName, localStorageContext!);
  };

  return (
    <Box height={"100%"}>
      {confirmProps != null ? <ConfirmationDialog {...confirmProps} /> : null}
      <Grid container height={"100%"}>
        <Grid size={{ xs: 12 }}>
          <DataGridDialog containerName={containerName} onCreate={onCreate} />
          <AddOrEditLanguageDialog
            isOpen={dialogOpen}
            handleClose={() => {
              setDialogOpen(false);
            }}
            languages={languageDialogProps.languages}
            addLanguage={(languageText: LanguageText) => {
              handleAddOrUpdateLanguage(languageText);
            }}
            removeLanguage={(languageKey: string) => {
              removeLanguage(languageKey);
            }}
            dialogLanguage={languageDialogProps.dialogLanguage}
            dialogValue={languageDialogProps.dialogValue}
            title={languageDialogProps.title}
            isEdit={languageDialogProps.isEdit}
          />
        </Grid>
        <Grid size={{ xs: 12 }} height={"94%"}>
          <DataGrid
            sx={{
              [`.${gridClasses.row}`]: { "&:hover": { backgroundColor: "rgba(0,0,255,0.2)" } },

              [`.${gridClasses.row}.local`]: {
                fontWeight: "bold",
                backgroundColor: "rgba(0,0,255,0.1)",
              },
              [`.${gridClasses.row}.stage`]: {
                backgroundColor: "rgba(0,0,255,0.1)",
              },
            }}
            columns={allColumns}
            rows={dataEntries}
            getRowClassName={(gridRowModel: GridRowModel): string => {
              const rowClassName = getRowClassName(gridRowModel, stagedChanges, localChanges);

              return rowClassName;
            }}
            rowHeight={52}
            getRowId={(row: GridRowModel) => row.key}
            editMode="row"
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              throw new Error(error);
            }}
            slotProps={{
              loadingOverlay: {
                variant: "linear-progress",
                noRowsVariant: "linear-progress",
              },
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            keepNonExistentRowsSelected
            initialState={{
              sorting: {
                sortModel: [{ field: "key", sort: "asc" }],
              },
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            localeText={{
              MuiTablePagination: {
                labelRowsPerPage: `Rijen per pagina:`,

                /**
                 * Display the pagination text as we wish.
                 * @param params - The parameters for the pagination text.
                 * @param params.from - The starting index of the displayed rows.
                 * @param params.to - The ending index of the displayed rows.
                 * @param params.count - The total number of rows.
                 * @returns The formatted pagination text.
                 */
                labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} van ${count}`,
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(CustomDataGrid);
