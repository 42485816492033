import axios, { AxiosRequestConfig } from "axios";

import { ApiMethod } from "../enums/ApiMethod";

import { webApiConfig } from "./ApiConfiguration";
import { getToken } from "./Auth/Auth";

/**
 * Perform an API call to the specified URL with the specified configuration
 * @param url - The URL to call
 * @param apiMethod - The method to use for the call
 * @param config - The configuration to use for the call
 * @param data - The data to send in the request
 * @returns The response from the API call
 */
export const apiCall = async <T>(
  url: string,
  apiMethod: ApiMethod,
  config: AxiosRequestConfig,
  data?: any,
): Promise<T> => {
  try {
    const response = await axios.request<T>({
      url,
      method: apiMethod,
      ...config,
      data,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Get the request headers for the API calls
 * @returns The request headers for the API calls
 */
export const getRequestHeaders = async (): Promise<AxiosRequestConfig> => {
  const token = await getToken();

  return {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
    },
    signal: new AbortController().signal,
  };
};
