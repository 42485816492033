import React, { ChangeEvent, FC, useEffect, useState } from "react";

import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { FaqItem, faqItem } from "../../models/Faqs";

interface CreateFaqDialogProps {
  handleFaqUpload: (newFaq: FaqItem) => void;
  allFaq: Array<FaqItem>;
  disabled: boolean;
}

/**
 * A functional component representing the 'CreateFaqDialog'.
 * @param CreateFaqDialogProps - The properties of the component.
 * @param CreateFaqDialogProps.handleFaqUpload - A function that handles the upload of a new Faq.
 * @param CreateFaqDialogProps.allFaq - An array of all the current Faqs.
 * @param CreateFaqDialogProps.disabled - A boolean that determines if the button is disabled.
 * @returns A React functional component.q
 */
const CreateFaqDialog: FC<CreateFaqDialogProps> = ({ handleFaqUpload, allFaq, disabled }) => {
  const [faq, setFaq] = useState<FaqItem>(faqItem);
  const [open, setOpen] = useState<boolean>(false);

  const [questionError, setQuestionError] = useState<string>("");

  /** Check if name is not duplicate */
  useEffect(() => {
    const duplicate: boolean = allFaq.some((someFaq) => someFaq.question === faq.question);
    if (duplicate) {
      setQuestionError("Faq vraag komt al eerder voor");
    } else if (!validateQuestion(faq.question)) {
      setQuestionError("Dit is geen geldige vraag");
    } else {
      setQuestionError("");
    }
  }, [faq.question]);

  /**
   * Handles validating Url safe question input
   * @param question - The question to validate
   * @returns A boolean indicating if the question is valid
   */
  const validateQuestion = (question: string): boolean => {
    if (question === "") {
      return true;
    }

    // Allow only letters, numbers, comma and question mark
    const regex = /^[a-zA-Z0-9\s,'?]+$/;

    return regex.test(question);
  };

  /**
   * Handles setting several input values
   * @param event - The event that triggered the function
   * @param property - The property to set
   */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, property: string): void => {
    const updatedFaq = { ...faq };
    event.preventDefault();
    switch (property) {
      case "question":
        updatedFaq.question = event.target.value;
        break;
      case "answer":
        updatedFaq.answer = event.target.value;
        break;
    }
    setFaq(updatedFaq);
  };

  // The variable that handles live updating form validation
  const validSubmission: boolean = [faq.question !== "", faq.answer !== "", questionError === ""].every(Boolean);

  /** Handles closing the dialog. */
  const handleClose = (): void => {
    setOpen(false);
    setFaq(faqItem);
  };

  /** Handle submitting a new Faq */
  const handleSubmit = (): void => {
    handleFaqUpload(faq);
    handleClose();
  };

  return (
    <div data-testid="create-faq-dialog">
      <Button
        data-testid="button-add-faq"
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
        startIcon={<LibraryBooksIcon />}
        sx={{ height: 50 }}
        disabled={disabled}
      >
        FAQ toevoegen
      </Button>
      {open ? (
        <Dialog data-testid="dialog" open={open} onClose={handleClose}>
          <DialogTitle>Nieuwe FAQ</DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText>Vul de benodigde informatie in om een nieuwe FAQ aan te maken.</DialogContentText>
            <Button
              style={{
                right: "5px",
                top: "5px",
                position: "absolute",
                color: "black",
                fontSize: "17px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              X
            </Button>
            <Grid container gap={2} paddingTop={2}>
              <Grid size={{ xs: 12 }}>
                <TextField
                  slotProps={{ htmlInput: { "data-testid": "question-textfield" } }}
                  id="input-question"
                  size="small"
                  label="Vraag"
                  fullWidth
                  helperText={questionError}
                  error={questionError !== ""}
                  value={faq.question}
                  onChange={(event) => {
                    handleInputChange(event, "question");
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <TextField
                  slotProps={{ htmlInput: { "data-testid": "answer-textfield" } }}
                  id="input-answer"
                  size="small"
                  label="Antwoord"
                  fullWidth
                  value={faq.answer}
                  onChange={(event) => {
                    handleInputChange(event, "answer");
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button data-testid="confirm-button" color="primary" disabled={!validSubmission} onClick={handleSubmit}>
              Opslaan
            </Button>
            <Button data-testid="cancel-button" color="error" onClick={handleClose}>
              Annuleren
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};

export default CreateFaqDialog;
