import React, { FC } from "react";

import { Route, Routes } from "react-router-dom";

import DialogEditor from "../../components/Editors/DialogEditor/DialogEditor";
import ModularEditor from "../../components/Editors/ModularEditor/ModularEditor";
import QuestionEditor from "../../components/Editors/Questions/QuestionEditor";

import { ContainerNames } from "../../enums/ContainerNames";
import { TabPaths } from "../../enums/TabPaths";

import ClientConfigurator from "../Configurators/ClientConfigurator";
import FaqComponent from "../Faq/FaqComponent";
import KnowledgeSources from "../KnowledgeSources/KnowledgeSources";

/**
 * Routing Component
 * @returns React component that handles navigation to the different pages and components
 */
const Routing: FC = () => {
  const NLUFeatureFlag: boolean = process.env.REACT_APP_ENABLE_NLU_QUESTION_ANSWERING === "true";
  const GroupIncidentsFeatureFlag: boolean = process.env.REACT_APP_ENABLE_GROUP_INCIDENTS === "true";

  return (
    <Routes>
      <Route path={TabPaths.Home} element={<p>Welkom bij het admin panel</p>} />
      {NLUFeatureFlag ? (
        <>
          <Route path={TabPaths.Questions} element={<QuestionEditor />} />
          <Route path={TabPaths.Dialogs} element={<DialogEditor containerName={ContainerNames.Dialogs} />} />
        </>
      ) : null}
      <Route path={TabPaths.Messages} element={<ModularEditor containerName={ContainerNames.Answers} />} />
      <Route path={TabPaths.Highlights} element={<ModularEditor containerName={ContainerNames.Highlights} />} />
      {GroupIncidentsFeatureFlag ? (
        <Route
          path={TabPaths.GroupsIncidents}
          element={<ModularEditor containerName={ContainerNames.GroupIncidents} />}
        />
      ) : null}

      <Route path={TabPaths.Faq} element={<FaqComponent />} />

      <Route path={TabPaths.KnowledgeSources} element={<KnowledgeSources />} />
      <Route path={TabPaths.ClientConfig} element={<ClientConfigurator />} />
    </Routes>
  );
};

export default Routing;

