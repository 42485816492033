import React, { FC } from "react";

import { v4 as uuidv4 } from "uuid";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { DialogStep } from "../../../../models/Dialogs/DialogStep";

import { NodeSelection } from "../VisualisationDialog";

interface ShowIfStatementsOptionsProps {
  selectedNodeData: { newNodeData: DialogStep; oldNodeData: DialogStep };
  setSelectedNodeData: (arg0: NodeSelection) => void;
}

/**
 * ShowIfStatementsOptions Component
 * @param ShowIfStatementOptionsProps - The properties of the ShowIfStatementsOptions component.
 * @param ShowIfStatementOptionsProps.selectedNodeData - The data of the selected node.
 * @param ShowIfStatementOptionsProps.setSelectedNodeData - The function to set the selected node data.
 * @returns A React component representing the ShowIfStatementsOptions to display and edit the options of a IfStatements node.
 */
const ShowIfStatementsOptions: FC<ShowIfStatementsOptionsProps> = ({ selectedNodeData, setSelectedNodeData }) => (
  <Grid key="options-container " container spacing={1}>
    <TableContainer>
      <Table sx={{ minWidth: 180 }} aria-label="caption label">
        <TableHead key="head">
          <TableRow>
            <TableCell key="Sleutel">Sleutel</TableCell>
            <TableCell key="Waarde">Waarde</TableCell>
            <TableCell key="Actie">Actie</TableCell>
          </TableRow>
        </TableHead>
        <TableBody key="body">
          {[null, undefined].includes(selectedNodeData.newNodeData.options.ifs)
            ? selectedNodeData.newNodeData.options.ifs.map((button: { id: string; key: string; value: string }) => (
                <ShowIfStatementsOptionsCell
                  node={selectedNodeData}
                  setNode={setSelectedNodeData}
                  id={button.id}
                  key={button.key}
                  value={button.value}
                />
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
);

interface ShowIfStatementsOptionsCellProps {
  node: { newNodeData: DialogStep; oldNodeData: DialogStep };
  setNode: (arg0: NodeSelection) => void;
  id: string;
  key: string;
  value: string;
}

/**
 * A cell in the showIfstatementsOptions table
 * @param ShowIfStatementsOptionsCellProps - The properties of the ShowIfStatementsOptionsCell component.
 * @param ShowIfStatementsOptionsCellProps.node - The data of the selected node.
 * @param ShowIfStatementsOptionsCellProps.setNode - The function to set the selected node data.
 * @param ShowIfStatementsOptionsCellProps.id - The id of the button.
 * @param ShowIfStatementsOptionsCellProps.key - The key of the button.
 * @param ShowIfStatementsOptionsCellProps.value - The value of the button.
 * @returns A React component representing the ShowIfStatementsOptionsCell to display and edit the options of a IfStatements node.
 */
const ShowIfStatementsOptionsCell: FC<ShowIfStatementsOptionsCellProps> = ({ node, setNode, id, key, value }) => {
  /**
   * Handles the change of the key of a button.
   * @param event - The event that triggered the change.
   */
  const onKeyChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updateNodeData: DialogStep = {
      ...node.newNodeData,
    };

    updateNodeData.options.ifs.forEach((button: { key: string; value: string }) => {
      if (event.currentTarget.getAttribute("name") === button.key) {
        button.key = event.target.value;
      }
    });

    const newNodeSelection: NodeSelection = {
      oldNodeData: node.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setNode(newNodeSelection);
  };

  /**
   * Handles the change of the value of a button.
   * @param event - The event that triggered the change.
   */
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updateNodeData: DialogStep = {
      ...node.newNodeData,
    };

    updateNodeData.options.ifs.forEach((button: { id: string; key: string; value: string }) => {
      if (event.currentTarget.getAttribute("name") === button.id) {
        button.value = event.target.value;
      }
    });

    const newNodeSelection: NodeSelection = {
      oldNodeData: node.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setNode(newNodeSelection);
  };

  /**
   * Adds a new button to the list of buttons.
   */
  const addIF = (): void => {
    const updateNodeData: DialogStep = {
      ...node.newNodeData,
    };

    const currentElse = updateNodeData.options.ifs[updateNodeData.options.ifs.length - 1];
    updateNodeData.options.ifs[updateNodeData.options.ifs.length - 1] = {
      id: uuidv4(),
      key: "",
      value: "",
      nextStep: "",
    };
    updateNodeData.options.ifs[updateNodeData.options.ifs.length] = currentElse;

    const newNodeSelection: NodeSelection = {
      oldNodeData: node.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setNode(newNodeSelection);
  };

  /**
   * Handles the deletion of a button.
   */
  const onDelete = (): void => {
    const updateNodeData: DialogStep = {
      ...node.newNodeData,
    };

    const newIfs = updateNodeData.options.ifs.filter((e: { id: string }) => e.id !== id);

    updateNodeData.options.ifs = newIfs;

    const newNodeSelection: NodeSelection = {
      oldNodeData: node.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setNode(newNodeSelection);
  };

  if (key !== "ELSE") {
    return (
      <TableRow key={key}>
        <TableCell key={`Key_${key}`}>
          <TextField value={key} onChange={onKeyChange} slotProps={{ input: { name: key } }} />
        </TableCell>
        <TableCell key={`Value_${key}`}>
          <TextField value={value} onChange={onValueChange} slotProps={{ input: { name: key } }} />
        </TableCell>
        <TableCell key={`Action_${key}`}>
          <Button color="error" onClick={onDelete} variant="contained">
            Verwijderen
          </Button>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <>
        <TableRow key="newIF">
          <TableCell colSpan={3} align="center">
            <Button onClick={addIF} variant="contained" color="success" fullWidth={true}>
              +
            </Button>
          </TableCell>
        </TableRow>
        <TableRow key={id}>
          <TableCell key={`Key_${id}`}>{key}</TableCell>
          <TableCell colSpan={2} key={`Value_${id}`}>
            <TextField value={value} onChange={onValueChange} slotProps={{ input: { name: id } }} fullWidth={true} />
          </TableCell>
        </TableRow>
      </>
    );
  }
};

export default ShowIfStatementsOptions;
