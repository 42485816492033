import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";

import { DrawerGroup } from "../../models/Drawer/DrawerGroups";

import DrawerGroupItemChild from "./DrawerGroupItemChild";
import SaveLiveButtons from "./SaveLiveButtons";

export interface DrawerGroupItemProps {
  group: DrawerGroup;
  selectedTab: [string, number] | undefined;
  setSelectedTab: Dispatch<SetStateAction<[string, number] | undefined>>;
}

/**
 * A drawer group item associated with a drawer group
 * @param DrawerGroupProps The drawer group's properties
 * @param DrawerGroupProps.group The drawer group
 * @param DrawerGroupProps.selectedTab The currently selected tab state hook
 * @param DrawerGroupProps.setSelectedTab The setter for selected tabs state hook
 * @returns a drawer group item
 */
const DrawerGroupItem: FC<DrawerGroupItemProps> = ({ group, selectedTab, setSelectedTab }) => {
  const [open, setOpen] = useState<boolean>(true);

  /**
   * Set selected tab index based on current path after rerender
   */
  useEffect(() => {
    for (const item of group.items) {
      if (window.location.href.endsWith(item.urlPath)) {
        setSelectedTab([group.name, item.tabOrder]);
      }
    }
  }, []);

  /**
   * Handle the open state of the drawer group
   */
  const handleOpen = (): void => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        data-testid={`drawer-group-item-${group.name}`}
        key="page-routes-group"
        onClick={handleOpen}
        sx={{ backgroundColor: open ? "#0062ff20" : "" }}
      >
        <ListItemText primary={group.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse data-testid={`drawer-group-content-${group.name}`} in={open} timeout="auto" unmountOnExit>
        {group.name == "Editors" && <SaveLiveButtons />}
        <List data-testid="drawer-group" key="page-routes-group" disablePadding>
          {group.items.map((item) => (
            <DrawerGroupItemChild
              key={`drawer-group-child-${item.tabName}`}
              item={item}
              parentGroup={group.name}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              isLast={group.items.indexOf(item) == group.items.length - 1}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default DrawerGroupItem;
