import React, { DragEvent, FC, useEffect, useRef, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { Box, IconButton, List, ListItem, ListItemText, Stack } from "@mui/material";

import { deepCopy } from "../../helpers/DeepCopy";

import FileUploadButton from "./FileUploadButton";
import { validateFiles } from "./FileUploadHelper";

interface FileUploadProps {
  setFiles: (files: Array<File>) => void;
  filenames?: Array<string>;
  updateFilenames?: (filenames: Array<string>) => void;
}

/**
 * The functional component that handles retrieving files as File objects.
 * @param FileUploadProps - the props for the FileUpload component
 * @param FileUploadProps.setFiles - the function to set the files
 * @param FileUploadProps.filenames - the filenames of the files
 * @param FileUploadProps.updateFilenames - the function to update the filenames
 * @returns - the FileUpload component
 */
const FileUpload: FC<FileUploadProps> = ({ setFiles, filenames = [], updateFilenames }) => {
  const [newFiles, setNewFiles] = useState<Array<File>>([]);
  const [currentFilenames, setCurrentFilenames] = useState<Array<string>>(deepCopy(filenames));
  const [dragOver, setDragOver] = useState<boolean>(false);

  useEffect(() => {
    setFiles(newFiles);
  }, [newFiles]);

  useEffect(() => {
    if (updateFilenames) updateFilenames(currentFilenames);
  }, [currentFilenames]);

  /**
   * Handle the deletion of a file from the drop selection
   * @param filenameToDelete - the name of the file to delete
   */
  const handleDelete = (filenameToDelete: string): void => {
    if (newFiles.some((file) => file.name === filenameToDelete)) {
      setNewFiles(newFiles.filter((file) => file.name !== filenameToDelete));
    } else {
      setCurrentFilenames(currentFilenames.filter((filename) => filename !== filenameToDelete));
    }
  };

  /**
   * Handles dropping files
   * @param event - user input from HTML element
   */
  const handleFileDrop = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();

    const droppedFiles = Array.from(event.dataTransfer.files);
    const acceptedFiles: Array<File> = [...newFiles, ...validateFiles(droppedFiles, currentFilenames, newFiles)];

    setNewFiles(acceptedFiles);
    setDragOver(false);
  };

  /**
   * Handles choosing file from file explorer
   * @param event - user input from HTML element
   */
  const handleFileChoice = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    const droppedFiles = Array.from(event.target.files ?? []);
    const acceptedFiles: Array<File> = [...newFiles, ...validateFiles(droppedFiles, currentFilenames, newFiles)];

    setNewFiles(acceptedFiles);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  /**
   * Handles clicking file upload button
   */
  const handleButtonClick = (): void => {
    fileInputRef.current?.click();
  };

  const allFileNames = [...currentFilenames, ...newFiles.map((file) => file.name)];

  return (
    <Box
      data-testid="file-upload"
      onDragOver={(event) => {
        event.preventDefault();
        setDragOver(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setDragOver(false);
      }}
      onDrop={handleFileDrop}
      sx={{
        display: "flex",
        justifyContent: "center",
        minHeight: "50px",
        maxHeight: "200px",
        width: "100%",
        border: "2px dashed",
        backgroundColor: dragOver ? "lightblue" : "white",
      }}
    >
      {allFileNames.length === 0 ? (
        <FileUploadButton
          handleButtonClick={handleButtonClick}
          handleFileChoice={handleFileChoice}
          fileInputRef={fileInputRef}
        />
      ) : (
        <Stack sx={{ width: "100%" }}>
          <FileUploadButton
            border
            handleButtonClick={handleButtonClick}
            handleFileChoice={handleFileChoice}
            fileInputRef={fileInputRef}
          />
          <List
            style={{
              display: "flex",
              flexWrap: "wrap",
              height: "100%",
              width: "100%",
              paddingBottom: "0",
              paddingTop: "0",
              overflowY: "auto",
            }}
          >
            {allFileNames.map((filename, index) => (
              <ListItem
                key={`file-${filename}`}
                style={{
                  borderBottom: index < allFileNames.length - 1 ? "1px solid lightgrey" : "none",
                }}
                secondaryAction={
                  <IconButton
                    data-testid={`delete-button-${filename}`}
                    onClick={() => {
                      handleDelete(filename);
                    }}
                  >
                    <CancelIcon color="error" fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemText primary={filename} />
              </ListItem>
            ))}
          </List>
        </Stack>
      )}
    </Box>
  );
};

export default FileUpload;
