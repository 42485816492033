import { TrackedChange } from "./TrackedChange";

export interface ChangeBucket {
  key: string;
  text: string;
  changes: Array<TrackedChange>;
}

export const changeBucketTemplateArray: Array<ChangeBucket> = [
  {
    key: "answers",
    text: "Berichten",
    changes: [],
  },
  {
    key: "dialogs",
    text: "Dialogen",
    changes: [],
  },
  {
    key: "highlights",
    text: "Highlights",
    changes: [],
  },
  {
    key: "groupincidents",
    text: "Groepsincidenten",
    changes: [],
  },
  {
    key: "knowledgesources",
    text: "Kennisbronnen",
    changes: [],
  },
];
