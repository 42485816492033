import React, { FC, ReactElement, useEffect, useState } from "react";

import { ChevronRight } from "@mui/icons-material";
import { Divider, IconButton, Stack, TextField, Typography } from "@mui/material";

import ITSMTicketInformation from "../../../../components/Editors/DialogEditor/DetailsWindow/ITSMTicketInformation";
import ShowAskWithButtonsOptions from "../../../../components/Editors/DialogEditor/DetailsWindow/ShowAskWithButtonsOptions";
import ShowIfStatementsOptions from "../../../../components/Editors/DialogEditor/DetailsWindow/ShowIfStatementsOptions";
import ShowLLMMessageOption from "../../../../components/Editors/DialogEditor/DetailsWindow/ShowLLMMessageOption";
import ShowOptions from "../../../../components/Editors/DialogEditor/DetailsWindow/ShowOptions";
import ShowRedirectOptions from "../../../../components/Editors/DialogEditor/DetailsWindow/ShowRedirectOptions";
import { NodeSelection } from "../../../../components/Editors/DialogEditor/VisualisationDialog";
import ErrorBoundary from "../../../../components/ErrorBoundary/ErrorBoundary";

import { DialogStep } from "../../../../models/Dialogs/DialogStep";

import { ShowOptionsComponentType } from "../../../../enums/ShowOptionsComponentType";
import { StepTexts, StepTypes } from "../../../../enums/StepTypes";

import { TopDeskApiData } from "../../../../API/TopDeskInteraction";

interface DetailsWindowProps {
  selectedNodeData: NodeSelection;
  setSelectedNodeData: React.Dispatch<React.SetStateAction<NodeSelection>>;
  topDeskApiData: TopDeskApiData | undefined;
  isTopDeskDataLoaded: boolean;
  loadTopDeskApiData: () => Promise<boolean>;
  containerName: string;
  selectedDialog: string;
}

/**
 * DetailsWindow Component
 * @param DetailsWindowProps - The properties of the DetailsWindow component.
 * @param DetailsWindowProps.selectedNodeData - The selected node data.
 * @param DetailsWindowProps.setSelectedNodeData - The function to set the selected node data.
 * @param DetailsWindowProps.topDeskApiData - The TopDesk API data.
 * @param DetailsWindowProps.isTopDeskDataLoaded - A boolean indicating if the TopDesk API data is loaded.
 * @param DetailsWindowProps.loadTopDeskApiData - The function to load the TopDesk API data.
 * @param DetailsWindowProps.containerName - The name of the container.
 * @param DetailsWindowProps.selectedDialog - The name of the selected dialog.
 * @returns A DetailsWindow component.
 */
export const DetailsWindow: FC<DetailsWindowProps> = ({
  selectedNodeData,
  setSelectedNodeData,
  topDeskApiData,
  isTopDeskDataLoaded,
  loadTopDeskApiData,
  selectedDialog,
  containerName,
}) => {
  const [type, setType] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    setType(selectedNodeData?.newNodeData?.type);

    switch (selectedNodeData?.newNodeData?.type) {
      case StepTypes.AskWithButtons: {
        setTitle(StepTexts.AskWithButtons);
        break;
      }
      case StepTypes.Conditional: {
        setTitle(StepTexts.Conditional);
        break;
      }
      case StepTypes.CreateTicketDialog: {
        setTitle(StepTexts.CreateTicket);
        break;
      }
      case StepTypes.Message: {
        setTitle(StepTexts.Message);
        break;
      }
      case StepTypes.LLM: {
        setTitle(StepTexts.LLM);
        break;
      }
      case StepTypes.Redirect: {
        setTitle(StepTexts.Redirect);
        break;
      }
      case StepTypes.TextPrompt: {
        setTitle(StepTexts.TextPrompt);
        break;
      }
      case StepTypes.YesNoDialog: {
        setTitle(StepTexts.YesNoDialog);
        break;
      }
    }
  }, [selectedNodeData.newNodeData.type]);

  /**
   * Handles the change of the name of the node.
   * @param event - The event that triggered the change.
   */
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentSelectedNode: NodeSelection | null = selectedNodeData !== null ? { ...selectedNodeData } : null;
    const updateNodeData: DialogStep | null =
      currentSelectedNode !== null ? { ...currentSelectedNode.newNodeData } : null;

    if (updateNodeData !== null) {
      updateNodeData.name = event.target.value;

      const newNodeSelection: NodeSelection = {
        oldNodeData: selectedNodeData?.oldNodeData,
        newNodeData: updateNodeData,
        changed: true,
      };

      setSelectedNodeData(newNodeSelection);
    }
  };

  /**
   * Closes drawer
   */
  const closeDrawer = (): void => {
    setSelectedNodeData({
      oldNodeData: {
        id: "",
        name: "",
        nextStep: "",
        options: undefined,
        type: "",
      },
      newNodeData: {
        id: "",
        name: "",
        nextStep: "",
        options: undefined,
        type: "",
      },
      changed: true,
    });
  };

  /**
   * Return component based on node step type
   * @param type - The type of the step.
   * @returns The component based on the node step type.
   */
  const stepTypeComponent = (type: string): ReactElement => {
    switch (type) {
      case StepTypes.AskWithButtons:
        return (
          <ShowAskWithButtonsOptions selectedNodeData={selectedNodeData} setSelectedNodeData={setSelectedNodeData} />
        );
      case StepTypes.Conditional:
        return (
          <ShowIfStatementsOptions selectedNodeData={selectedNodeData} setSelectedNodeData={setSelectedNodeData} />
        );
      case StepTypes.CreateTicketDialog:
        return (
          <ITSMTicketInformation
            selectedNodeData={selectedNodeData}
            setSelectedNodeData={setSelectedNodeData}
            topDeskApiData={topDeskApiData}
            isTopDeskDataLoaded={isTopDeskDataLoaded}
            loadTopDeskApiData={loadTopDeskApiData}
          />
        );
      case StepTypes.Message:
        return (
          <ShowOptions
            componentType={ShowOptionsComponentType.Message}
            selectedNodeData={selectedNodeData}
            setSelectedNodeData={setSelectedNodeData}
          />
        );
      case StepTypes.LLM:
        return <ShowLLMMessageOption selectedNodeData={selectedNodeData} setSelectedNodeData={setSelectedNodeData} />;
      case StepTypes.Redirect:
        return (
          <ShowRedirectOptions
            selectedNodeData={selectedNodeData}
            setSelectednodeData={setSelectedNodeData}
            containerName={containerName}
            selectedDialog={selectedDialog}
          />
        );
      case StepTypes.TextPrompt: // Please give me my own component
      case StepTypes.YesNoDialog:
        return (
          <ShowOptions
            componentType={ShowOptionsComponentType.Question}
            selectedNodeData={selectedNodeData}
            setSelectedNodeData={setSelectedNodeData}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Stack>
      <Stack direction={"row"} padding={2} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant="h5">{title}</Typography>
        <IconButton
          onClick={() => {
            closeDrawer();
          }}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
      <Divider />
      <ErrorBoundary>
        <Stack padding={2} gap={2}>
          <TextField
            id="input-name"
            label="Naam"
            value={selectedNodeData?.newNodeData?.name}
            onChange={onNameChange}
            fullWidth={true}
          />
          {stepTypeComponent(type)}
        </Stack>
      </ErrorBoundary>
    </Stack>
  );
};
