import React, { FC } from "react";

import { Box, Drawer } from "@mui/material";

import DrawerContents from "./DrawerContents";
import { DrawerHeader } from "./DrawerHeaderSpacer";

export interface FlyoutDrawerProps {
  drawerOpen: boolean;
  drawerWidth: number;
}

/**
 * FlyoutDrawer Component
 * @param FlyoutDrawerProps - The props for the FlyoutDrawer component.
 * @param FlyoutDrawerProps.drawerOpen - Whether the drawer is open.
 * @param FlyoutDrawerProps.drawerWidth - The width of the drawer.
 * @returns A React component representing the FlyoutDrawer to display and edit the dialogs.
 */
const FlyoutDrawer: FC<FlyoutDrawerProps> = ({ drawerOpen, drawerWidth }) => (
  <Drawer
    data-testid="drawer"
    key="app-drawer"
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: drawerWidth,
        boxSizing: "border-box",
      },
    }}
    open={drawerOpen}
    anchor="left"
    variant="persistent"
  >
    <Box data-testid="box">
      <DrawerHeader data-testid="drawer-header" />
      <DrawerContents />
    </Box>
  </Drawer>
);

export default FlyoutDrawer;
