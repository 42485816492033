import React, { FC, useEffect, useState } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  ChipPropsColorOverrides,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";

import { externalStorageContextAttributes } from "../../contexts/ChangeTracking/ExternalStorageContext";
import { localStorageContextAttributes } from "../../contexts/ChangeTracking/LocalStorageContext";

import { TrackedChange } from "../../models/ChangeTracking/TrackedChange";

import { ChangeType } from "../../enums/ChangeType";

import SaveAccordion from "./SaveAccordion";

interface SaveListItemProps {
  change: TrackedChange;
  context: localStorageContextAttributes | externalStorageContextAttributes | null;
}

/**
 * Committable change item Component
 * @param SaveListItemProps - The props for the SaveListItem component
 * @param SaveListItemProps.change - The change to be displayed
 * @param SaveListItemProps.context - The context to be used for the change
 * @returns - The SaveListItem component
 */
const SaveListItem: FC<SaveListItemProps> = ({ change, context }) => {
  useEffect(() => {
    setChecked(change.live);
  }, [change.live]);

  // State
  const [checked, setChecked] = useState<boolean>(change.live);

  /**
   * handle the changing of the checkbox state
   * @param event - the react event that fires this function
   */
  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      setChecked(event.target.checked);

      if (context !== null) {
        // Construct a new tracked change for the update
        const updatedChange: TrackedChange = {
          ...change,
          live: event.target.checked,
        };

        // Update
        context.updateChange!(updatedChange);
      }
    } catch (error) {
      throw new Error(`Failed to check item because of error: ${error}`);
    }
  };

  /**
   * Function to display the item's key correctly.
   * @param change - The change whose item's key should be displayed.
   * @returns - The key to be displayed.
   */
  const displayCorrectKey = (change: TrackedChange): string => {
    // Determine whether to use newValue or oldValue
    const value = change.newValue ?? change.oldValue;

    // Return the appropriate key based on available properties
    return "name" in value ? value.name : value.key;
  };

  /**
   * Display the chip color based on the change type.
   * @param changeType - The change type that will correspond to a color.
   * @returns - The color to be displayed.
   */
  const displayCorrectChipColor = (
    changeType: ChangeType,
  ):
    | OverridableStringUnion<
        "default" | "error" | "warning" | "primary" | "secondary" | "info" | "success",
        ChipPropsColorOverrides
      >
    | undefined => {
    // Switch based on the change type.
    switch (changeType) {
      case ChangeType.New:
        return "success";
      case ChangeType.Delete:
        return "error";
      case ChangeType.Update:
        return "warning";
      default:
        return "default";
    }
  };

  return (
    <ListItem data-testid="list-item" key={`${change.containerName}-change-${change.key}`}>
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          backgroundColor: "rgba(0, 100, 180, 0.15)",
          borderColor: "rgba(0, 0, 0, 0)",
        }}
      >
        <CardHeader
          title={
            <Stack direction="row" spacing={1}>
              <Chip
                data-testid="change-chip"
                label={change.changeType.toString()}
                color={displayCorrectChipColor(change.changeType)}
                size="small"
              />
              <Typography data-testid="display-key" variant="body1">
                {displayCorrectKey(change)}
              </Typography>
            </Stack>
          }
          action={
            <Checkbox inputProps={{ "aria-label": "card-checkbox" }} checked={checked} onChange={handleChecked} />
          }
        />
        <CardContent data-testid="save-accordion">
          <SaveAccordion change={change} />
        </CardContent>
      </Card>
    </ListItem>
  );
};

export default SaveListItem;
