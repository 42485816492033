import { GridColDef, renderBooleanCell, renderEditBooleanCell } from "@mui/x-data-grid";

import { ContainerNames } from "../../../enums/ContainerNames";

import { renderCustomDateCell, renderCustomDateEditCell } from "../../CustomDataGrid/DataGridComponents/DateTimePicker";
import { renderSelectMessageType } from "../../CustomDataGrid/DataGridComponents/SelectMesssageType";

/**
 * Get the columns for the Modular editor by container name
 * @param containerName - The name of the container to get the columns for
 * @returns The columns for the container
 */
export const getColumnsByContainerName = (containerName: ContainerNames): Array<GridColDef> => {
  switch (containerName) {
    case ContainerNames.Answers:
      return [
        {
          field: "type",
          headerName: "Type",
          type: "string",
          width: 150,
          editable: true,
          renderEditCell: renderSelectMessageType,
          /**
           * Gets the correct display text for the message type
           * @param type - The type of the message
           * @returns The correct display text for the message type
           */
          valueFormatter: (type: string): string | undefined => upperCaseFirstLetter(type),
        },
      ];
    case ContainerNames.Highlights:
      return [
        {
          field: "timeStampFrom",
          headerName: "Periode van",
          type: "string",
          width: 200,
          editable: true,
          filterable: false,
          renderCell: renderCustomDateCell,
          renderEditCell: renderCustomDateEditCell,
        },
        {
          field: "timeStampTo",
          headerName: "Periode tot",
          type: "string",
          width: 200,
          editable: true,
          filterable: false,
          renderCell: renderCustomDateCell,
          renderEditCell: renderCustomDateEditCell,
        },
        {
          field: "repeat",
          headerName: "Herhalen",
          type: "boolean",
          width: 125,
          editable: true,
          renderCell: renderBooleanCell,
          renderEditCell: renderEditBooleanCell,
        },
        {
          field: "timeSpan",
          headerName: "Interval",
          type: "number",
          width: 125,
          editable: true,
        },
        {
          field: "enabled",
          headerName: "Ingeschakeld",
          type: "boolean",
          width: 125,
          editable: true,
          renderCell: renderBooleanCell,
          renderEditCell: renderEditBooleanCell,
        },
      ];
    case ContainerNames.GroupIncidents:
      return [
        {
          field: "enabled",
          headerName: "Ingeschakeld",
          type: "boolean",
          width: 125,
          editable: true,
          renderCell: renderBooleanCell,
          renderEditCell: renderEditBooleanCell,
        },
      ];
    default:
      return [];
  }
};

/**
 * Capitalize the first letter of a string
 * @param str - The string to capitalize the first letter of
 * @returns The string with the first letter capitalized or an empty string if the input is null or undefined
 */
export const upperCaseFirstLetter = (str: string | null | undefined): string => {
  if (!str) return "";

  return str.charAt(0).toUpperCase() + str.slice(1);
};
