import React, { ReactNode } from "react";

import { Divider, Stack } from "@mui/material";

import { CheckItemDifference } from "../../../helpers/ObjectDifferences";

import { TrackedChange } from "../../../models/ChangeTracking/TrackedChange";

import { ContainerNames } from "../../../enums/ContainerNames";

import AccordionContentAnswer from "./AccordionContentAnswer";
import AccordionContentDialog from "./AccordionContentDialog";
import AccordionContentHighlight from "./AccordionContentHighlight";

/**
 * Initialize the factory that builds the accordion content.
 * @param change - The change that will be turned into displayable accordion content.
 * @returns The accordion content.
 */
export const AccordionContentFactoryStarter = (change: TrackedChange): ReactNode => {
  // Get the differences
  let differences = {};
  if (![null, undefined].includes(change.oldValue) && ![null, undefined].includes(change.newValue)) {
    differences = CheckItemDifference(change.oldValue, change.newValue);
  }

  return (
    <Stack
      width={1000}
      direction="row"
      spacing={0.5}
      divider={<Divider orientation="vertical" flexItem />}
      justifyContent="space-evenly"
    >
      {![null, undefined].includes(change.oldValue)
        ? AccordionContentFactory(change.oldValue, change.containerName)
        : null}
      {![null, undefined].includes(change.newValue)
        ? AccordionContentFactory(change.newValue, change.containerName, differences)
        : null}
    </Stack>
  );
};

/**
 * Builds the information for a single change.
 * @param record - The change whose information must be displayed.
 * @param containerName - The name of the container that the change belongs to.
 * @param differences - An object representing the differences between the old change and new change
 * @returns An array of react nodes.
 */
const AccordionContentFactory = (
  record: Record<string, any>,
  containerName: string,
  differences?: Record<string, boolean | Record<string, boolean>>,
): ReactNode => {
  if (containerName === ContainerNames.Answers) {
    return <AccordionContentAnswer record={record} differences={differences} />;
  }
  if (containerName === ContainerNames.Dialogs) {
    return <AccordionContentDialog record={record} differences={differences} />;
  }
  if (containerName === ContainerNames.GroupIncidents) {
    return null;
  }
  if (containerName === ContainerNames.Highlights) {
    return <AccordionContentHighlight record={record} differences={differences} />;
  }
};
