import React, { FC, ReactElement } from "react";

import { DEFlagIcon, FRFlagIcon, GBFlagIcon, NLFlagIcon } from "../../Icons/FlagIcons";

interface FlagProps {
  languageKey: string;
}
/**
 * Component that renders a flag icon based on the language key.
 * @param FlagProps - The props for the component
 * @param FlagProps.languageKey - The key of the language
 * @returns The flag icon for the language
 */
const Flag: FC<FlagProps> = ({ languageKey }): ReactElement => {
  switch (languageKey) {
    case "NL":
      return <NLFlagIcon />;
    case "EN":
      return <GBFlagIcon />;
    case "DE":
      return <DEFlagIcon />;
    case "FR":
      return <FRFlagIcon />;
    default:
      return <></>;
  }
};

export default Flag;
