import React, { FC } from "react";

import { Box, LinearProgress, Stack, Typography } from "@mui/material";

/**
 * A skeleton in the form of a displayed utterance list.
 * @returns a loading indicator customized for client configurator
 */
const ClientLoadingIndicator: FC = () => (
  <Box width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
    <Stack
      direction="column"
      spacing={1}
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography variant={"h5"} gutterBottom fontFamily={"sans-serif"}>
        Bedrijfsprofiel Laden...
      </Typography>
      <Box width={"50%"}>
        <LinearProgress />
      </Box>
    </Stack>
  </Box>
);

export default ClientLoadingIndicator;
