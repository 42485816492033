import React, { FC, useEffect, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";

import { DialogModel, dialog } from "../../../../models/Dialogs/Dialog";

interface CreateNewDialogProps {
  executable: (update: any) => Promise<void>;
}

/**
 * A functional component representing the 'CreateHighlightDialog'.
 * @param CreateNewDialogProps - The properties of the 'CreateHighlightDialog' component.
 * @param CreateNewDialogProps.executable - The function that will be executed when the dialog is submitted.
 * @returns JSX element representing the 'CreateHighlightDialog'.
 */
const CreateNewDialog: FC<CreateNewDialogProps> = ({ executable }) => {
  const [newDialog, setNewDialog] = useState<DialogModel>({
    ...dialog,
  });
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");

  /**
   * Handles opening the dialog with the specified scroll type.
   * @param scrollType - The scroll type for the dialog (e.g., 'paper', 'body', 'paperScrollBody', etc.).
   * @returns A function that sets the 'scroll' state to the specified scroll type and opens the dialog.
   */
  const handleOpen = (scrollType: DialogProps["scroll"]) => () => {
    setScroll(scrollType);
    setOpen(true);
  };

  /**
   * Handles closing the dialog and resetting the 'newHighlight' state to its initial values.
   */
  const handleClose = (): void => {
    if (["", null, undefined].includes(newDialog.key)) {
      setNewDialog({ ...dialog });
    }
    setOpen(false);
  };

  /**
   * Ref to the description element for accessibility purposes.
   */
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    } else {
      return () => {
        setNewDialog(dialog);
      };
    }
  }, [open]);

  /**
   * Handles the change event when the input values are changed.
   * Updates the corresponding properties in the 'newHighlight' state based on the event target's 'name'.
   * @param event - The React change event representing the change in the input value.
   */
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    const updatedNewDialog: DialogModel = { ...newDialog };

    if (name === "DialogName") {
      updatedNewDialog.name = value;
    }

    setNewDialog(updatedNewDialog);
  };

  return (
    <>
      <Button onClick={handleOpen("paper")}>
        <AddIcon />
      </Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll}>
        <DialogTitle>Nieuwe Dialoog</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText id="new-dialog-description" borderBottom={"1px solid #dee2e6"} marginBottom={"10px"}>
              Vul de benodigde informatie in om een nieuwe dialoog toe te voegen aan de chatbot.
            </DialogContentText>
            <TextField
              id="input-key-wIcon"
              label="Naam Dialoog"
              variant="outlined"
              fullWidth
              value={newDialog.name}
              onChange={onInputChange}
              name="DialogName"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              executable({ ...newDialog }).catch((error) => {
                throw new Error(error);
              });
              handleClose();
            }}
            disabled={newDialog.name.length <= 0}
          >
            Opslaan
          </Button>
          <Button onClick={handleClose}>Annuleren</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateNewDialog;
