import React, { FC, useCallback, useState } from "react";

import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import { DrawerHeader } from "../../components/FlyoutDrawer/DrawerHeaderSpacer";
import FlyoutDrawer from "../../components/FlyoutDrawer/FlyoutDrawer";
import Header from "../../components/Headers/Header";
import Routing from "../../components/Routing/Routing";

import "./Home.css";

const drawerWidth = 290;

/**
 * Renders the home page of the application
 * @returns A React Function Component
 */
const Home: FC = () => {
  // State
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

  /**
   * This function handles the opening of the drawer
   * @returns void
   */
  const handleDrawerOpen = useCallback(() => {
    setIsDrawerOpen((opened: boolean) => !opened);
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <FlyoutDrawer drawerWidth={drawerWidth} drawerOpen={isDrawerOpen} />
      <Header authenticated={true} isDrawerOpen={isDrawerOpen} handleDrawerOpen={handleDrawerOpen} />
      <FlexContent open={isDrawerOpen}>
        <DrawerHeader />
        <Stack height="90%">
          <Routing />
        </Stack>
      </FlexContent>
    </Box>
  );
};

export default Home;

/**
 * FlexContent JSX style for page to respond to drawer open state
 */
const FlexContent = styled("div", {
  /**
   * Function to check if a prop should be forwarded
   * @param prop - the prop to check if it should be forwarded
   * @returns properties for the flex grow and shrink of the page content if the drawer is open or not
   */
  shouldForwardProp: (prop) => prop !== "open",
})<{ open?: boolean }>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: `-${drawerWidth}px`,
  ...(open === true && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
