import React, { FC, useEffect, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { externalStorageContextAttributes } from "../../contexts/ChangeTracking/ExternalStorageContext";
import { localStorageContextAttributes } from "../../contexts/ChangeTracking/LocalStorageContext";

import { ChangeBucket } from "../../models/ChangeTracking/ChangeBucket";
import { TrackedChange } from "../../models/ChangeTracking/TrackedChange";

import SaveListItem from "./SaveListItem";

interface SaveListProps {
  changeBucket: ChangeBucket;
  context: localStorageContextAttributes | externalStorageContextAttributes | null;
}

/**
 * Component that will allow us to display the changes
 * @param SaveListProps - the props that this component takes in
 * @param SaveListProps.changeBucket - the change bucket that we are displaying
 * @param SaveListProps.context - the context that we are using to store the changes
 * @returns JSX.Element - the element that
 */
const SaveList: FC<SaveListProps> = ({ changeBucket, context }) => {
  useEffect(() => {
    /**
     * validate the reason why the checkbox should be checked or not at render
     * @returns boolean - whether the checkbox should be checked or not
     */
    const validateAllChecked = (): boolean => {
      let count = 0;

      for (const change of changeBucket.changes) {
        if (change.live) count++;
      }

      return count === changeBucket.changes.length;
    };

    setChecked(validateAllChecked());
  }, [changeBucket, context]);

  const [open, setOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  /**
   * handle the changing of the checkbox state
   * @param event - the react event that fires this function
   */
  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);

    try {
      const updatedChanges = changeBucket.changes.map((change) => ({
        ...change,
        live: event.target.checked,
      }));

      const updatedBucket = {
        ...changeBucket,
        changes: updatedChanges,
      };

      if (context !== null) {
        context.updateChanges!(updatedBucket);
      }
    } catch (error) {
      throw new Error(`Failed to check item because of error: ${error}`);
    }
  };

  if (context === null) {
    return <CircularProgress data-testid="loading-indicator" />;
  }

  return (
    <>
      <ListItem
        data-testid="list-item"
        key={`${changeBucket.key}-externalChange`}
        secondaryAction={
          <Box data-testid="action-container">
            <Checkbox
              inputProps={
                { "aria-label": `checkbox-${changeBucket.key}` } satisfies React.InputHTMLAttributes<HTMLInputElement>
              }
              checked={checked}
              onChange={handleChecked}
            />
            <IconButton
              data-testid="expand-button"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        }
      >
        <ListItemText data-testid="list-item-text">
          {changeBucket.changes.length > 1
            ? `${changeBucket.text} heeft ${changeBucket.changes.length} veranderingen`
            : `${changeBucket.text} heeft ${changeBucket.changes.length} verandering`}
        </ListItemText>
      </ListItem>
      <Collapse data-testid="collapse" in={open} timeout="auto" unmountOnExit>
        <List sx={{ bgcolor: "background.paper" }} disablePadding>
          {changeBucket.changes.map((change: TrackedChange) => (
            <Box data-testid={`save-list-item-${change.key}`} key={change.key}>
              <SaveListItem key={`committable-list-item-${change.key}`} change={change} context={context} />
              <Divider />
            </Box>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default SaveList;
