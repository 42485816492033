export enum TabPaths {
  Home = "",
  Dialogs = "/Dialogs",
  Faq = "/Faq",
  GroupsIncidents = "/GroupsIncidents",
  Highlights = "/Highlights",
  KnowledgeSources = "/KnowledgeSources",
  Messages = "/Messages",
  Questions = "/Questions",
  ClientConfig = "/ClientConfig",
}
