import { NluContextAttributes } from "../../../contexts/NLU/NluContext";

/**
 * Handles the change of CLU projects
 * @param CLUContext - Context of CLU
 * @param project - The specific project to change to
 */
export const handleProjectChange = async (CLUContext: NluContextAttributes | null, project: string): Promise<void> => {
  if (CLUContext === null) return;
  await CLUContext.setSelectedProject!(project);
};

/**
 * Train the current CLU app
 * @param CLUContext - current CLUContext
 * @param setTraining - the function to set the training state
 * @returns - only if clucontext is null
 */
export const handleTrainingRequest = async (
  CLUContext: NluContextAttributes | null,
  setTraining: (value: boolean) => void,
): Promise<void> => {
  if (CLUContext === null) return;
  setTraining(true);
  await CLUContext.trainSelectedProject!();
  const isTraining = await CLUContext.getTrainingStatus!();
  setTraining(isTraining);
};

/**
 * Gets the current training status
 * @param CLUContext - current CLUContext
 * @param setTraining - the function to set the training state
 * @returns - only if clucontext is null
 */
export const getTrainingStatus = async (
  CLUContext: NluContextAttributes | null,
  setTraining: (value: boolean) => void,
): Promise<void> => {
  if (CLUContext === null) return;

  const isTraining = await CLUContext.getTrainingStatusOnCallInterval!(false);
  setTraining(isTraining);
};
