import React, { FC, useEffect, useState } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import SyncIcon from "@mui/icons-material/Sync";
import { Alert, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { useClientContext } from "../../contexts/ClientContext";
import { useNLUContext } from "../../contexts/NLU/NluContext";
import { useSyncContext } from "../../contexts/SyncContext";

import { getTrainingStatus, handleTrainingRequest } from "../Editors/Questions/helpers";

/**
 * Component with dropdown for synchronization
 * @returns a functional component
 */
const DropDownwithSync: FC = () => {
  const ClientContext = useClientContext();
  const CLUContext = useNLUContext();
  const SyncContext = useSyncContext();

  const [clientId, setClientId] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  // Whether current version is multi- or single tenant
  const isMultiTenant: boolean = process.env.REACT_APP_IS_MULTI_TENANT === "true";

  useEffect(() => {
    if (isMultiTenant && clientId === null) return;
    if (SyncContext?.checkSyncLock !== undefined) {
      void SyncContext.checkSyncLock(clientId);
    }

    void getTrainingStatus(CLUContext, setIsTraining);
  }, [clientId, CLUContext]);

  /** Check every 20 seconds if CLU project is being trained */
  useEffect(() => {
    // Interval between calls in miliseconds
    const intervalTime = 20000;

    const interval = setInterval(() => {
      getTrainingStatus(CLUContext, setIsTraining).catch((err) => {
        throw new Error(err);
      });
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (isMultiTenant && ClientContext?.clientId !== null) {
      setClientId(ClientContext!.clientId);
    } else if (!isMultiTenant) {
      setClientId(null);
    }
  }, [ClientContext?.clientId]);

  useEffect(() => {
    if (SyncContext !== null) {
      setIsSyncing(SyncContext.isSyncing);
      setAlertOpen(SyncContext.isAlertOpen);
    }
  }, [SyncContext?.isSyncing, SyncContext?.isAlertOpen]);

  return (
    <>
      <ListItemButton data-testid={`dropdown-button`} key="dropdownWithSync" onClick={() => setOpen(!open)}>
        <ListItemText primary={"Overige functies"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse data-testid={`dropdownWithSync`} in={open} timeout="auto" unmountOnExit>
        <List data-testid="dropdownWithSync-list" key="page-routes-group">
          <ListItemButton
            data-testid="sync-button"
            key={"Sync_KnowledgeSources"}
            sx={{ py: 0, minHeight: 32, color: "rgba(0,0,0,.8)" }}
            onClick={() => {
              if (SyncContext?.syncSources !== undefined) {
                void SyncContext.syncSources(clientId);
              }
            }}
            disabled={isSyncing}
            divider
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <SyncIcon />
            </ListItemIcon>
            <ListItemText
              primary={isSyncing ? "Bezig met synchroniseren" : "Synchroniseer kennisbronnen"}
              primaryTypographyProps={{ fontSize: 14, fontWeight: "medium" }}
            />
          </ListItemButton>
          <Collapse in={alertOpen}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="info"
              sx={{ height: 100 }}
            >
              Synchronisatie al actief, probeer later opnieuw!
            </Alert>
          </Collapse>
          <ListItemButton
            data-testid="training-button"
            key={"train_NLU"}
            sx={{ py: 0, minHeight: 32, color: "rgba(0,0,0,.8)" }}
            onClick={
              isTraining
                ? undefined
                : async () => {
                    await handleTrainingRequest(CLUContext, setIsTraining);
                  }
            }
            disabled={isTraining}
            divider
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              <ModelTrainingIcon />
            </ListItemIcon>
            <ListItemText
              primary={isTraining ? `NLU is aan het trainen` : `NLU Trainen`}
              primaryTypographyProps={{ fontSize: 14, fontWeight: "medium" }}
            />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

export default DropDownwithSync;
