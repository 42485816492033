import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { red } from "@mui/material/colors";
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from "@xyflow/react";

import styles from "../Visualisation_Dialog.module.scss";
import { DialogEdge as DialogEdgeType } from "./JsonToReactFlow";

/**
 * Component for a specific edge
 * @param EdgeProps - the properties of the edge component
 * @param EdgeProps.id - the id of the edge
 * @param EdgeProps.sourceX - the x coordinate of the source
 * @param EdgeProps.sourceY - the y coordinate of the source
 * @param EdgeProps.targetX - the x coordinate of the target
 * @param EdgeProps.targetY - the y coordinate of the target
 * @param EdgeProps.sourcePosition - the position of the source
 * @param EdgeProps.targetPosition - the position of the target
 * @param EdgeProps.style - the style of the edge
 * @param EdgeProps.markerEnd - the marker end of the edge
 * @param EdgeProps.selected - the selected state of the edge
 * @param EdgeProps.source - the source of the edge
 * @param EdgeProps.target - the target of the edge
 * @param EdgeProps.data - the data of the edge
 * @returns - Edge component
 */
export const DialogEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  selected,
  source,
  target,
  data,
}: EdgeProps<DialogEdgeType>): JSX.Element => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className="nodrag nopan"
          hidden={selected === undefined || !selected}
        >
          <IconButton
            className={styles.edgeButton}
            onClick={() => {
              data!.onEdgeDelete({
                id,
                from: id.substring(0, id.indexOf("---")),
                to: id.substring(id.indexOf("---") + 3),
                source,
                target,
              });
            }}
          >
            <DeleteIcon sx={{ color: red[500] }} />
          </IconButton>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};
