import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    authority: `${process.env.REACT_APP_AUTHORITY_URL}/${process.env.REACT_APP_IDENTITY_PROVIDER_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
    storeAuthStateInCookie: process.env.REACT_APP_STORE_AUTH_STATE_IN_COOKIE as boolean | undefined,
  },
};

export const loginRequest: RedirectRequest = {
  scopes: [process.env.REACT_APP_LOGIN_SCOPE!],
};

export const KnowledgeSourceWebAppsConfig = {
  knowledgeSourceWebAppCallTimeInterval: process.env.REACT_APP_KNOWLEDGE_SOURCE_WEBAPP_CALL_TIME_INTERVAL,
  knowledgeSourceDocumentSizeLimit: process.env.REACT_APP_KNOWLEDGE_SOURCE_DOCUMENT_SIZE_LIMIT,
};
