import { allowedFileExtensions } from "../../models/KnowledgeSources/KnowledgeSource";

import { KnowledgeSourceWebAppsConfig } from "../../API/Auth/AuthConfig";

/**
 * Handles validating file type, file size and avoiding duplicates files (based on name)
 * @param droppedFiles - files to be validated
 * @param currentFilenames - filenames of the files
 * @param newFiles - files that are new
 *  @returns - files that passed validation
 */
export const validateFiles = (
  droppedFiles: Array<File>,
  currentFilenames: Array<string>,
  newFiles: Array<File>,
): Array<File> => {
  // Document size limit fail-safe default is 2MB
  let documentSizeLimit = 2000000;
  if (KnowledgeSourceWebAppsConfig.knowledgeSourceDocumentSizeLimit !== undefined) {
    documentSizeLimit = parseInt(KnowledgeSourceWebAppsConfig.knowledgeSourceDocumentSizeLimit);
  }

  // Filter files based on extension, url-reserved characters, size and duplication
  const acceptedFiles = droppedFiles.filter(
    (file) =>
      allowedFileExtensions.includes(getExtension(file.name)) &&
      !file.name.includes("#") &&
      !file.name.includes("?") &&
      file.size < documentSizeLimit &&
      !currentFilenames.includes(file.name) &&
      !newFiles.map((newfile) => newfile.name).includes(file.name),
  );

  return acceptedFiles;
};

/**
 * Get the extension from a file name
 * @param filename - the name of the file
 * @returns - the extension of the file
 */
const getExtension = (filename: string): string => {
  const ext = filename.split(".").pop();
  if (ext === undefined) {
    throw new Error("Filename is missing extension");
  }

  return ext;
};
