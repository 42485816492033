import { StepTypes } from "../../enums/StepTypes";
import { DialogStep } from "./DialogStep";

export type DialogVersion = {
  version: string;
  active: boolean;
  steps: Array<DialogStep>;
};

export const defaultDialogVersion: DialogVersion = {
  version: "0.1",
  active: true,
  steps: [
    {
      id: "start",
      name: "start",
      type: StepTypes.Start,
      nextStep: "",
      options: null,
    },
    {
      id: "finish",
      name: "finish",
      type: StepTypes.Finish,
      options: null,
    },
  ],
};
