import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { SyncError } from "../components/KnowledgeSources/KnowledgeSourcesHelper";

import { webApiConfig } from "./ApiConfiguration";
import { getToken } from "./Auth/Auth";

enum SyncEndpoint {
  CheckLock = "/CheckLock",
  CheckStatus = "/CheckStatus",
}

/**
 * Generic function to setup an API call
 * @param clientId - The client ID to use for the API call
 * @param endpoint - The endpoint to send the request to
 * @returns The response from the API
 */
export const genericSyncCall = async (clientId: string | null, endpoint = ""): Promise<AxiosResponse> => {
  try {
    // set the base endpoint for file uploads
    const url = `${webApiConfig.endpointUrl}/Synchronize${endpoint}`;
    const token = await getToken();

    const requestConfig: AxiosRequestConfig = {
      params: { clientId },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    return await axios.post(url, {}, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 409) {
        throw new SyncError("Synchronization is locked");
      }
      throw new Error(
        `API call failed, error message: ${error.message}, request url: ${error.config?.url}, info: ${error.response?.data.title}`,
      );
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Start a synchronization operation to update data
 * @param clientId - The client ID to use for the API call
 * @returns The response from the API
 */
export const synchronize = async (clientId: string | null): Promise<void> => {
  await genericSyncCall(clientId);
};

/**
 * Start a synchronization operation to update data
 * @param clientId - The client ID to use for the API call
 * @returns The response from the API
 */
export const checkLock = async (clientId: string | null): Promise<void> => {
  await genericSyncCall(clientId, SyncEndpoint.CheckLock);
};

/**
 * Start a synchronization operation to update data
 * @param clientId - The client ID to use for the API call
 */
export const checkStatus = (clientId: string | null): void => {
  void genericSyncCall(clientId, SyncEndpoint.CheckStatus);
};
