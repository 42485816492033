import React, { Dispatch, FC, SetStateAction } from "react";

import { useNavigate } from "react-router-dom";

import { Badge, ListItem, ListItemButton, ListItemText } from "@mui/material";

import { useExternalStorageContext } from "../../contexts/ChangeTracking/ExternalStorageContext";
import { useLocalStorageContext } from "../../contexts/ChangeTracking/LocalStorageContext";

import { DrawerItem } from "../../models/Drawer/DrawerItems";

interface DrawerGroupItemChildProps {
  item: DrawerItem;
  parentGroup: string;
  selectedTab: [string, number] | undefined;
  setSelectedTab: Dispatch<SetStateAction<[string, number] | undefined>>;
  isLast: boolean;
}

/**
 * A drawer group item's child that is associated with a drawer item
 * @param DrawerGroupItemChildProps The drawer item's properties
 * @param DrawerGroupItemChildProps.item The drawer item
 * @param DrawerGroupItemChildProps.parentGroup The drawer item's parent group
 * @param DrawerGroupItemChildProps.selectedTab The currently selected tab state hook
 * @param DrawerGroupItemChildProps.setSelectedTab The setter for selected tabs state hook
 * @param DrawerGroupItemChildProps.isLast Is the list element the last of a list?
 * @returns a drawer group item child
 */
const DrawerGroupItemChild: FC<DrawerGroupItemChildProps> = ({
  item,
  parentGroup,
  selectedTab,
  setSelectedTab,
  isLast,
}) => {
  const localStorageContext = useLocalStorageContext();
  const externalStorageContext = useExternalStorageContext();
  const navigate = useNavigate();

  if (externalStorageContext === null || localStorageContext === null) {
    throw new Error("Contexts should not be null");
  }

  /**
   * Handles the click a different list item, which corresponds to switching tab.
   * @param item - The drawer item which corresponds to a tab
   */
  const handleListItemClick = (item: DrawerItem): void => {
    setSelectedTab([parentGroup, item.tabOrder]);
    navigate(item.urlPath);
  };

  /**
   * Finds the bucket and checks for tracked changes from staging to live.
   * @param item - drawer item to check for a tracked change
   * @returns number of changes or undefined if there are no changes
   */
  const getExternalChanges = (item: DrawerItem): number | undefined => {
    const bucket = externalStorageContext.changeBuckets.find((bucket) => bucket.key === item.key);
    if (bucket === undefined) return bucket;

    return bucket.changes.length;
  };

  /**
   * Finds the bucket and checks for tracked changes from local to staging.
   * @param item - drawer item to check for a tracked change
   * @returns whether there are changes
   */
  const getLocalChanges = (item: DrawerItem): boolean => {
    const bucket = localStorageContext.changeBuckets.find((bucket) => bucket.key === item.key);
    if (bucket === undefined) return false;

    return bucket.changes.length > 0;
  };

  return (
    <ListItem
      data-testid={`drawer-group-item-${item.tabName}`}
      key={item.key}
      dense
      disablePadding
      divider={!isLast}
      secondaryAction={
        getExternalChanges(item) !== undefined ? (
          <Badge data-testid={`badge-${item.key}`} badgeContent={getExternalChanges(item)} color="primary" />
        ) : null
      }
    >
      <ListItemButton
        data-testid={`list-item-button-${item.key}`}
        key={item.key}
        selected={selectedTab?.[0] === parentGroup && selectedTab?.[1] === item.tabOrder}
        onClick={() => {
          handleListItemClick(item);
        }}
        sx={{
          backgroundColor: "white",
          color: "black",
          width: "100%",
          "&.Mui-selected": {
            backgroundColor: "rgba(30, 110, 255, 0.66)",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "rgba(30, 110, 255, 1)",
            color: "white",
          },
          "&:hover": {
            backgroundColor: "rgba(30, 110, 255, 0.33)",
          },
        }}
      >
        {getLocalChanges(item) ? (
          <ListItemText primary={`${item.tabName}*`} />
        ) : (
          <ListItemText primary={item.tabName} />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default DrawerGroupItemChild;
