import React, { FC, useEffect, useState } from "react";

import { DialogContent, FormControl, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { Language, LanguageText } from "../../models/Language";

import LanguageSelect from "../Selects/LanguageSelect";

export interface AddOrEditLanguageDialogProps {
  addLanguage?: (value: LanguageText) => void;
  removeLanguage?: (languageKey: string) => void;
  isOpen: boolean;
  isEdit: boolean;
  handleClose?: () => void;
  languages: Array<Language>;
  dialogLanguage: string;
  dialogValue: string;
  title: string;
}

export const defaultAddOrEditLanguageDialogProps: AddOrEditLanguageDialogProps = {
  isOpen: false,
  isEdit: false,
  languages: [],
  dialogValue: "",
  dialogLanguage: "",
  title: "",
};

/**
 * A dialog that allows the user to add a new language to the current text.
 * @param AddOrEditLanguageDialogProps - the properties of the component
 * @param AddOrEditLanguageDialogProps.isOpen - whether the dialog is open
 * @param AddOrEditLanguageDialogProps.handleClose - function to handle closing the dialog
 * @param AddOrEditLanguageDialogProps.languages - the languages that can be selected
 * @param AddOrEditLanguageDialogProps.dialogValue - the value of the dialog
 * @param AddOrEditLanguageDialogProps.dialogLanguage - the language of the dialog
 * @param AddOrEditLanguageDialogProps.isEdit - whether the dialog is in edit mode
 * @param AddOrEditLanguageDialogProps.addLanguage - function to add a language
 * @param AddOrEditLanguageDialogProps.removeLanguage - function to remove a language
 * @param AddOrEditLanguageDialogProps.title - the title of the dialog
 * @returns A React Function Component
 */
const AddOrEditLanguageDialog: FC<AddOrEditLanguageDialogProps> = ({
  isOpen,
  handleClose,
  languages,
  dialogValue,
  dialogLanguage,
  isEdit,
  addLanguage,
  removeLanguage,
  title,
}) => {
  const [value, setValue] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");

  useEffect(() => {
    if (dialogLanguage === null || dialogLanguage === undefined || dialogLanguage === "") {
      setSelectedLanguage(languages.length > 0 ? languages[0].key : "");
    } else {
      setSelectedLanguage(dialogLanguage);
    }
  }, [dialogLanguage, languages]);

  useEffect(() => {
    setValue(dialogValue);
  }, [dialogValue]);

  /**
   * Handle the confirm button click.
   */
  const handleConfirm = (): void => {
    if (addLanguage === undefined || handleClose === undefined) return;

    const languageText: LanguageText = {
      key: selectedLanguage,
      value,
    };

    addLanguage(languageText);
    handleClose();
  };

  /**
   * Handle the language change.
   * @param newLanguage - The new language
   */
  const handleChangeLanguage = (newLanguage: string): void => {
    setSelectedLanguage(newLanguage);
  };

  return (
    <>
      {addLanguage && removeLanguage && handleClose && (
        <Dialog
          open={isOpen}
          onClose={() => {
            handleClose();
          }}
          fullWidth={true}
          scroll="paper"
        >
          <DialogTitle data-testid="dialog-title">{title}</DialogTitle>
          <DialogContent dividers={true}>
            {!isEdit && (
              <LanguageSelect
                data-testid="language-select"
                handleChange={handleChangeLanguage}
                languages={languages}
                label="Taal"
                selectedValue={selectedLanguage}
              />
            )}
            <FormControl fullWidth>
              <TextField
                slotProps={{ htmlInput: { "data-testid": "dialog-textfield" } }}
                multiline={true}
                variant="outlined"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                placeholder="Voeg tekst toe voor de geselecteerde taal"
                autoFocus
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="confirm-button"
              onClick={() => {
                handleConfirm();
              }}
              color="primary"
              variant="contained"
            >
              Akkoord
            </Button>
            {isEdit && (
              <Button
                data-testid="remove-button"
                onClick={() => {
                  removeLanguage(dialogLanguage);
                  handleClose();
                }}
                color="error"
                variant="contained"
              >
                Verwijderen
              </Button>
            )}
            <Button
              data-testid="cancel-button"
              onClick={() => {
                handleClose();
              }}
              color="warning"
              variant="contained"
            >
              Annuleer
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AddOrEditLanguageDialog;
