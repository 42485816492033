import React from "react";

import { v4 as uuidv4 } from "uuid";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Checkbox, Divider, IconButton, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { ButtonOption } from "../../../../models/Dialogs/ButtonOption";
import { DialogStep } from "../../../../models/Dialogs/DialogStep";

import { ShowOptionsComponentType } from "../../../../enums/ShowOptionsComponentType";

import { NodeSelection } from "../VisualisationDialog";
import ShowOptions from "./ShowOptions";

interface ShowAskWithButtonsOptionsProps {
  selectedNodeData: NodeSelection;
  setSelectedNodeData: (arg0: NodeSelection) => void;
}

/**
 * ShowAskWithButtonsOptions Component
 * @param ShowAskWihButtonsOptionsProps - The props for the component.
 * @param ShowAskWihButtonsOptionsProps.selectedNodeData - The selected node data.
 * @param ShowAskWihButtonsOptionsProps.setSelectedNodeData - The function to set the selected node data.
 * @returns A React component representing the ShowAskWithButtonsOptions to display and edit the options of a AskWithButtons node.
 */
const ShowAskWithButtonsOptions: React.FC<ShowAskWithButtonsOptionsProps> = ({
  selectedNodeData,
  setSelectedNodeData,
}) => {
  /**
   * Adds a new button to the list of buttons.
   */
  const addButton = (): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.buttons.splice(updateNodeData.options.buttons.length - 1, 0, {
      id: uuidv4(),
      key: "",
      value: "",
      nextStep: "",
    });

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  /**
   * Removes a button based on its ID
   * @param buttonId - The ID of the button to remove.
   */
  const removeButton = (buttonId: string): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.buttons = updateNodeData.options.buttons.filter(
      (button: ButtonOption) => button.id !== buttonId,
    );

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  /**
   *  Handles the change of the else isHidden checkbox.
   * @param event - The event that triggered the change.
   */
  const changeElseIsHidden = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.buttons.forEach((button: ButtonOption) => {
      if (button.key.toUpperCase() === "ELSE") {
        button.isHidden = event.target.checked;
      }
    });

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  return (
    <>
      <ShowOptions
        componentType={ShowOptionsComponentType.Question}
        selectedNodeData={selectedNodeData}
        setSelectedNodeData={setSelectedNodeData}
      />
      <Stack direction="row" alignItems="center">
        {selectedNodeData.newNodeData.options.buttons !== undefined ? (
          <Checkbox
            checked={
              selectedNodeData.newNodeData.options.buttons[selectedNodeData.newNodeData.options.buttons.length - 1]
                .isHidden
            }
            onChange={changeElseIsHidden}
            inputProps={{ "aria-label": "controlled" }}
          />
        ) : null}
        <Box component="span">Verberg &quot;anders&quot; optie</Box>
      </Stack>
      <Divider>Knop opties</Divider>
      <Stack gap={1} divider={<Divider flexItem />}>
        {selectedNodeData?.newNodeData?.options?.buttons?.map((button: ButtonOption) => {
          if (!button.isHidden && button.key !== "else") {
            return (
              <Grid container key={`${button.id}`} direction="column">
                <Grid size={{ xs: 12 }}>
                  <ShowOptions
                    componentType={ShowOptionsComponentType.Button}
                    selectedNodeData={selectedNodeData}
                    setSelectedNodeData={setSelectedNodeData}
                    optionsId={button.id}
                  />
                </Grid>
                <Grid container justifyContent="flex-end">
                  <IconButton
                    onClick={() => {
                      removeButton(button.id);
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          } else {
            return null;
          }
        })}
      </Stack>
      <Button
        startIcon={<AddIcon />}
        onClick={() => {
          addButton();
        }}
        variant="contained"
        color="success"
        fullWidth={true}
      />
    </>
  );
};

export default ShowAskWithButtonsOptions;
