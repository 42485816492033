import React, { StrictMode } from "react";

import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { msalConfig } from "./API/Auth/AuthConfig";
import "./index.css";
import App from "./pages/App";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account Selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload != null) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

// Use BrowserRouter here, because <App> depends on this context
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <App pca={msalInstance} />
    </BrowserRouter>
  </StrictMode>,
);
