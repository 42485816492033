import { useEffect } from "react";

import { Edge, useNodesInitialized, useReactFlow } from "@xyflow/react";

import { type DialogNode } from "./JsonToReactFlow";

import ELK from "elkjs/lib/elk.bundled.js";

// elk layouting options can be found here:
// https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html
const layoutOptions = {
  "elk.algorithm": "layered",
  "elk.direction": "DOWN",
  "elk.layered.spacing.edgeNodeBetweenLayers": "40",
  "elk.spacing.nodeNode": "40",
  "elk.layered.nodePlacement.strategy": "SIMPLE",
};

const elk = new ELK();

/**
 * uses elkjs to give each node a layouted position
 * @param nodes - nodes
 * @param edges - edges
 * @returns layout for nodes and edges
 */
export const getLayoutedNodes = async (nodes: Array<DialogNode>, edges: Array<Edge>): Promise<Array<DialogNode>> => {
  const graph = {
    id: "root",
    layoutOptions,
    children: nodes.map((n) => {
      const targetPorts = n.data.targetHandles.map((t: { id: string }) => ({
        id: t.id,
        properties: {
          side: "NORTH",
        },
      }));

      const sourcePorts = n.data.sourceHandles.map((s: { id: string }) => ({
        id: s.id,
        properties: {
          side: "SOUTH",
        },
      }));

      return {
        id: n.id,

        width: n.measured?.width ?? 200,
        height: n.measured?.height ?? 100,
        properties: {
          "org.eclipse.elk.portConstraints": "FIXED_ORDER",
        },
        // we are also passing the id, so we can also handle edges without a sourceHandle or targetHandle option
        ports: [{ id: n.id }, ...targetPorts, ...sourcePorts],
      };
    }),
    edges: edges.map((e) => ({
      id: e.id,
      sources: [e.sourceHandle ?? e.source],
      targets: [e.targetHandle ?? e.target],
    })),
  };

  const layoutedGraph = await elk.layout(graph);

  const layoutedNodes = nodes.map((node) => {
    const layoutedNode = layoutedGraph.children?.find((lgNode) => lgNode.id === node.id);

    return {
      ...node,
      position: {
        x: layoutedNode?.x ?? 0,
        y: layoutedNode?.y ?? 0,
      },
    };
  });

  return layoutedNodes;
};

/**
 * Function that does something for the layout of the nodes (yes i know this is not a good description, but i don't know what this function does and someone forgot to add a description)
 * @returns Layout nodes
 */
const useLayoutNodes = (): null => {
  const nodesInitialized = useNodesInitialized();
  const { getNodes, getEdges, setNodes, fitView } = useReactFlow<DialogNode>();

  useEffect(() => {
    if (nodesInitialized) {
      /**
       * layout nodes
       */
      const layoutNodes = async (): Promise<void> => {
        const layoutedNodes = await getLayoutedNodes(getNodes(), getEdges());

        setNodes(layoutedNodes);
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        setTimeout(async () => await fitView(), 0);
      };

      layoutNodes().catch((error) => {
        throw new Error(error);
      });
    }
  }, [nodesInitialized, getNodes, getEdges, setNodes, fitView]);

  return null;
};

export default useLayoutNodes;
