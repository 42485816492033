import React, { FC, ReactElement } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface ConfirmationDialogProps {
  open: boolean;
  title?: string;
  button1Text?: string;
  button2Text?: string;
  button1Color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  button2Color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  description?: string;
  bodyContent?: ReactElement;
  fullWidth?: boolean;
  executable?: () => void;
  handleClose?: () => void;
}

/**
 * A generic confirmation dialog.
 * @param ConfirmationDialogProps - The props for the confirmation dialog.
 * @param ConfirmationDialogProps.open - Whether the dialog is open.
 * @param ConfirmationDialogProps.title - The title of the dialog.
 * @param ConfirmationDialogProps.button1Text - The text for the first button.
 * @param ConfirmationDialogProps.button2Text - The text for the second button.
 * @param ConfirmationDialogProps.button1Color - The color for the first button.
 * @param ConfirmationDialogProps.button2Color - The color for the second button.
 * @param ConfirmationDialogProps.description - The description of the dialog.
 * @param ConfirmationDialogProps.bodyContent - The body content of the dialog.
 * @param ConfirmationDialogProps.fullWidth - Whether the dialog is full width.
 * @param ConfirmationDialogProps.executable - The function to execute on confirmation.
 * @param ConfirmationDialogProps.handleClose - The function to close the dialog.
 * @returns Confirmation dialog components
 */
const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  title,
  button1Text,
  button2Text,
  button1Color = "primary",
  button2Color = "error",
  description,
  bodyContent,
  fullWidth,
  executable,
  handleClose,
}) => (
  <>
    {title && button1Text && button2Text && executable && handleClose && (
      <Dialog data-testid="dialog" open={open} onClose={handleClose} fullWidth={fullWidth} maxWidth="lg">
        <DialogTitle data-testid="dialog-title">{title}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="dialog-description" data-testid="dialog-description">
            {description}
          </DialogContentText>
          {bodyContent}
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="confirm-button"
            name="confirm"
            onClick={() => {
              executable();
              handleClose();
            }}
            variant="contained"
            color={button1Color}
          >
            {button1Text}
          </Button>
          <Button
            data-testid="cancel-button"
            name="cancel"
            onClick={() => {
              handleClose();
            }}
            variant="contained"
            color={button2Color}
          >
            {button2Text}
          </Button>
        </DialogActions>
      </Dialog>
    )}
  </>
);

export default ConfirmationDialog;
