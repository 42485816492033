/**
 * Allows us to make a true copy of any array or object.
 * @param target - The array or object to be copied.
 * @returns A deep copy of the target.
 */
export const deepCopy = <T>(target: T): T => {
  // Check whether the original is an object or a an array.
  if (target instanceof Array) {
    // prepare a copy and place the original's items within according to <i, v> pairs.
    const copy: Array<any> = [] as any;
    for (const [index, value] of target.entries()) {
      copy[index] = deepCopy(value);
    }

    return copy as T;
  } else if (target !== null && typeof target === "object") {
    // prepare a copy and place the original's items within according to <k, v> pairs
    const copy: Record<string, any> = { ...(target as Record<string, any>) };

    for (const [key, value] of Object.entries(copy)) {
      copy[key] = deepCopy(value);
    }

    return copy as T;
  } else {
    // Primitive value: atomic, no need to copy.
    return target;
  }
};
