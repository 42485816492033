import React, { FC, ReactElement, useEffect, useState } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import RefreshIcon from "@mui/icons-material/Refresh";
import RestoreIcon from "@mui/icons-material/Restore";
import { Alert, Button, CircularProgress, Collapse, Divider, IconButton, Stack } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";

import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import CreateUploadSourceDialog from "../../components/Dialogs/NewEntryDialogs/CreateUploadSourceDialog";

import { useClientContext } from "../../contexts/ClientContext";
import { useSyncContext } from "../../contexts/SyncContext";

import { KnowledgeSource } from "../../models/KnowledgeSources/KnowledgeSource";

import { SynchronizeStatus } from "../../enums/SynchronizeStatus";

import resources from "../../resources/resources.json";

import { KnowledgeSourceWebAppsConfig } from "../../API/Auth/AuthConfig";
import { deleteFile } from "../../API/FileStorageInteraction";
import {
  createKnowledgeSource,
  deleteKnowledgeSource,
  getKnowledgeSources,
  updateKnowledgeSource,
} from "../../API/KnowledgeSourcesInteraction";
import { checkStatus } from "../../API/SyncInteraction";
import CustomToolTip from "../CustomToolTip";
import KnowledgeSourceEditDialog from "./KnowledgeSourceEditDialog";
import { dateTimeRepresentation, handleUploadFiles } from "./KnowledgeSourcesHelper";

// TODO 4098: change this component to support uploading to staging and live

/**
 * KnowledgeSources Component
 * @returns A React component representing the KnowledgeSources to display
 */
const KnowledgeSources: FC = () => {
  const ClientContext = useClientContext();
  const SyncContext = useSyncContext();

  const [clientId, setClientId] = useState<string | null>(null);

  // State
  const [loading, setLoading] = useState<boolean>(true);
  const [synchronizationOngoing, setSynchronizationOngoing] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  // State variables for json file
  const [knowledgeSources, setKnowledgeSources] = useState<Array<KnowledgeSource>>([]);

  // Table manipulation
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "name", sort: "asc" }]);

  // Confirmation dialog states
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [confirmProps, setConfirmProps] = useState<ConfirmationDialogProps>();

  // Whether current version is multi- or single tenant
  const isMultiTenant: boolean = process.env.REACT_APP_IS_MULTI_TENANT === "true";

  // Columns for the DataGrid component
  const columns: Array<GridColDef> = [
    {
      field: "name",
      headerName: "Kennisbron naam",
      type: "string",
      hideable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,

      /**
       * Render the cell with the status of the source
       * @param param - the row to get actions for
       * @param param.row - the row to get actions for
       * @returns - the cell to display
       */
      renderCell: ({ row }: GridRowModel) => {
        switch (row.status) {
          case SynchronizeStatus.New:
            return <CustomToolTip title="Nieuw, Synchronisatie Vereist" child={<NoteAddIcon color="warning" />} />;
          case SynchronizeStatus.Edit:
            return <CustomToolTip title="Gewijzigd, Synchronisatie Vereist" child={<RefreshIcon color="warning" />} />;
          case SynchronizeStatus.Sync:
            return <CustomToolTip title="Synchronisatie Succesvol" child={<CheckCircleIcon color="success" />} />;
          case SynchronizeStatus.Fail:
            return <CustomToolTip title="Synchronisatie Mislukt" child={<ErrorIcon color="error" />} />;
          case SynchronizeStatus.Delete:
            return <CustomToolTip title="Verwijderd, Synchronisatie Vereist" child={<DeleteIcon color="warning" />} />;
        }
      },
    },
    {
      field: "lastModified",
      headerName: "Laatst gewijzigd",
      type: "string",
      width: 200,

      /**
       * Render the cell with the last modified date
       * @param param - the row to get actions for
       * @param param.row - the row to get actions for
       * @returns - the cell to display
       */
      renderCell: ({ row }: GridRowModel) => dateTimeRepresentation(row.lastModified),
    },
    {
      field: "lastSyncRequest",
      headerName: "Laatste synchronisatie request",
      type: "string",
      width: 220,

      /**
       * Render the last sync request date
       * @param param - the row to get actions for
       * @param param.row - the row to get actions for
       * @returns - the cell to display
       */
      renderCell: ({ row }: GridRowModel) =>
        row.lastSyncRequest === resources.general.knowledgeSourceNotSynced
          ? resources.general.knowledgeSourceNotSynced
          : dateTimeRepresentation(row.lastSyncRequest),
    },
    {
      field: "actions",
      headerName: "Acties",
      type: "actions",
      hideable: false,
      width: 180,

      /**
       * Set actions Open and Delete
       * @param param - the row to get actions for
       * @param param.row - the row to get actions for
       * @returns - the actions to display
       */
      getActions: ({ row }: GridRowModel) => {
        const editAction = (
          <KnowledgeSourceEditDialog
            source={row}
            allKnowledgeSources={knowledgeSources}
            handleEditSource={handleEditSource}
            uploadFiles={handleUploadFiles}
            deleteFile={handleDeleteFile}
          />
        );

        const deleteAction = (
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() => {
              handleDeleteSource(row);
            }}
          />
        );

        const restoreAction = (
          <GridActionsCellItem
            icon={<RestoreIcon color={synchronizationOngoing ? "disabled" : "primary"} />}
            label="Restore"
            disabled={synchronizationOngoing}
            onClick={() => {
              handleRestoreSource(row);
            }}
          />
        );

        return row.status === SynchronizeStatus.Delete ? [restoreAction] : [editAction, deleteAction];
      },
    },
  ];

  useEffect(() => {
    if (isMultiTenant && clientId === null) return;
    setLoading(true);
    if (SyncContext?.checkSyncLock !== undefined) {
      void SyncContext.checkSyncLock(clientId);
    }
    setKnowledgeSources([]);
    retrieveKnowledgeSources();
  }, [clientId]);

  useEffect(() => {
    if (isMultiTenant && ClientContext?.clientId !== null) {
      setClientId(ClientContext!.clientId);
    } else if (!isMultiTenant) {
      setClientId(null);
    }
  }, [ClientContext?.clientId]);

  useEffect(() => {
    if (SyncContext !== null) {
      setSynchronizationOngoing(SyncContext.isSyncing);
      setAlertOpen(SyncContext.isAlertOpen);
    }
  }, [SyncContext?.isSyncing, SyncContext?.isAlertOpen]);

  useEffect(() => {
    if (isMultiTenant && clientId === null) return;

    // TODO 5441: use server-side rendering instead of this live update disaster implementation!
    let intervalTime = 20000;
    if (KnowledgeSourceWebAppsConfig.knowledgeSourceWebAppCallTimeInterval !== undefined) {
      intervalTime = 1000 * parseInt(KnowledgeSourceWebAppsConfig.knowledgeSourceWebAppCallTimeInterval);
    }

    const interval = setInterval(() => {
      if (SyncContext?.checkSyncLock !== undefined) {
        void SyncContext.checkSyncLock(clientId);
      }
      checkStatus(clientId);
      retrieveKnowledgeSources();
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, [clientId]);

  /** Retrieves the knowledge sources from the context. */
  const retrieveKnowledgeSources = (): void => {
    void getKnowledgeSources(clientId).then((data) => {
      if (data.length > 0 && data[0].clientId === clientId) {
        setKnowledgeSources(data);
      }
      setLoading(false);
    });
  };

  /**
   * Remove file from storage
   * @param folderId - the folder to delete file from
   * @param filename - the filename to delete
   */
  const handleDeleteFile = (folderId: string, filename: string): void => {
    deleteFile(`${folderId}/${filename}`);
  };

  /**
   * Handle uploading a new source
   * @param newSource - the source to upload
   */
  const handleSourceUpload = (newSource: KnowledgeSource): void => {
    const newSourceWithId = { ...newSource, clientId };
    createKnowledgeSource(newSourceWithId);
    setKnowledgeSources([...knowledgeSources, newSourceWithId]);
  };

  /**
   * Handle edit of a source
   * @param editSource - the source to edit
   */
  const handleEditSource = (editSource: KnowledgeSource): void => {
    const updatedKnowledgeSources = knowledgeSources.map((source) => {
      if (source.key === editSource.key) {
        editSource.lastModified = new Date().toISOString();
        if (source.status !== SynchronizeStatus.New) {
          editSource.status = SynchronizeStatus.Edit;
        }
        updateKnowledgeSource(editSource, clientId);

        return editSource;
      }

      return source;
    });

    setKnowledgeSources(updatedKnowledgeSources);
  };

  /**
   * Handle deletion of a source
   * @param deleteSource - the source to delete
   */
  const handleDeleteSource = (deleteSource: KnowledgeSource): void => {
    /** Callback for the confirmation dialog.  */
    const confirmDeletion = (): void => {
      const updatedKnowledgeSources = knowledgeSources
        .map((source) => {
          if (source.key === deleteSource.key) {
            if (deleteSource.status === SynchronizeStatus.New) {
              deleteKnowledgeSource(deleteSource.key, clientId);
            } else {
              deleteSource.status = SynchronizeStatus.Delete;
              updateKnowledgeSource(deleteSource, clientId);
            }

            return deleteSource;
          }

          return source;
        })
        .filter((source) => !(source.key === deleteSource.key && source.status === SynchronizeStatus.New));

      setKnowledgeSources(updatedKnowledgeSources);
    };

    const confirmProps: ConfirmationDialogProps = {
      open: true,
      title: `Bevestig verwijdering van "${deleteSource.name}"`,
      button1Text: "Akkoord",
      button2Text: "Annuleer",
      description: `Weet je zeker dat je kennisbron "${deleteSource.name}" wilt verwijderen?`,

      /** Executable if confirmed */
      executable: () => {
        confirmDeletion();
      },

      /** Handle closing confirm dialog */
      handleClose: () => {
        setConfirmOpen(false);
      },
    };
    setConfirmProps(confirmProps);
    setConfirmOpen(true);
  };

  /**
   * Handle restoring a source that is marked for permanent deletion
   * @param restoreSource - the source to restore
   */
  const handleRestoreSource = (restoreSource: KnowledgeSource): void => {
    const updatedKnowledgeSources = knowledgeSources.map((source) => {
      if (source.name === restoreSource.name) {
        // If modification happened last, set status to edit, else to whether last sync was successful
        if (new Date(restoreSource.lastSyncRequest) < new Date(restoreSource.lastModified)) {
          restoreSource.status = SynchronizeStatus.Edit;
        } else if (
          new Date(restoreSource.lastSyncRequest).getTime() === new Date(restoreSource.lastSyncSuccess).getTime()
        ) {
          restoreSource.status = SynchronizeStatus.Sync;
        } else {
          restoreSource.status = SynchronizeStatus.Fail;
        }
        updateKnowledgeSource(restoreSource, clientId);

        return restoreSource;
      }

      return source;
    });

    setKnowledgeSources(updatedKnowledgeSources);
  };

  /**
   * What to show if there is no knowledge sources data
   * @returns - ReactElement
   */
  const noKnowledgeSourcesToShow = (): ReactElement => (
    <Stack height="100%" alignItems="center" justifyContent="center">
      Geen kennisbronnen om weer te geven
    </Stack>
  );

  return (
    <>
      {confirmProps != null ? <ConfirmationDialog {...confirmProps} open={confirmOpen} /> : null}
      <Stack width={"100%"} height={"100%"}>
        <Stack
          sx={{ width: "100%", height: "50px", marginBottom: "10px", marginRight: "10px" }}
          direction={"row"}
          display={"flex"}
          spacing={"5px"}
        >
          <CreateUploadSourceDialog
            allKnowledgeSources={knowledgeSources}
            handleSourceUpload={handleSourceUpload}
            uploadFiles={handleUploadFiles}
            disabled={loading}
          />
          <Divider orientation="vertical" />
          <Button
            variant="outlined"
            sx={{ height: 50 }}
            color={"success"}
            disabled={synchronizationOngoing || loading}
            endIcon={synchronizationOngoing ? <CircularProgress color="inherit" size={20} /> : null}
            style={{
              color: synchronizationOngoing ? "grey" : "",
              borderColor: synchronizationOngoing ? "grey" : "",
            }}
            onClick={() => {
              if (SyncContext?.syncSources !== undefined) {
                void SyncContext?.syncSources(clientId);
              }
            }}
          >
            {synchronizationOngoing ? "Bezig met synchroniseren" : "Synchroniseer kennisbronnen"}
          </Button>
          <Collapse in={alertOpen}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="info"
              sx={{ height: 50 }}
            >
              Synchronisatie al actief, probeer later opnieuw!
            </Alert>
          </Collapse>
        </Stack>
        {/* Think we can use Custom Data grid here now but not Certain yet - Dirk */}
        <DataGrid
          rows={loading ? [] : knowledgeSources}
          getRowId={(row: GridValidRowModel) => row.key}
          columns={columns}
          loading={loading}
          slotProps={{
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "linear-progress",
            },
          }}
          slots={{
            noRowsOverlay: noKnowledgeSourcesToShow,
          }}
          keepNonExistentRowsSelected
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          sortModel={sortModel}
          onSortModelChange={(model: GridSortModel) => {
            setSortModel(model);
          }}
        />
      </Stack>
    </>
  );
};
export default KnowledgeSources;
