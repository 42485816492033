import React, { FC } from "react";

import { List, ListItem, Skeleton } from "@mui/material";

import { ConfirmationDialogProps } from "../../../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import { useNLUContext } from "../../../../../contexts/NLU/NluContext";

import { IntentGroup } from "../../../../../models/Nlu/IntentGroups";
import { NluUtterance } from "../../../../../models/Nlu/NluUtterence";

import "../List.css";
import IntentListItem from "./IntentListItem";

interface IntentListProps {
  intentGroup: IntentGroup;
  handleCloseConfirmationDialog: () => void;
  setConfirmationDialogProps: (confirmationDialogProps: ConfirmationDialogProps) => void;
}

/**
 * A list of intents.
 * @param IntentListProps - The properties that this component requires to function.
 * @param IntentListProps.intentGroup - The intent group that this list is for.
 * @param IntentListProps.handleCloseConfirmationDialog - A function to close the confirmation dialog.
 * @param IntentListProps.setConfirmationDialogProps - A function to set the confirmation dialog properties.
 * @returns A list of intents.
 */
const IntentList: FC<IntentListProps> = ({
  intentGroup,
  handleCloseConfirmationDialog,
  setConfirmationDialogProps,
}: IntentListProps) => {
  // Context
  const CLUContext = useNLUContext();

  if (CLUContext?.selectedProject === null) {
    return (
      <List
        className="List"
        sx={{
          backgroundColor: "white",
          borderRadius: "5px",
          maxHeight: "500px",
          overflow: "auto",
        }}
      >
        {[0, 1, 2, 3].map((item) => (
          <ListItem key={`skeleton-${item}`}>
            <Skeleton width={"100%"} />
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <List
      className="List"
      sx={{
        backgroundColor: "white",
        borderRadius: "5px",
        maxHeight: "250px",
        overflow: "auto",
      }}
    >
      {intentGroup.intents !== null && intentGroup.intents.length > 0 ? (
        <>
          {intentGroup.intents.map((intent) => {
            // Get the utterances that match this intent, and their amount.

            const utterances: Array<NluUtterance> | undefined = CLUContext?.selectedProject!.assets.utterances.filter(
              (utterance) => utterance.intent === intent.name,
            );

            return (
              <IntentListItem
                key={`intent-${intent.name}`}
                count={utterances === undefined ? 0 : utterances.length}
                parentGroup={intentGroup}
                intent={intent}
                handleCloseConfirmationDialog={handleCloseConfirmationDialog}
                setConfirmationDialogProps={setConfirmationDialogProps}
              />
            );
          })}
        </>
      ) : null}
    </List>
  );
};

export default IntentList;
