import React, { FC } from "react";

import { ContainerNames } from "../../../enums/ContainerNames";

import CreateAnswerDialog from "../../Dialogs/NewEntryDialogs/CreateAnswerDialog";
import CreateHighlightDialog from "../../Dialogs/NewEntryDialogs/CreateHighlightDialog";
import { EditorContentType } from "../../Editors/ModularEditor/ModularEditor";

interface DataGridDialogProps {
  containerName: ContainerNames;
  onCreate: (newItem: EditorContentType) => void;
}

/**
 * Renders a dialog based on the 'containerName'
 * @param DataGridDialogProps - The props for the component
 * @param DataGridDialogProps.containerName - The name of the container to render the dialog for.
 * @param DataGridDialogProps.onCreate - The function to execute when creating a new item.
 * @returns A dialog component based on the 'containerName'
 */
const DataGridDialog: FC<DataGridDialogProps> = ({ containerName, onCreate }): JSX.Element => {
  switch (containerName) {
    case ContainerNames.Highlights:
      return <CreateHighlightDialog executable={onCreate} />;
    case ContainerNames.Answers:
      return <CreateAnswerDialog executable={onCreate} />;
    default:
      return <></>;
  }
};

export default DataGridDialog;
