import { Client, ClientDetails } from "../models/Client";

import { ApiMethod } from "../enums/ApiMethod";

import { webApiConfig } from "../API/ApiConfiguration";
import { apiCall, getRequestHeaders } from "./ApiHelpers";

/**
 * Fetches the list of clients from the API.
 * @returns The list of clients.
 */
export const getClients = async (): Promise<Array<Client>> => {
  const url = `${webApiConfig.endpointUrl}/Clients`;
  const requestConfig = await getRequestHeaders();

  return await apiCall<Array<Client>>(url, ApiMethod.GET, requestConfig);
};

/**
 * Fetches a single client from the API with full details.
 * @param clientId - The id of the client to fetch.
 * @returns A detailed client.
 */
export const getClient = async (clientId: string | null): Promise<ClientDetails> => {
  const url = `${webApiConfig.endpointUrl}/Clients/${clientId}`;
  const requestConfig = await getRequestHeaders();

  return await apiCall<ClientDetails>(url, ApiMethod.GET, requestConfig);
};

/**
 * Fetches a single client from the API with full details.
 * @param client - The id of the client to fetch.
 * @returns A detailed client.
 */
export const updateClientConfig = async (client: ClientDetails): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/Clients/${client.clientId}`;
  const requestConfig = await getRequestHeaders();
  const body = {
    ClientName: client.clientName,
    Config: client.config,
  };

  await apiCall(url, ApiMethod.PUT, requestConfig, body);
};
