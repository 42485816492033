import { AxiosError, AxiosResponse } from "axios";

import { ApiMethod } from "../enums/ApiMethod";

import { webApiConfig } from "./ApiConfiguration";
import { apiCall, getRequestHeaders } from "./ApiHelpers";

export enum StorageEnvironment {
  Staging = 0,
  Live = 1,
}

/**
 * Perform proper logging of axios error
 * @param error - The axios error to be logged
 * @returns A string with information about the error
 */
export const logAxiosError = (error: AxiosError): string => {
  let errors = "";
  Object.entries((error.response as AxiosResponse)?.data.errors).forEach(([key, value]) => {
    errors = errors.concat(`(${key}: ${value})`);
  });

  return [
    `API call axios malfunction, error message: ${error.message}, `,
    `request url: ${error.config?.url}, `,
    `info: ${(error.response as AxiosResponse)?.data.title}, `,
    `errors: ${errors}`,
  ].join("");
};

// #region Basic API requests.

/**
 * Get a container from the specified storage environment.
 * @param storageEnvironment - The storage environment we are interacting with
 * @param containerName - the containerName where the item will be put.
 * @param clientId - The client ID to use for the API call
 * @returns an axios response with the response data.
 */
export const getContainer = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  clientId: string | null = null,
): Promise<Array<T>> => {
  const url = `${webApiConfig.endpointUrl}/${containerName}`;

  const requestConfig = { params: { environment: storageEnvironment, clientId }, ...(await getRequestHeaders()) };

  return await apiCall<Array<T>>(url, ApiMethod.GET, requestConfig);
};

/**
 * Get an item from a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with
 * Environment.Staging or Environment.Live
 * @param containerName - the containerName where the item will be put.
 * @param itemKey - the key of the item that will be fetched.
 * @param clientId - The client ID to use for the API call
 * @returns an axios response with the response data.
 */
export const getItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  itemKey: string,
  clientId: string | null = null,
): Promise<T> => {
  const url = `${webApiConfig.endpointUrl}/${containerName}/${itemKey}`;

  const requestConfig = { params: { environment: storageEnvironment, clientId }, ...(await getRequestHeaders()) };

  const response = await apiCall<T>(url, ApiMethod.GET, requestConfig);

  return response;
};

/**
 * create an item in a specified container that matches the item supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be put
 * @returns an axios response with the response data.
 */
export const createItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  item: T,
): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/${containerName}`;

  const requestConfig = { params: { environment: storageEnvironment }, ...(await getRequestHeaders()) };

  await apiCall<T>(url, ApiMethod.POST, requestConfig, item);
};

/**
 * Create an item in a specified container that matches the item supplied.
 * @param storageEnvironment - The storage environment we are interacting with
 * Environment.Staging or Environment.Live
 * @param containerName - the containerName where the item will be put.
 * @param items - the items that we will create.
 * @param clientId - The client ID to use for the API call
 * @returns an axios response with the response data.
 */
export const createItems = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  items: Array<T>,
  clientId: string | null = null,
): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/${containerName}/List`;

  const requestConfig = { params: { environment: storageEnvironment, clientId }, ...(await getRequestHeaders()) };

  await apiCall(url, ApiMethod.POST, requestConfig, items);
};

/**
 * Delete an item in a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with
 * Environment.Staging or Environment.Live
 * @param containerName - the containerName where the item will be put.
 * @param itemKey - the key of the item that will be deleted.
 * @param clientId - The client ID to use for the API call
 * @returns an axios response with the response data.
 */
export const deleteItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  itemKey: string,
  clientId: string | null = null,
): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/${containerName}/${itemKey}`;

  const requestConfig = { params: { environment: storageEnvironment, clientId }, ...(await getRequestHeaders()) };

  await apiCall<T>(url, ApiMethod.DELETE, requestConfig);
};

/**
 * Update an item in a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with
 * @param containerName - the containerName where the item will be put.
 * @param key - the key of the item that will be updated
 * @param item - the item that will be updated
 * @param clientId - The client ID to use for the API call
 */
export const updateItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  key: string,
  item: any,
  clientId: string | null = null,
): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/${containerName}/${key}`;

  const requestConfig = { params: { environment: storageEnvironment, clientId }, ...(await getRequestHeaders()) };

  await apiCall<T>(url, ApiMethod.PUT, requestConfig, item);
};

/**
 * Update an item in a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be updated
 * @param clientId - The client ID to use for the API call
 */
export const updateItems = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  item: any,
  clientId: string | null = null,
): Promise<void> => {
  const url = `${webApiConfig.endpointUrl}/${containerName}/List`;

  const requestConfig = { params: { environment: storageEnvironment, clientId }, ...(await getRequestHeaders()) };

  await apiCall<T>(url, ApiMethod.PUT, requestConfig, item);
};

// #endregion
