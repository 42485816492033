import { DialogVersion } from "../../../models/Dialogs/DialogVersion";
import { Language, LanguageText } from "../../../models/Language";

import { EditorContentType } from "../ModularEditor/ModularEditor";
import { DialogNode, DialogNodeData, fillSourceHandles } from "./ReactFlow/JsonToReactFlow";

/**
 * Update the data of a node
 * @param dialogNodes - the nodes that needs to be updated
 * @param activeDialog - the version of the dialog
 * @returns the updated nodes
 */
export const updateNodeData = (dialogNodes: Array<DialogNode>, activeDialog: DialogVersion): Array<DialogNode> => {
  const updatedNodes = dialogNodes.map((node) => {
    activeDialog.steps.forEach((newNode) => {
      if (newNode.id === node.id) {
        node.data = {
          ...node.data,
          label: newNode.name,
          sourceHandles: fillSourceHandles(newNode),
        };
      }
    });

    return node;
  });

  return updatedNodes;
};

/**
 * Filter the nodes
 * @param nodes - the node that needs to be filtered
 * @param nodeData - the data of the node
 * @returns the filtered nodes
 */
export const filterNodes = (nodes: Array<DialogNode>, nodeData: DialogNodeData): Array<DialogNode> =>
  nodes.filter((node) => node.id !== nodeData.id);

/**
 * Find the language value in the data set
 * @param data - the data set
 * @param languagekey - the language
 * @returns the language value
 */
export const findLanguageValueInDataSet = (data: EditorContentType, languagekey: string): string =>
  data.languages.find((language: LanguageText) => language.key === languagekey)?.value ?? "";

/**
 * Filter the languages
 * @param languages - the languages that needs to be filtered
 * @param data - the data of the languages
 * @returns the filtered languages
 */
export const filterLanguages = (languages: Array<Language>, data: EditorContentType): Array<Language> =>
  languages.filter((language) => !data.languages.some((lang: LanguageText) => lang.key === language.key));
