import { KnowledgeSource } from "../../models/KnowledgeSources/KnowledgeSource";
import { UrlConfig } from "../../models/KnowledgeSources/UrlConfig";

import { KnowledgeSourceType } from "../../enums/KnowledgeSourceType";

import { createFile } from "../../API/FileStorageInteraction";

/**
 * Create a visual timestamp of the give datetime string which is in UTC format
 * @param date - datetime string in UTC format
 * @returns - string representation of the datetime
 */
export const dateTimeRepresentation = (date: string): string => {
  const userTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return new Date(date).toLocaleString("en-GB", {
    timeZone: userTimezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

/**
 * Check if source is of Url type
 * @param type - KnowledgeSourceType
 * @returns - boolean
 */
export const isUrlType = (type: KnowledgeSourceType): boolean => {
  if (
    type === KnowledgeSourceType.AtlassianWiki ||
    type === KnowledgeSourceType.AzureDevOpsWiki ||
    type === KnowledgeSourceType.GoogleDrive ||
    type === KnowledgeSourceType.SharePoint ||
    type === KnowledgeSourceType.UrlScraper
  ) {
    return true;
  }

  return false;
};

/**
 * Check if source is of Url type
 * @param type - KnowledgeSourceType
 * @returns - boolean
 */
export const isDocType = (type: KnowledgeSourceType): boolean => type === KnowledgeSourceType.DocumentUpload;

/**
 * Check if url is a duplicate
 * @param source - KnowledgeSource
 * @param allSources - Array<KnowledgeSource>
 * @returns - boolean
 */
export const isUrlDuplicate = (source: KnowledgeSource, allSources: Array<KnowledgeSource>): boolean => {
  const duplicate: boolean = allSources.some(
    (diffSource) =>
      diffSource.name !== source.name && (diffSource.config as UrlConfig).url === (source.config as UrlConfig).url,
  );

  return duplicate;
};

/**
 * Check if the url is valid
 * @param source - KnowledgeSource
 * @returns - boolean
 */
export const isUrlInvalid = (source: KnowledgeSource): boolean => {
  const { url } = source.config as UrlConfig;

  if (url !== "") {
    try {
      new URL(url);
      if (!url.startsWith("https://")) {
        return true;
      }
    } catch {
      return true;
    }
  }

  return false;
};

/**
 * Check if the source is valid
 */
export class SyncError extends Error {
  /**
   * Constructor
   * @param message - error message
   */
  constructor(message?: string) {
    super(message);
    this.name = "SyncError";
  }
}

/**
 * Uploads the files using Backend API
 * @param files - the files to upload
 * @param folderId - the folder to upload them to
 */
export const handleUploadFiles = (files: Array<File>, folderId: string): void => {
  const sourceFiles = files.map((file) => new File([file], `${folderId}/${file.name}`));

  const formData = new FormData();
  for (const file of sourceFiles) {
    // Parameter name matches input in backend!
    const files = "files";
    formData.append(files, file);
  }

  createFile(formData);
};
