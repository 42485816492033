import React, { FC, useEffect, useRef, useState } from "react";

import { MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";

import { deepCopy } from "../../../helpers/DeepCopy";

import { Answer, defaultAnswerProps } from "../../../models/Answers";
import { messageTextType } from "../../../models/MessageTypes";

import { ContainerNames } from "../../../enums/ContainerNames";
import { ShowOptionsComponentType } from "../../../enums/ShowOptionsComponentType";

import { StorageEnvironment, getContainer } from "../../../API/StorageInteraction";

const defaultLanguage: string = process.env.REACT_APP_GLOBAL_LANGUAGE!;

interface CreateAnswerDialogProps {
  executable: (update: Answer) => void;
  typePredetermined?: ShowOptionsComponentType;
}

/**
 * A functional component representing the 'CreateAnswerDialog'.
 * @param CreateAnswerDialogProps - The properties of the component.
 * @param CreateAnswerDialogProps.executable - A function that handles the upload of a new Answer.
 * @param CreateAnswerDialogProps.typePredetermined - The predetermined type of the answer.
 * @returns A React functional component.
 */
const CreateAnswerDialog: FC<CreateAnswerDialogProps> = ({ executable, typePredetermined }) => {
  const [newAnswer, setNewAnswer] = useState<Answer>(deepCopy(defaultAnswerProps));
  const [allAnswers, setAllAnswers] = useState<Array<Answer>>([]);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const [hasDuplicateKey, setHasDuplicateKey] = useState(false);
  const [hasSpacesInKey, setHasSpacesInKey] = useState(false);
  const [hasKeyError, setHasKeyError] = useState(false);
  const [keyErrorMessage, setKeyErrorMessage] = useState("Deze sleutel is niet juist.");

  /**
   * Ref to the description element for accessibility purposes.
   */
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    } else {
      return () => {
        setNewAnswer(deepCopy(defaultAnswerProps));
      };
    }
  }, [open]);

  // Get all answers.
  useEffect(() => {
    // Get the answers container.
    void getContainer(StorageEnvironment.Staging, ContainerNames.Answers).then((data) => {
      setAllAnswers(data as Array<Answer>);
    });
  }, [newAnswer]);

  // Effect that will help us check against existing answers.
  useEffect(() => {
    const exists: boolean = allAnswers.some((answer: Answer) => answer.key === newAnswer.key);
    if (exists) {
      setHasDuplicateKey(true);
    } else {
      setHasDuplicateKey(false);
    }

    const hasSpaces: boolean = newAnswer.key.includes(" ");
    if (hasSpaces) {
      setHasSpacesInKey(true);
    } else {
      setHasSpacesInKey(false);
    }
  }, [newAnswer]);

  // Effect that will help us display warnings based on flags.
  useEffect(() => {
    if (hasDuplicateKey || hasSpacesInKey) {
      setHasKeyError(true);
    } else {
      setHasKeyError(false);
    }
    if (hasDuplicateKey) {
      setKeyErrorMessage("Deze sleutel bestaat al.");
    }
    if (hasSpacesInKey) {
      setKeyErrorMessage("Deze sleutel mag geen spaties bevatten.");
    }
    if (hasDuplicateKey && hasSpacesInKey) {
      setKeyErrorMessage("Deze sleutel bestaat al en mag geen spaties bevatten.");
    }
  }, [hasDuplicateKey, hasSpacesInKey]);

  /**
   * Handles opening the dialog with the specified scroll type.
   * @param scrollType - The scroll type for the dialog (e.g., 'paper', 'body', 'paperScrollBody', etc.).
   * @returns void
   */
  const handleOpen = (scrollType: DialogProps["scroll"]) => (): void => {
    setScroll(scrollType);
    setOpen(true);
  };

  /**
   * Handles closing the dialog.
   */
  const handleClose = (): void => {
    setOpen(false);
  };

  /**
   * Handles the change event when input value is changed.
   * Updates the property in the 'newAnswer' state with the new value.
   * @param event - The React change event representing the change in the input value.
   * @param property - The property to update in the 'newAnswer' state.
   */
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    property: string,
  ): void => {
    const updatedNewAnswer = { ...newAnswer };

    switch (property) {
      case "key":
        updatedNewAnswer.key = event.target.value;
        break;
      case "language":
        updatedNewAnswer.languages[0] = {
          key: process.env.REACT_APP_GLOBAL_LANGUAGE!,
          value: event.target.value,
        };
        break;
      case "type":
        updatedNewAnswer.type = event.target.value;
        break;
    }

    setNewAnswer(updatedNewAnswer);
  };

  return (
    <>
      <Button onClick={handleOpen("paper")} variant="outlined">
        Nieuw Bericht
      </Button>
      <Dialog open={open} onClose={handleClose} scroll={scroll}>
        <DialogTitle>
          Nieuw Bericht voor
          {defaultLanguage.endsWith(process.env.REACT_APP_GLOBAL_LANGUAGE!) ? <>Nederlands</> : <>Engels</>}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            Vul de benodigde informatie in het Nederlands in om een nieuw herbruikbaar bericht aan te maken, later kunt
            u andere talen toevoegen.
          </DialogContentText>
          <Grid container spacing={2} paddingTop={2}>
            <Grid size={{ xs: 12 }}>
              <TextField
                id="input-key"
                size="small"
                label="Onderwerp"
                error={hasKeyError}
                helperText={hasKeyError ? keyErrorMessage : null}
                fullWidth
                value={newAnswer.key}
                onChange={(event) => {
                  handleInputChange(event, "key");
                }}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TextField
                id="input-lang"
                size="small"
                label="Waarde"
                fullWidth
                defaultValue={newAnswer.languages[0].value}
                onChange={(event) => {
                  handleInputChange(event, "language");
                }}
              />
            </Grid>
            {typePredetermined === undefined && (
              <Grid size={{ xs: 12 }}>
                <TextField
                  select
                  fullWidth
                  label="Bericht Type"
                  size="small"
                  onChange={(event) => {
                    handleInputChange(event, "type");
                  }}
                  value={newAnswer.type}
                >
                  {messageTextType.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={hasKeyError}
            onClick={() => {
              executable({ ...newAnswer, type: typePredetermined ?? newAnswer.type });
              handleClose();
            }}
          >
            Opslaan
          </Button>
          <Button color="error" onClick={handleClose}>
            Annuleren
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateAnswerDialog;
