import React, { FC, useState } from "react";

import { Box, Button, ListItem } from "@mui/material";

import { useExternalStorageContext } from "../../contexts/ChangeTracking/ExternalStorageContext";
import { useLocalStorageContext } from "../../contexts/ChangeTracking/LocalStorageContext";

import ConfirmationDialog from "../Dialogs/ConfirmationDialog/ConfirmationDialog";
import ChangesToSave from "../Save/ChangesToSave";

/**
 * Buttons for saving to live and staging
 * @returns a functional component
 */
const SaveLiveButtons: FC = () => {
  const externalStorageContext = useExternalStorageContext();
  const localStorageContext = useLocalStorageContext();

  const [confirmLiveOpen, setConfirmLiveOpen] = useState<boolean>(false);
  const [confirmSaveOpen, setConfirmSaveOpen] = useState<boolean>(false);

  /**
   * This function gets the current live data and change tracking for live.
   */
  const getCurrentCurrentDataStates = async (): Promise<void> => {
    externalStorageContext?.getChanges!().catch((error) => {
      throw new Error(`Error getting changes from external storage: ${error}`);
    });
  };

  return (
    <ListItem key={"buttons"} dense disablePadding divider>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Button
          key={"save-button"}
          data-testid="save-button"
          variant="outlined"
          color="primary"
          sx={{ borderRadius: 0, width: "50%" }}
          onClick={() => {
            setConfirmSaveOpen(true);
            getCurrentCurrentDataStates().catch((error) => {
              throw new Error(`Error getting changes: ${error}`);
            });
          }}
        >
          Opslaan
        </Button>
        <Button
          key={"live-button"}
          data-testid="live-button"
          variant="outlined"
          color="secondary"
          sx={{ borderRadius: 0, width: "50%" }}
          onClick={() => {
            setConfirmLiveOpen(true);
            getCurrentCurrentDataStates().catch((error) => {
              throw new Error(`Error getting changes: ${error}`);
            });
          }}
        >
          Live brengen
        </Button>

        <ConfirmationDialog
          executable={() => {
            if (localStorageContext !== null) void localStorageContext.saveChanges!();
          }}
          title="Opslaan naar staging"
          bodyContent={<ChangesToSave key={"change-management-staging"} context={localStorageContext} />}
          button1Text="Opslaan"
          button2Text="Annuleren"
          fullWidth={true}
          handleClose={() => {
            setConfirmSaveOpen(false);
          }}
          open={confirmSaveOpen}
        />
        <ConfirmationDialog
          executable={() => {
            if (externalStorageContext !== null) void externalStorageContext.saveChanges!();
          }}
          title="Live brengen"
          bodyContent={<ChangesToSave key={"change-management-live"} context={externalStorageContext} />}
          button1Text="Live brengen"
          button2Text="Annuleren"
          fullWidth={true}
          handleClose={() => {
            setConfirmLiveOpen(false);
          }}
          open={confirmLiveOpen}
        />
      </Box>
    </ListItem>
  );
};

export default SaveLiveButtons;
