import React, { FC, memo, useCallback, useEffect, useRef, useState } from "react";

import {
  Background,
  BackgroundVariant,
  Controls,
  MarkerType,
  MiniMap,
  Node,
  ReactFlow,
  ReactFlowProvider,
  XYPosition,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useUpdateNodeInternals,
} from "@xyflow/react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { Autocomplete, Box, Button, Drawer, FormControl, MenuItem, Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { deepCopy } from "../../../helpers/DeepCopy";

import CustomToolTip from "../../../components/CustomToolTip";
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from "../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import { useLocalStorageContext } from "../../../contexts/ChangeTracking/LocalStorageContext";
import { useNLUContext } from "../../../contexts/NLU/NluContext";

import { TrackedChange } from "../../../models/ChangeTracking/TrackedChange";
import { ButtonOption } from "../../../models/Dialogs/ButtonOption";
import { DialogModel } from "../../../models/Dialogs/Dialog";
import { DialogStep } from "../../../models/Dialogs/DialogStep";
import { DialogVersion } from "../../../models/Dialogs/DialogVersion";
import { ITSMDescriptionQuesion } from "../../../models/Itsm/ItsmDescriptionQuestion";
import { NluIntent } from "../../../models/Nlu/NluIntent";

import { ChangeType } from "../../../enums/ChangeType";
import { StepTexts, StepTypes } from "../../../enums/StepTypes";

import { TopDeskApiData } from "../../../API/TopDeskInteraction";
import EnterTextDialog from "../../Dialogs/ConfirmationDialog/EnterTextDialog";
import CreateNewIntent from "../../Dialogs/NewEntryDialogs/DialogCreator/CreateNewIntent";
import { DetailsWindow } from "../../Editors/DialogEditor/DetailsWindow/DetailsWindow";
import { DialogEdge } from "../../Editors/DialogEditor/ReactFlow/DialogEdge";
import DialogNodeComponent from "../../Editors/DialogEditor/ReactFlow/DialogNode";
import { DrawerHeader } from "../../FlyoutDrawer/DrawerHeaderSpacer";
import { StepType } from "./DragAndDrop/Step_Type";
import {
  DialogNode,
  DialogNodeData,
  fillInitialEdges,
  fillInitialNodes,
  fillSourceHandles,
} from "./ReactFlow/JsonToReactFlow";
import useLayoutNodes from "./ReactFlow/Use_LayoutNodes";
import "./VisualisationDialog.css";
import { filterNodes, updateNodeData } from "./VisualisationDialogHelper";
import styles from "./Visualisation_Dialog.module.scss";

import "@xyflow/react/dist/style.css";

// Interface
interface VisualisationDialogProps {
  dialog: DialogModel;
  onUpdate: (update: DialogModel) => void;
  selectedDialog: string;
  topDeskApiData: TopDeskApiData | undefined;
  isTopDeskDataLoaded: boolean;
  loadTopDeskApiData: () => Promise<boolean>;
  containerName: string;
}

// NodeTypes
const nodeTypes = {
  elk: DialogNodeComponent,
};

// EdgeTypes
const edgeTypes = {
  CIM: DialogEdge,
};

// Custom Types
export interface NodeSelection {
  oldNodeData: DialogStep;
  newNodeData: DialogStep;
  changed: boolean;
}

/**
 * The visualisation dialog component that renders the dialogcreator (ReactFlow)
 * @param VisualisationDialogProps - the props given by DialogEditor
 * @param VisualisationDialogProps.dialog - the dialog that needs to be visualised
 * @param VisualisationDialogProps.onUpdate - the function that updates the dialog
 * @param VisualisationDialogProps.selectedDialog - the selected dialog
 * @param VisualisationDialogProps.topDeskApiData - the topdesk data
 * @param VisualisationDialogProps.isTopDeskDataLoaded - the boolean that checks if the topdesk data is loaded
 * @param VisualisationDialogProps.loadTopDeskApiData - the function that loads the topdesk data
 * @param VisualisationDialogProps.containerName - the name of the container
 * @returns the visualisation of the dialog creator
 */
const VisualisationDialog = ({
  dialog,
  onUpdate,
  selectedDialog,
  topDeskApiData,
  isTopDeskDataLoaded,
  loadTopDeskApiData,
  containerName,
}: VisualisationDialogProps): JSX.Element => (
  <ReactFlowProvider>
    <Flow
      dialog={dialog}
      onUpdate={onUpdate}
      selectedDialog={selectedDialog}
      topDeskApiData={topDeskApiData}
      isTopDeskDataLoaded={isTopDeskDataLoaded}
      loadTopDeskApiData={loadTopDeskApiData}
      containerName={containerName}
    />
  </ReactFlowProvider>
);

/**
 * The flow component that renders the dialogcreator (ReactFlow)
 * @param VisualisationDialogProps - the props given by DialogEditor
 * @param VisualisationDialogProps.dialog - the dialog that needs to be visualised
 * @param VisualisationDialogProps.onUpdate - the function that updates the dialog
 * @param VisualisationDialogProps.selectedDialog - the selected dialog
 * @param VisualisationDialogProps.topDeskApiData - the topdesk data
 * @param VisualisationDialogProps.isTopDeskDataLoaded - the boolean that checks if the topdesk data is loaded
 * @param VisualisationDialogProps.loadTopDeskApiData - the function that loads the topdesk data
 * @param VisualisationDialogProps.containerName - the name of the container
 * @returns the visualisation of the dialog creator
 */
const Flow: FC<VisualisationDialogProps> = ({
  dialog,
  onUpdate,
  selectedDialog,
  topDeskApiData,
  isTopDeskDataLoaded,
  loadTopDeskApiData,
  containerName,
}: VisualisationDialogProps) => {
  const NLUContext = useNLUContext();
  const localStorageContext = useLocalStorageContext();
  const { screenToFlowPosition, zoomIn, zoomOut } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();

  const [currentData, setCurrentData] = useState<DialogModel>(dialog);

  /**
   * Function to open the confirmation dialog for the deletion of a Node
   * @param nodeData - the data from the Node
   */
  const onNodesDelete = (nodeData: DialogNodeData): void => {
    const confirmProps: ConfirmationDialogProps = {
      open: true,
      handleClose: handleConfirmClose,
      title: `'${nodeData.label}' verwijderen`,
      button1Text: "Verwijderen",
      button2Text: "Annuleren",
      description: `Weet je zeker dat je "${nodeData.label}" wilt verwijderen?`,

      /**
       * the functions needed to run to delete a Node with it's possible connections
       */
      executable: () => {
        removeSelectedEdge({
          from: nodeData.id,
          to: nodeData.id,
          sourceHandles: nodeData.sourceHandles,
          targetHandles: nodeData.targetHandles,
        });
        removeSelectedNode(nodeData.id);
        setSelectedNodeData({
          oldNodeData: {
            id: "",
            name: "",
            nextStep: "",
            options: undefined,
            type: "",
          },
          newNodeData: {
            id: "",
            name: "",
            nextStep: "",
            options: undefined,
            type: "",
          },
          changed: false,
        });
        setNodes((nds) => filterNodes(nds, nodeData));
      },
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Delete the edge from the data behind the visual dialog
   * @param edge - the data given from the edge delete click
   * @param edge.id - the id of the edge
   * @param edge.from - the from node
   * @param edge.to - the to node
   * @param edge.source - edge.source
   * @param edge.target - edge.target
   */
  const onEdgeDelete = (edge: { id: string; from: string; to: string; source: string; target: string }): void => {
    setEdges((edges) => edges.filter((edgeFromArray) => edge.id !== edgeFromArray.id));

    const updateData = { ...currentData };

    const steps = updateData.versions
      .find((version) => version.version === selectedVersion)!
      .steps.map((step) => {
        if (step.id === edge.from) {
          step.nextStep = "";
        } else {
          removeEdgeBasedOnType(step, edge, false);
        }

        return step;
      });

    updateData.versions.find((version) => version.version === selectedVersion)!.steps = steps;

    if (updateData !== undefined) {
      handleUpdate(updateData).catch((error) => {
        throw new Error(error);
      });
    }
  };

  /**
   * Get the active dialog through a function
   * @returns the active dialog
   */
  const getActiveDialog = (): DialogVersion => dialog.versions.find((version: DialogVersion) => version.active)!;

  const [loading, setLoading] = useState<boolean>(true);
  const [nodes, setNodes, onNodesChange] = useNodesState<DialogNode>(
    fillInitialNodes(getActiveDialog(), onNodesDelete),
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(fillInitialEdges(getActiveDialog(), onEdgeDelete));
  const [activeDrag, setActiveDrag] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedVersion, setSelectedVersion] = useState<string>(getActiveDialog().version);
  const [selectedNodeData, setSelectedNodeData] = useState<NodeSelection>({
    oldNodeData: {
      id: "",
      name: "",
      nextStep: "",
      options: undefined,
      type: "",
    },
    newNodeData: {
      id: "",
      name: "",
      nextStep: "",
      options: undefined,
      type: "",
    },
    changed: false,
  });

  const [currentIntent, setCurrentIntent] = useState<NluIntent>({ name: "" });
  const [selectedIntent, setSelectedIntent] = useState<NluIntent | null>(null);
  const [deleteIntentConnection, setDeleteIntentConnection] = useState<boolean>(false);
  const [usedIntents, setUsedIntents] = useState<Array<NluIntent> | null>();
  const [intentOptions, setIntentOptions] = useState<Array<NluIntent>>();
  const [confirmProps, setConfirmProps] = useState<ConfirmationDialogProps>();
  const [newNodePosition, setNewNodePosition] = useState<XYPosition>();
  const ref = useRef<HTMLInputElement | null>(null);

  // #region useEffects
  useEffect(() => {
    setCurrentData(dialog);

    const activeDialog = dialog.versions.find((version: DialogVersion) => version.active);

    if (activeDialog !== undefined) {
      setLoading(false);
      setNodes(fillInitialNodes(activeDialog, onNodesDelete));
      setEdges(fillInitialEdges(activeDialog, onEdgeDelete));
      setSelectedVersion(activeDialog.version);
    }
  }, []);

  /**
   * set the intent options list
   */
  useEffect(() => {
    setIntentOptions(NLUContext?.selectedProject?.assets.intents);
  }, [NLUContext?.selectedProject?.assets.intents]);

  /**
   * set the used Intents in all dialogs
   */
  useEffect(() => {
    setUsedIntents(NLUContext?.usedIntents);
  }, [NLUContext?.usedIntents]);

  /**
   * Once the intentOptions are filled, set the currentIntent and selectedIntent
   */
  useEffect(() => {
    if (intentOptions === undefined) return;
    const currIntent = intentOptions.find((intent) => intent.name === dialog.intent);

    if (currIntent != null) {
      setCurrentIntent(currIntent);
      setSelectedIntent(currIntent);
    }
  }, [intentOptions]);

  /**
   * When selectedNodeData is changed, then
   */
  useEffect(() => {
    if (selectedNodeData.changed) {
      saveChanges();
      setSelectedNodeData({ ...selectedNodeData, changed: false });
    }

    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNodeData.newNodeData.id) {
          // it's important that you create a new node object
          // in order to notify react flow about the change

          updateNodeInternals(node.id);

          return {
            ...node,
            data: {
              ...node.data,
              label: selectedNodeData.newNodeData.name,
            },
          };
        }

        return node;
      }),
    );
  }, [selectedNodeData]);

  /**
   * OnChange intent selection save
   */
  useEffect(() => {
    const updateData = { ...dialog };
    if (selectedIntent === null) {
      if (deleteIntentConnection) {
        NLUContext?.updateUsedIntents!(currentIntent, false);

        if (updateData !== undefined) {
          updateData.intent = "";
          handleUpdate(updateData).catch((error) => {
            throw new Error(error);
          });
          setCurrentIntent({ name: "" });
          setDeleteIntentConnection(false);
        }
      }
    } else if (selectedIntent.name !== currentIntent.name && selectedIntent.name !== "") {
      NLUContext?.updateUsedIntents!(currentIntent, true);

      if (updateData !== undefined) {
        updateData.intent = selectedIntent.name;
        handleUpdate(updateData).catch((error) => {
          throw new Error(error);
        });
        setCurrentIntent(selectedIntent);
      }
    }
  }, [selectedIntent]);

  /**
   * OnChange version selection
   */
  useEffect(() => {
    if (selectedVersion !== undefined) {
      const updateData = { ...dialog };

      const activeDialog = updateData.versions.find((version: DialogVersion) => version.version === selectedVersion);
      if (activeDialog === undefined) return;
      setNodes(fillInitialNodes(activeDialog, onNodesDelete));
      setEdges(fillInitialEdges(activeDialog, onEdgeDelete));
    }
  }, [selectedVersion]);

  /**
   * When nodes get changed and loading is set to true, refill the nodes and edges through the initial methods.
   * New nodes can't be created with using the type 'elk' so this is a workaround
   */
  useEffect(() => {
    if (loading) {
      setLoading(false);
      setNodes(fillInitialNodes(getActiveDialog(), onNodesDelete));
      setEdges(fillInitialEdges(getActiveDialog(), onEdgeDelete));
    }
  }, [nodes]);
  // #endregion

  // #region data manipulation

  /**
   * Used in DetailsWindow to save the details of that node
   */
  const saveChanges = (): void => {
    // Use oldNodeData for comparison and newNodeData for updating
    const updateData = { ...currentData };

    if (updateData.versions !== undefined) {
      const steps = updateData.versions
        .find((version) => version.version === selectedVersion)!
        .steps.map((step) => {
          if (step.id === selectedNodeData.oldNodeData.id) {
            setSelectedNodeData({
              ...selectedNodeData,
              oldNodeData: selectedNodeData.newNodeData,
            });

            return { ...selectedNodeData.newNodeData };
          } else {
            return { ...step };
          }
        });
      updateData.versions.find((version) => version.version === selectedVersion)!.steps = steps;

      if (updateData !== undefined) {
        handleUpdate(updateData).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };

  /**
   * Handle the update, which also resets the Nodes and Edges
   * @param update - the update that was made to the dialog.
   * @returns void
   */
  const handleUpdate = async (update: DialogModel): Promise<void> => {
    // Track this new change
    if (localStorageContext !== null) {
      // Add a sort order based on the item's keys.
      const keys: Array<string> = Object.keys(update);

      // Since we are updating, retrieve existing change.
      const changeKey = `change-${containerName}-${update.key}`;

      // Retrieve a previous update for this item and modify it, or create a new change if did not exist.
      const trackedChangeResult = localStorageContext.getChangeByKey!(changeKey, containerName);
      if (trackedChangeResult === undefined) {
        // Build a new Change
        const newTrackedChange: TrackedChange = {
          key: changeKey,
          containerName,
          oldValue: deepCopy(currentData),
          newValue: update,
          lastModified: moment().toDate(),
          changeType: ChangeType.Update,
          live: false,
          displayOrder: keys,
        };

        localStorageContext.trackChange!(newTrackedChange);
      } else {
        // Modify the change parameters.
        const updateTrackedChange = {
          ...trackedChangeResult,
          newValue: update,
          lastModified: moment().toDate(),
          displayOrder: keys,
        };

        localStorageContext.updateChange!(updateTrackedChange);
      }
    }

    setCurrentData({ ...update });

    if (selectedVersion !== undefined) {
      const activeDialog = update.versions.find((version) => version.version === selectedVersion);
      if (activeDialog === undefined) return;

      setNodes((nds) => updateNodeData(nds, activeDialog));
    }

    onUpdate(update);
  };

  /**
   * Get the correct starting data for the specific type
   * @param newNodeName - the name of the new node
   * @returns the correct starting data or undefined
   */
  const getCorrectStartingNodeData = (newNodeName: string): DialogStep | undefined => {
    if (activeDrag === StepTypes.Message) {
      return {
        id: uuidv4(),
        name: newNodeName,
        type: StepTypes.Message,
        nextStep: "",
        options: {
          isAdminAnswer: false,
          adminResponse: "",
          selectedResponse: "",
        },
      };
    }

    if (activeDrag === StepTypes.Conditional) {
      return {
        id: uuidv4(),
        name: newNodeName,
        type: StepTypes.Conditional,
        options: {
          ifs: [{ id: uuidv4(), key: "ELSE", value: "", nextStep: "" }],
        },
      };
    }

    if (activeDrag === StepTypes.AskWithButtons) {
      return {
        id: uuidv4(),
        name: newNodeName,
        type: StepTypes.AskWithButtons,
        nextStep: "",
        options: {
          isAdminQuestion: false,
          adminQuestion: "",
          selectedQuestion: "",
          buttons: [
            {
              id: uuidv4(),
              key: "else",
              value: "",
              nextStep: "",
              isHidden: true,
            },
          ],
        },
      };
    }

    if (activeDrag === StepTypes.TextPrompt) {
      return {
        id: uuidv4(),
        name: newNodeName,
        type: StepTypes.TextPrompt,
        nextStep: "",
        options: {
          isAdminQuestion: false,
          adminQuestion: "",
          selectedQuestion: "",
        },
      };
    }

    if (activeDrag === StepTypes.YesNoDialog) {
      return {
        id: uuidv4(),
        name: newNodeName,
        type: StepTypes.YesNoDialog,
        nextStep: "",
        options: {
          isAdminQuestion: false,
          adminQuestion: "",
          selectedQuestion: "",
          buttons: [
            {
              id: uuidv4(),
              key: "yes",
              value: "yes",
              nextStep: "",
            },
            {
              id: uuidv4(),
              key: "no",
              value: "no",
              nextStep: "",
            },
          ],
        },
      };
    }

    if (activeDrag === StepTypes.CreateTicketDialog) {
      return {
        id: uuidv4(),
        name: newNodeName,
        type: StepTypes.CreateTicketDialog,
        nextStep: "",
        options: {
          response: "",
          DescQuestions: [],
        },
      };
    }

    if (activeDrag === StepTypes.Redirect) {
      return {
        id: uuidv4(),
        name: newNodeName,
        type: StepTypes.Redirect,
        nextStep: "",
        options: {
          redirect: "",
        },
      };
    }

    if (activeDrag === StepTypes.LLM) {
      return {
        id: uuidv4(),
        name: newNodeName,
        type: StepTypes.LLM,
        nextStep: "",
        options: {
          useUserQuestion: false,
          llmStepModel: {
            message: "",
            knowledgeBases: [],
          },
        },
      };
    }
  };

  /**
   * Removes connections
   * @param edge - the data from the edge(s) that needs to be deleted
   * @param edge.from - the from node
   * @param edge.to - the to node
   * @param edge.sourceHandles - the source handles
   * @param edge.targetHandles - the target handles
   */
  const removeSelectedEdge = (edge: {
    from: string;
    to: string;
    sourceHandles: Array<{ id: string; className: string }>;
    targetHandles: Array<{ id: string; className: string }>;
  }): void => {
    const updateData = { ...currentData };

    const steps = updateData.versions
      .find((version) => version.version === selectedVersion)!
      .steps.map((step) => {
        if (step.id === edge.from) {
          step.nextStep = "";
        } else {
          removeEdgeBasedOnType(step, edge, true);
        }

        return step;
      });

    updateData.versions.find((version) => version.version === selectedVersion)!.steps = steps;

    if (updateData !== undefined) {
      handleUpdate(updateData).catch((error) => {
        throw new Error(error);
      });
    }
  };

  /**
   * Node deletion from the data
   * @param id - the id of the node that needs to be deleted
   */
  const removeSelectedNode = (id: string): void => {
    const updateData = { ...currentData };

    if (updateData.versions !== undefined) {
      const steps = updateData.versions
        .find((version) => version.version === selectedVersion)!
        .steps.filter((step) => step.id !== id);

      updateData.versions.find((version) => version.version === selectedVersion)!.steps = steps;

      if (updateData !== undefined) {
        handleUpdate(updateData).catch((error) => {
          throw new Error(error);
        });
      }
    }
  };

  /**
   * Remove connection based on step types, since the different step types have different structures
   * @param step - step that needs to be checked
   * @param edge - edge that needs to be unconnected
   * @param onNodeDelete - if it is from a node deletion
   */
  const removeEdgeBasedOnType = (
    step: DialogStep,
    edge:
      | {
          from: string;
          to: string;
          sourceHandles: Array<{ id: string; className: string }>;
          targetHandles: Array<{ id: string; className: string }>;
        }
      | { id: string; from: string; to: string; source: string; target: string },
    onNodeDelete: boolean,
  ): void => {
    switch (step.type) {
      case StepTypes.AskWithButtons:
      case StepTypes.YesNoDialog:
        step.options.buttons.forEach((button: ButtonOption) => {
          if (button.id === edge.from || (button.nextStep === edge.to && onNodeDelete)) {
            button.nextStep = "";
          }
        });
        break;
      case StepTypes.Conditional:
        step.options.ifs.forEach((ifStatement: { id: string; nextStep: string }) => {
          if (ifStatement.id === edge.from || (ifStatement.nextStep === edge.to && onNodeDelete)) {
            ifStatement.nextStep = "";
          }
        });
        break;
      default:
        if (step.id === edge.from && step.nextStep === edge.to) {
          step.nextStep = "";
        }
        break;
    }
  };
  // #endregion

  // #region ReactFlow interaction
  /**
   * Once clicked on a Node, set the selectedNodeData
   * @param event - the event arguments
   * @param node - the node that is clicked on
   */
  const onNodeClick = (event: React.MouseEvent, node: Node): void => {
    const selection: DialogStep = dialog.versions
      .find((version) => version.version === selectedVersion)!
      .steps.find((selection: DialogStep) => selection.id === node.id)!;

    if (selection.type !== StepTypes.Start && selection.type !== StepTypes.Finish) {
      onPaneClick();
      setTimeout(() => {
        setSelectedNodeData({ oldNodeData: selection, newNodeData: selection, changed: false });
      }, 0);
    }
  };

  /**
   * Set the selectedNodeData to empty, so that the details window closes
   */
  const onPaneClick = (): void => {
    setSelectedNodeData({
      oldNodeData: {
        id: "",
        name: "",
        nextStep: "",
        options: undefined,
        type: "",
      },
      newNodeData: {
        id: "",
        name: "",
        nextStep: "",
        options: undefined,
        type: "",
      },
      changed: false,
    });
  };

  /**
   * if the new node type, is dragged of the pane
   */
  const onDragOver = useCallback((event: { preventDefault: () => void; dataTransfer: { dropEffect: string } }) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  /**
   * Once the new node type is dropped show the confirmation window
   */
  const onDrop = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { getData: (arg0: string) => string };
      clientX: number;
      clientY: number;
    }) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");

      // project was renamed to screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      setNewNodePosition(position);
      setActiveDrag(type);
      setShowConfirmation(true);
    },
    [screenToFlowPosition],
  );

  /**
   * Add the node and all the data for a new node
   * @param newNodeName - the name of the new node
   */
  const onConfirmAddNode = (newNodeName: string): void => {
    const newNode = getCorrectStartingNodeData(newNodeName)!;

    const updateData: DialogModel = { ...currentData };
    const { steps } = dialog.versions.find((version) => version.version === selectedVersion)!;
    steps.splice(steps.length - 1, 0, newNode);
    updateData.versions.find((version) => version.version === selectedVersion)!.steps = steps;
    setCurrentData({ ...updateData });

    handleUpdate(updateData).catch((error) => {
      throw new Error(error);
    });

    setActiveDrag(null);

    if (newNodePosition !== undefined) {
      const newNodeReactFlow = {
        id: newNode.id,
        position: newNodePosition,
        data: {
          label: newNodeName,
          sourceHandles: fillSourceHandles(newNode),
          targetHandles: [{ id: `${newNode.id}_in`, className: "port" }],
          stepType: newNode.type,
          onNodesDelete,
          selectable: true,
          id: newNode.id,
        },
      };

      setLoading(true);

      setNodes((nds) => {
        nds.splice(nds.length - 1, 0, newNodeReactFlow);

        return nds;
      });
    }

    setShowConfirmation(false);
  };

  // #endregion

  // #region Dialog selections
  /**
   * OnChange for the intent Select input.
   * @param newValue - The new value of the input.
   */
  const OnChangeIntentSelection = (newValue: NluIntent | null): void => {
    if (newValue !== null) {
      setSelectedIntent(newValue);
    } else {
      const confirmProps: ConfirmationDialogProps = {
        open: true,
        handleClose: handleConfirmClose,
        title: `Ontkoppelen met ${currentIntent.name}`,
        button1Text: "Ontkoppelen",
        button2Text: "Annuleren",
        description: `Weet je zeker dat je deze dialog met wilt ontkoppelen van "${currentIntent.name}"?`,

        /**
         * Removes the intent connection
         */
        executable: () => {
          setDeleteIntentConnection(true);
          setSelectedIntent(null);
        },
      };

      setConfirmProps(confirmProps);
    }
  };

  /**
   * Handles the closing of confirmation dialog.
   */
  const handleConfirmClose = (): void => {
    const confirmProps: ConfirmationDialogProps = {
      open: false,
    };

    setConfirmProps(confirmProps);
  };

  /**
   * This function acts as the executable for the CreateNewIntent dialog window
   * @param newIntent - the intent that was created via the popup
   */
  const onNewIntentCreated = (newIntent: NluIntent): void => {
    if (NLUContext !== null) {
      // Add this intent to intentOptions and set it as the selected intent.
      const newIntentOptions = [...intentOptions!];
      setIntentOptions([...newIntentOptions, newIntent]);

      const _selectedIntent = newIntent;
      if (_selectedIntent !== undefined) {
        setSelectedIntent(_selectedIntent);
      }
    }
  };

  /**
   * OnChange for the version selection
   * @param event - the event that triggered the change
   */
  const OnChangeVersionSelection = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value !== undefined) {
      const version = parseFloat(event.target.value).toFixed(1);

      setSelectedVersion(version);
    }
  };

  /**
   * Sets the currently viewed version as active
   */
  const setAsActive = (): void => {
    const updateData = { ...currentData };
    updateData.versions.find((version) => version.active)!.active = false;
    updateData.versions.forEach((version) => {
      if (version.version === selectedVersion.toString()) {
        version.active = true;
      }
    });

    handleUpdate(updateData).catch((error) => {
      throw new Error(error);
    });
  };

  /**
   * Adds new version for the dialog
   */
  const AddNewVersion = (): void => {
    const updateData = { ...currentData };
    const currentVersion = updateData.versions.find((version) => version.version === selectedVersion)!;
    let latestVersion = 0.0;

    updateData.versions.forEach((version) => {
      if (latestVersion < parseFloat(version.version)) {
        latestVersion = parseFloat(version.version);
      }
    });

    latestVersion = latestVersion + 0.1;

    const updatedVersionArray: Array<DialogVersion> = [
      {
        version: latestVersion.toFixed(1),
        active: false,
        steps: currentVersion.steps,
      },
    ];

    updateData.versions.forEach((version) => {
      if (updatedVersionArray.length < 5) {
        updatedVersionArray.push(version);
      }
    });

    if (updatedVersionArray.find((version) => version.active) === undefined) {
      updatedVersionArray[updatedVersionArray.length - 1].active = true;
    }

    updateData.versions = updatedVersionArray;

    setSelectedVersion(latestVersion.toFixed(1));

    handleUpdate(updateData).catch((error) => {
      throw new Error(error);
    });
  };

  /**
   * Once a new connection is made
   */
  const onConnect = useCallback(
    // type is any, because it is not known which variables are given from here
    (params: any) => {
      const fromNode = nodes.find((node) => node.id === params.source);
      const toNode = nodes.find((node) => node.id === params.target);

      if (fromNode?.data.stepType === StepTypes.Start && toNode?.data.stepType === StepTypes.Finish) {
        return;
      }

      const sourceHandleID =
        params.sourceHandle.includes("_out") === true
          ? params.sourceHandle.substring(0, params.sourceHandle.indexOf("_out"))
          : params.sourceHandle;

      if (params.source !== params.target) {
        params.type = "CIM";
        params.markerEnd = {
          type: MarkerType.ArrowClosed,
        };
        params.id = `${sourceHandleID}---${params.targetHandle.substring(0, params.targetHandle.indexOf("_in"))}`;
        params.data = {
          onEdgeDelete,
        };

        const updateData = { ...currentData };

        const nextStep = params.targetHandle.substring(0, params.targetHandle.indexOf("_in"));

        // different step types have different structures for the way the nextStep is set
        if (updateData.versions !== undefined) {
          const steps = updateData.versions
            .find((version) => version.version === selectedVersion)!
            .steps.map((step) => {
              if (step.id === params.source) {
                switch (step.type) {
                  case StepTypes.AskWithButtons:
                  case StepTypes.YesNoDialog:
                    step.options.buttons.forEach((button: ButtonOption) => {
                      if (button.id === sourceHandleID) {
                        button.nextStep = nextStep;
                      }
                    });
                    break;
                  case StepTypes.Conditional:
                    step.options.ifs.forEach((ifStatement: { id: string; nextStep: string }) => {
                      if (ifStatement.id === sourceHandleID) {
                        ifStatement.nextStep = nextStep;
                      }
                    });
                    break;
                  case StepTypes.CreateTicketDialog:
                    if (step.id === params.source) {
                      if (step.options.DescQuestions.length > 0) {
                        step.options.DescQuestions.find(
                          (question: ITSMDescriptionQuesion) => question.nextStep === step.nextStep,
                        ).nextStep = nextStep;
                      }

                      step.nextStep = nextStep;
                    }
                    break;
                  default:
                    if (step.id === params.source) {
                      step.nextStep = nextStep;
                    }
                    break;
                }
              }

              return step;
            });

          updateData.versions.find((version) => version.version === selectedVersion)!.steps = steps;

          handleUpdate(updateData).catch((error) => {
            throw new Error(error);
          });
        }

        setEdges((edges) => edges.filter((edge) => params.sourceHandle !== edge.sourceHandle));
        setEdges((els) => addEdge(params, els));
      }
    },
    [],
  );

  // #endregion

  // used to set the layout so that nodes don't overlap and show in chronological order
  useLayoutNodes();

  return (
    <Stack gap={2} height="70vh" width="100%" direction="column">
      <Grid container>
        <Grid size={{ xs: 12 }}>
          <Grid container alignItems="center" justifyContent="flex-start" gap={2}>
            <Grid>
              <FormControl>
                <Autocomplete
                  sx={{ minWidth: "250px" }}
                  id="intentSelection"
                  value={selectedIntent}
                  onChange={(event: React.SyntheticEvent, newValue: NluIntent | null) => {
                    OnChangeIntentSelection(newValue);
                  }}
                  options={intentOptions!}
                  autoHighlight
                  getOptionLabel={(option: NluIntent) => option.name}
                  renderOption={(props, option: NluIntent) => {
                    let isUsed = false;
                    if (currentIntent.name !== option.name) {
                      isUsed = usedIntents!.some((usedIntent: NluIntent) => usedIntent.name === option.name);
                    }

                    return (
                      <MenuItem component="li" {...props} disabled={isUsed}>
                        {option.name}
                      </MenuItem>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} label="Dialoog intent" />}
                />
              </FormControl>
            </Grid>
            <Grid>
              <CreateNewIntent executable={onNewIntentCreated} />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Grid container alignItems="center" justifyContent="flex-end" gap={2}>
            <Grid>
              <FormControl>
                <TextField
                  select
                  id="versionSelection"
                  label="Versie selectie"
                  onChange={OnChangeVersionSelection}
                  value={selectedVersion.toString()}
                >
                  <CustomToolTip
                    title="Kopieert de versie van een dialoog die nu zichtbaar is (niet per definitie de actieve)"
                    child={
                      <MenuItem key={"NewVersion"} onClick={AddNewVersion}>
                        Nieuwe versie
                      </MenuItem>
                    }
                  />

                  {currentData.versions.map((option: { version: string; active: boolean }) => {
                    const disabled = option.version === selectedVersion;

                    return (
                      <MenuItem key={option.version} value={option.version} disabled={disabled}>
                        {option.version} {option.active ? <span> (huidig)</span> : <></>}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
            <Grid>
              <FormControl>
                <Button onClick={setAsActive}>Activeren</Button>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={styles.ReactFlowContainer}>
        <Grid paddingTop={2} className={styles.left}>
          <Stack gap={1} alignItems="center">
            <StepType name={StepTexts.Message} type={StepTypes.Message} />
            <StepType name={StepTexts.Conditional} type={StepTypes.Conditional} />
            <StepType name={StepTexts.AskWithButtons} type={StepTypes.AskWithButtons} />
            <StepType name={StepTexts.TextPrompt} type={StepTypes.TextPrompt} />
            <StepType name={StepTexts.YesNoDialog} type={StepTypes.YesNoDialog} />
            <StepType name={StepTexts.CreateTicket} type={StepTypes.CreateTicketDialog} />
            <StepType name={StepTexts.Redirect} type={StepTypes.Redirect} />
            <StepType name={StepTexts.LLM} type={StepTypes.LLM} />
          </Stack>
        </Grid>
        <ReactFlow
          ref={ref}
          className={styles.right}
          // Node functions
          nodes={nodes}
          onNodesChange={onNodesChange}
          onNodeClick={onNodeClick}
          nodeTypes={nodeTypes}
          onDrop={onDrop}
          onDragOver={onDragOver}
          // Edge functions
          edges={edges}
          onEdgesChange={onEdgesChange}
          edgeTypes={edgeTypes}
          onConnect={onConnect}
          onPaneClick={onPaneClick}
          fitView
          zoomOnDoubleClick
          snapToGrid
          colorMode="light"
          deleteKeyCode={null}
        >
          <Background variant={BackgroundVariant.Lines} />
          <Controls
            showInteractive={false}
            onZoomIn={() => {
              void zoomIn({ duration: 800 });
            }}
            onZoomOut={() => {
              void zoomOut({ duration: 800 });
            }}
          />
          <MiniMap nodeColor={"black"} pannable zoomable />
        </ReactFlow>
      </div>
      {selectedNodeData.oldNodeData.id !== "" ? (
        <Drawer
          className={styles.drawer}
          variant="permanent"
          anchor="right"
          open={true}
          onClose={() => {
            setSelectedNodeData({
              oldNodeData: {
                id: "",
                name: "",
                nextStep: "",
                options: undefined,
                type: "",
              },
              newNodeData: {
                id: "",
                name: "",
                nextStep: "",
                options: undefined,
                type: "",
              },
              changed: false,
            });
          }}
        >
          <Box>
            <DrawerHeader />
            <DetailsWindow
              selectedNodeData={selectedNodeData}
              setSelectedNodeData={setSelectedNodeData}
              loadTopDeskApiData={loadTopDeskApiData}
              topDeskApiData={topDeskApiData}
              isTopDeskDataLoaded={isTopDeskDataLoaded}
              containerName={containerName}
              selectedDialog={selectedDialog}
            />
          </Box>
        </Drawer>
      ) : null}

      <EnterTextDialog
        isOpen={showConfirmation}
        title="Nieuwe stap toevoegen"
        description="Geef een naam aan je nieuwe stap"
        handleClose={() => {
          setShowConfirmation(false);
        }}
        executable={(value: string) => {
          onConfirmAddNode(value);
        }}
        button1Text="Toevoegen"
        button2Text="Annuleren"
        placeholder="Nieuwe stap naam"
      />

      {confirmProps != null ? <ConfirmationDialog {...confirmProps} /> : null}
    </Stack>
  );
};

export default memo(VisualisationDialog);
