import React, { FC, useEffect, useState } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Box, Divider, Skeleton, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useNLUContext } from "../../../contexts/NLU/NluContext";

import IntentGroupList from "./Intents/IntentGroupList";
import styles from "./QuestionEditor.module.scss";
import UtteranceList from "./Utterances/UtteranceList";

/**
 * Question Editor Component
 * @returns the question editor component
 */
const QuestionEditor: FC = () => {
  const CLUContext = useNLUContext();

  const [errorText, setErrorText] = useState<string>("");

  /** Check every 20 seconds if synchronization is in progress and whether sources status has changed */
  useEffect(() => {
    // Interval between calls in miliseconds - Environment variable is in seconds
    const intervalTime = 20000;

    const interval = setInterval(() => {
      if (CLUContext?.selectedProject === null) {
        setErrorText("Het duurt wat langer om het initiële project aan te maken en te trainen.");
      } else {
        setErrorText("");
      }
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  });

  if (CLUContext?.selectedProject === null) {
    return (
      <Grid container spacing={2}>
        {errorText !== "" ? <p className={styles.ErrorText}>{errorText}</p> : null}
        <Grid size={{ xs: 12 }}>
          <Box component="h1">
            <Skeleton width="100%" height="100%"></Skeleton>
          </Box>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Skeleton width="100%" height="37px"></Skeleton>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Grid container justifyContent="space-between">
            <Grid>
              <Skeleton width="100%"></Skeleton>
            </Grid>
            <Grid>
              <Skeleton width="100%"></Skeleton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      width={"100%"}
      divider={<Divider orientation="vertical" variant="middle" flexItem />}
      sx={{ height: "100%", overflow: "hidden" }}
    >
      <Box sx={{ minWidth: "450px" }}>
        <DndProvider backend={HTML5Backend}>
          <IntentGroupList />
        </DndProvider>
      </Box>
      <Box width={"100%"}>{CLUContext?.selectedIntent !== null && <UtteranceList />}</Box>
    </Stack>
  );
};

export default QuestionEditor;
