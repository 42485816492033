import { DrawerItem, configItems, editorItems } from "./DrawerItems";

const EnableNLUFeatureFlag: boolean = process.env.REACT_APP_ENABLE_NLU_QUESTION_ANSWERING === "true";
const EnableHiglightsFeatureFlag: boolean = process.env.REACT_APP_ENABLE_HIGHLIGHTS === "true";
const EnableGroupIncidentsFeatureFlag: boolean = process.env.REACT_APP_ENABLE_GROUP_INCIDENTS === "true";

export interface DrawerGroup {
  name: string;
  items: Array<DrawerItem>;
}

const drawerGroupsUnfiltered: Array<DrawerGroup> = [
  {
    name: "Editors",
    items: editorItems,
  },
  {
    name: "Configuratie",
    items: configItems,
  },
];

// If feature is not flagged, filter it out
const filter: Array<string> = [];
if (!EnableGroupIncidentsFeatureFlag) {
  filter.push("groupincidents");
}
if (!EnableHiglightsFeatureFlag) {
  filter.push("highlights");
}
if (!EnableNLUFeatureFlag) {
  filter.push("dialogs");
  filter.push("questions");
}

/**
 * Filter the drawer groups based on the currently enabled features.
 * @param groups - The drawer groups.
 * @returns The filtered drawer groups.
 */
const filterGroupsOnFeatureFlags = (groups: Array<DrawerGroup>): Array<DrawerGroup> => {
  for (const group of groups) {
    group.items = group.items.filter((item) => !filter.includes(item.key));
    group.items = group.items.map((item, tabOrder) => ({ ...item, tabOrder }));
  }

  return groups;
};

// The drawer items with their adapted index, so highlighted tab selection works
export const drawerGroups: Array<DrawerGroup> = filterGroupsOnFeatureFlags(drawerGroupsUnfiltered);
